import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isMobileOnly } from 'react-device-detect';

// import Button from 'components/ui/Button';
import Button from 'components/ui/Button';
// import Plug from 'Layouts/Plug';
import PlugRotation from 'Layouts/PlugRotation';
import ThreejsApp from './Threejs';
import { ReactComponent as Fullscreen } from './assets/fullscreen.svg';
import { ReactComponent as FullscreenClose } from './assets/fullscreenclose.svg';
import { ReactComponent as Help } from './assets/help.svg';
import { ReactComponent as Upload } from './assets/upload.svg';
import { ReactComponent as Undo } from './assets/undo.svg';
import { ReactComponent as Redo } from './assets/redo.svg';
import { ReactComponent as Reset } from './assets/reset.svg';
import { ReactComponent as Login } from './assets/login.svg';

import { ReactComponent as Save } from './assets/savebtn.svg';

import logo from './assets/thermogreen.png';
import ExpertMode from './assets/side-bar-line.svg';
import RenderModal from './components/Modals/RenderModal';
import History from './Threejs/History';

import {
  initialization,
  setExpertMode,
  setShowAccountModal,
  setShowLoginModal,
  setShowSaveProjectModal,
  setShowUploadBg,
  setShowWarningModalRestart,
} from './store/reducers/User/UserSlice';
import {
  setSource,
  setTypeModelRemove,
} from './store/reducers/Project/ProjectSlice';
import './style.scss';
import Accordion from './Layouts/Accordion';
import ChangePasswordModal from './components/Modals/ChangePasswordModal';

import videoTutorial from './assets/tutorial.mp4';
import videoPoster from './assets/poster.png';
import SidePanel from './Layouts/SidePanel';
import Header from './Layouts/Header';

const App = ({ token }) => {
  const dispatch = useDispatch();
  const ref = useRef();
  const source = useRef();
  const expertMode = useSelector((state) => state.user.expertMode);
  const { typeModel } = useSelector((state) => state.project);

  const [mobile, setMobile] = useState(undefined);
  const [help, setHelp] = useState(false);
  const [playVideo, setPlayVideo] = useState(false);

  const [flagVideoTutorial, setFlagVideoTutorial] = useState(true);

  const videoRef = useRef();

  useEffect(() => {
    setMobile(isMobileOnly);
  }, []);

  const [fullscreen, setFullscreen] = useState(false);

  useEffect(() => {
    dispatch(initialization());
  }, []);

  useEffect(() => {
    if (token) {
      document.querySelector('.loader')?.classList?.add('hidden');
      return () => {};
    }
    const { current } = ref;
    source.current = new ThreejsApp(ref.current);
    dispatch(setSource(source.current));
    return () => {
      current.innerHTML = '';
    };
  }, []);

  const handleShowWarningModalRestart = () => {
    dispatch(setTypeModelRemove(null));
    dispatch(setShowWarningModalRestart(true));
  };

  // const saveModel1 = () => {
  //   source.current?.exportSceneGltf();
  // };

  // const saveModel2 = () => {
  //   source.current?.exportSceneObj();
  // };

  // const saveModel3 = () => {
  //   source.current?.exportSceneCollada();
  // };

  const closeFullscreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
  };

  const funcFullscreen = () => {
    if (fullscreen) {
      // ref.current.style.width = `100%`;

      ref.current.classList.add('fullscreen');

      document.body.requestFullscreen();
    } else {
      ref.current.classList.remove('fullscreen');
      // ref.current.style.width = `calc(100% - 436px)`;
      closeFullscreen();
    }
    source.current?.viewer.onWindowResize();
  };

  useEffect(() => {
    funcFullscreen();
    if (source.current) source.current.toggleFullScreen(fullscreen);
  }, [fullscreen]);

  useEffect(() => {
    if (mobile) return;
    document.body.addEventListener('fullscreenchange', () => {
      if (!document.fullscreenElement) {
        setFullscreen(false);
      }
    });
  }, []);

  const videoController = () => {
    if (!playVideo) {
      videoRef.current.controls = true;
      setTimeout(() => {
        videoRef.current.play();
        setPlayVideo(true);
      }, 10);
    }
  };

  // const handlePlay = () => {
  //   console.log('play');
  // };

  // const handlePause = () => {
  //   console.log('pause');
  //   // setPlayVideo(false);
  //   // videoRef.current.controls = false;
  //   // videoRef.current.pause();
  // };

  window.openVideoTutorial = () => {
    if (!flagVideoTutorial) return;
    setFlagVideoTutorial(false);
    setHelp(true);
  };

  const handleExpertMode = () => {
    source.current.funcRmElExpertMode();
    dispatch(setExpertMode(true));
    source.current?.changeExpertMode(true);
    source.current?.updEl();
  };

  const { profile } = useSelector((state) => state.user);

  const [initProfile, setInitProfile] = useState(false);

  useEffect(() => {
    if (mobile === undefined || profile === undefined) return;
    if (initProfile) return;
    if (profile && !mobile) {
      setInitProfile(true);

      setTimeout(() => {
        dispatch(setExpertMode(false));
        source.current?.changeExpertMode(false);
      }, 10);
    } else {
      setInitProfile(true);
    }
  }, [profile, mobile]);

  const handleShowAccount = () => {
    if (profile) {
      dispatch(setShowAccountModal('profile'));
    } else {
      dispatch(setShowLoginModal(true));
    }
  };

  const handleShowSaveProject = () => {
    dispatch(setShowSaveProjectModal(true));
  };

  const handleShowUploadBg = () => {
    dispatch(setShowUploadBg(ref.current));
  };

  useEffect(() => {
    if (expertMode) {
      source?.current.funcControlsAllow(false);
      ref.current.style.backgroundImage = 'none';
    }
  }, [expertMode]);

  return (
    <div style={{ height: '100%' }}>
      {mobile && <PlugRotation />}

      {/* {mobile ? (
        <Plug />
      ) : ( */}
      <div className="parent-container-threejs" style={{ height: '100%' }}>
        <div
          className={`container-threejs ${
            expertMode ? 'threejsExpertMode' : ''
          }`}
          ref={ref}
        />
        {token ? (
          <div>
            <ChangePasswordModal
              token={token}
              show
              close={() => {
                window.location.href = window.location.origin;
              }}
            />
          </div>
        ) : null}

        {!token && !expertMode ? (
          <div
            className={
              !fullscreen ? 'wrapper-accordion active' : 'wrapper-accordion'
            }
            id="accordion"
          >
            <Accordion />
          </div>
        ) : (
          <> </>
        )}

        {!fullscreen && !token && expertMode ? (
          <>
            <Header />
            <SidePanel />

            <div className="container-controls">
              {typeModel !== null && (
                <Fullscreen
                  onClick={() => {
                    setFullscreen((prev) => !prev);
                  }}
                />
              )}
              <Reset onClick={handleShowWarningModalRestart} />
              {profile && typeModel && <Save onClick={handleShowSaveProject} />}
              <Help
                onClick={() => {
                  setHelp(true);
                  setPlayVideo(false);
                }}
              />
            </div>

            <div className="container-mob logo">
              <img src={logo} alt="logo" />
            </div>

            <div className="container-mob top">
              <Login onClick={handleShowAccount} />
              {profile && typeModel && <Save onClick={handleShowSaveProject} />}
              {typeModel !== null && (
                <Fullscreen
                  onClick={() => {
                    setFullscreen((prev) => !prev);
                  }}
                />
              )}
            </div>

            <div className="container-mob bottom">
              <Reset onClick={handleShowWarningModalRestart} />
            </div>
          </>
        ) : (
          <> </>
        )}

        {fullscreen && expertMode && (
          <div className="container-fullscreen-controls">
            {fullscreen && (
              <FullscreenClose
                onClick={() => {
                  setFullscreen(false);
                }}
              />
            )}
          </div>
        )}

        {!fullscreen && !token && !expertMode ? (
          <div className="container-undo-redo">
            <Undo
              onClick={() => {
                History.undo();
              }}
            />
            <Redo
              onClick={() => {
                History.redo();
              }}
            />
            <Reset onClick={handleShowWarningModalRestart} />

            {/* <Button onClick={saveModel1} className="exportModel">
                Gltf
              </Button>
              <Button onClick={saveModel2} className="exportModel">
                Obj
              </Button>
              <Button onClick={saveModel3} className="exportModel">
                Dae
              </Button> */}
          </div>
        ) : (
          <> </>
        )}

        {!expertMode && (
          <div className="container-fullscreen-help">
            <Upload onClick={handleShowUploadBg} />
            {!fullscreen ? (
              <Fullscreen
                onClick={() => {
                  setFullscreen(true);
                }}
              />
            ) : (
              <FullscreenClose
                onClick={() => {
                  setFullscreen(false);
                }}
              />
            )}

            {!fullscreen && (
              <button
                className="expModeBtn"
                type="button"
                onClick={() => handleExpertMode()}
              >
                <img
                  src={ExpertMode}
                  alt="ExpertMode"
                  style={{ width: '26px' }}
                />
              </button>
            )}

            {!fullscreen ? (
              <Help
                onClick={() => {
                  setHelp(true);
                  setPlayVideo(false);
                }}
              />
            ) : (
              <> </>
            )}
          </div>
        )}
        {/* <div className="container-fullscreen-help"> */}
        {/*  <Fullscreen */}
        {/*    onClick={() => { */}
        {/*      setFullscreen((prev) => !prev); */}
        {/*    }} */}
        {/*  /> */}
        {/*  {!fullscreen ? ( */}
        {/*    <Help */}
        {/*      onClick={() => { */}
        {/*        setHelp(true); */}
        {/*        setPlayVideo(false); */}
        {/*      }} */}
        {/*    /> */}
        {/*  ) : ( */}
        {/*    <> </> */}
        {/*  )} */}
        {/* </div> */}

        {help ? (
          <div
            className={
              playVideo
                ? 'wrapper-video-tutorial active'
                : 'wrapper-video-tutorial'
            }
          >
            <div className="container-video-tutorial">
              <div className="title-video-tutorial">Anleitung</div>

              {!playVideo && (
                <Button
                  className="close-video-tutorial"
                  onClick={() => {
                    setHelp(false);
                  }}
                >
                  <div className="cross-video-tutorial">
                    <div className="line-video-tutorial line1"> </div>
                    <div className="line-video-tutorial line2"> </div>
                  </div>
                </Button>
              )}

              {playVideo && (
                <Button
                  className="minimize-button"
                  onClick={() => {
                    videoRef.current.controls = false;
                    setTimeout(() => {
                      videoRef.current.pause();
                      setPlayVideo(false);
                    }, 10);
                  }}
                >
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/6689/6689926.png"
                    alt=""
                  />
                </Button>
              )}

              <div className="video-tutorial">
                <video
                  ref={videoRef}
                  width="100%"
                  poster={videoPoster}
                  // onPlay={handlePlay}
                  // onPause={handlePause}
                  onClick={videoController}
                >
                  <source src={videoTutorial} type="video/mp4" />
                  <track
                    label="English Captions"
                    kind="captions"
                    src="captions.vtt"
                  />
                </video>

                {!playVideo && (
                  <Button className="play-video-tutorial"> </Button>
                )}
              </div>
            </div>
          </div>
        ) : (
          <> </>
        )}

        {fullscreen ? (
          <div className="container-note-exit-fullscreen">
            Klicken Sie auf das Vollbildsymbol oder die Escape-Taste, um
            zurückzugehen.
          </div>
        ) : (
          <> </>
        )}

        {!token && (
          <RenderModal expertMode={expertMode} typeModel={typeModel} />
        )}
      </div>
      {/* )} */}
    </div>
  );
};

export default () => {
  const params = new URLSearchParams(window.location.search);
  const token = params.get('token');

  return <App token={token} />;
};
