import * as THREE from 'three';
import {
  Box3,
  EventDispatcher,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  PlaneGeometry,
  TextureLoader,
  Vector3,
} from 'three';
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter';
import { OBJExporter } from 'three/addons/exporters/OBJExporter.js';
import { ColladaExporter } from 'three/addons/exporters/ColladaExporter.js';
import * as TWEEN from '@tweenjs/tween.js';
// funcCheckSide
//createBtnRoof
import { deepClone } from './clone';

import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import ProjectService from 'services/projects.service';
import Viewer from './Viewer';
import Details from './Details';
import pngEdit from '../assets/edit.png';
import pngDelete from '../assets/delete.png';

import pngRoof from '../assets/roof.png';

import History from './History';
import AddElCommand from './History/commands/AddElCommand';
import RmElCommand from './History/commands/RmElCommand';

import Element from './Element';
import AddWallCommand from './History/commands/AddWallCommand';
import RmWallCommand from './History/commands/RmWallCommand';

import ConstructLouver from './ConstructLouver';
import ConstructLouver2 from './ConstructLouver2';
import ConstructLouver3 from './ConstructLouver3';
import ConstructGlass from './ConstructGlass';

import SizeElCommand from './History/commands/SizeElCommand';
import ChangeMapElCommand from './History/commands/ChangeMapElCommand';
import ZipElCommand from './History/commands/ZipElCommand';
import WindowElCommant from './History/commands/WindowElCommant';
import LedElCommand from './History/commands/LedCommand';
import LamellenElCommand from './History/commands/LamellenElCommand';
import RemoteControlElCommand from './History/commands/RemoteControlElCommand';
import ElectroElCommand from './History/commands/ElectroElCommand';
import SteeringElCommand from './History/commands/SteeringElCommand';
import SolarELCommand from './History/commands/SolarELCommand';
import ReplaceCommand from './History/commands/ReplaceCommand';

import createMenu from './createMenu/index';
import Led2ElCommand from './History/commands/Led2ElCommand';

export default class ThreejsApp extends EventDispatcher {
  typeModel = null;

  globalWidth = 5000;
  globalDepth = 3000;
  globalHeight1 = 2100;
  globalHeight2 = 2500;

  resInfoProductGlassModel = null;

  zipMap = [];

  extras = {
    servicesTG: {
      aufmassVorOrt: false,
      baugensuch: false,
      planung: false,
      montagePlanung: false,
    },
    externalServicesTG: {
      fundamente: false,
      plattenpassen: false,
      colFundamente: 0,
      colPlattenpassen: 0,
    },
  };

  buffer = new Object3D();
  startPathModel = null;
  elements = [];
  bufferElements = [];

  menuRoof = new Object3D();

  zipMenu = new Object3D();
  zipRoofMenu = new Object3D();
  window1Menu = new Object3D();
  window2Menu = new Object3D();
  lamellenMenu = new Object3D();

  fullscreen = false;

  constructor(container) {
    super();
    this.container = container;
    this.viewer = new Viewer(this, container);
    this.details = new Details(this, container);
    this.raycasterEl = new THREE.Raycaster();
    this.raycasterMenu = new THREE.Raycaster();
    this.mouse = new THREE.Vector2();
    this.exporter = new GLTFExporter();
    this.raycasterElMesh = [];
    this.raycasterMenuMesh = [];
    this.faceNormal = new THREE.Vector3();
    this.expertMode = true;
    this.array = [];
    this.lastArrayDell = [];
    this.dataSteering = { prev: undefined, next: undefined };
    this.dataLed2 = { prev: undefined, next: undefined };
    this.init();
    this.initDitailsModels();
    //
    this.createBtnRoof();
    this.window1Menu = createMenu.createBtnWindow1Menu(this.raycasterMenuMesh);
    this.window2Menu = createMenu.createBtnWindow2Menu(this.raycasterMenuMesh);
    this.zipMenu = createMenu.createBtnZipMenu(this.raycasterMenuMesh);
    this.zipRoofMenu = createMenu.createBtnZipRoofMenu(this.raycasterMenuMesh);
    this.lamellenMenu = createMenu.createBtnLamellenMenu(
      this.raycasterMenuMesh
    );
    //
  }
  init() {
    this.menuRoof.name = 'menuRoof';
    this.zipMenu.name = 'zipMenu';
    this.zipRoofMenu.name = 'zipRoofMenu';
    this.window1Menu.name = 'window1Menu';
    this.window2Menu.name = 'window2Menu';
    this.lamellenMenu.name = 'lamellenMenu';

    this.container.addEventListener(
      'mousedown',
      this.onMouseDown.bind(this),
      false
    );
  }
  clearGlobalValues() {
    this.globalWidth = 5000;
    this.globalDepth = 3000;
    this.globalHeight1 = 2100;
    this.globalHeight2 = 2500;
  }
  async initDitailsModels() {
    this.resInfoProductGlassModel = await this.infoProductGlassModel(
      this.globalWidth,
      this.globalDepth,
      this.globalHeight1,
      this.globalHeight2
    );
    await this.details.initDitailsModels();
  }
  funcSelectType(type) {
    this.typeModel = type;

    this.dispatchEvent({
      type: 'open-section1',
    });

    if (this.typeModel === 'louver') {
      this.funcSelectModel(
        this.details.louverBase, //1
        this.details.louverBeam, //2
        this.details.louverColumn, //3
        this.details.louverRoof, //4
        undefined, //5
        this.details.zipPlaneSklSide, //6
        undefined, //7
        undefined, //8
        undefined, //9
        undefined, //10
        this.details.zipColumn, //11
        this.details.zipTop, //12
        this.details.zipBottom, //13
        this.details.windowBorder, //14
        this.details.windowFrame, //15
        this.details.windowGlass, //16
        this.details.windowHandle, //17
        this.details.windowPartition, //18
        this.details.windowFrameT, //19
        this.details.windowFrameB1, //20
        this.details.windowFrameB2, //21
        this.details.windowGlassC, //22
        this.details.windowGlassB, //23
        undefined, //24
        undefined, //25
        undefined, //26
        undefined, //27
        undefined, //28
        undefined, //29
        undefined, //30
        undefined, //31
        undefined, // 32
        undefined, //33
        undefined, // 34
        this.details.lamellen, // 35
        this.details.lamellenRailTop, // 36
        this.details.lamellenRailBottom, // 37
        this.details.lamellenFrameColumn, // 38
        this.details.lamellenFrameBase, // 39
        undefined, // 39
        undefined, // 40
        undefined, // 41
        undefined // 42
      );
    }

    if (this.typeModel === 'louver2') {
      this.funcSelectModel(
        this.details.louver2Base, //1
        this.details.louver2Beam, //2
        this.details.louver2Column, //3
        this.details.louver2Roof, //4
        undefined, //5
        this.details.zipPlaneSklSide, //6
        undefined, //7
        undefined, //8
        undefined, //9
        undefined, //10
        this.details.zipColumn, //11
        this.details.zipTop, //12
        this.details.zipBottom, //13
        undefined, //14
        undefined, //15
        undefined, //16
        undefined, //17
        undefined, //18
        undefined, //19
        undefined, //20
        undefined, //21
        undefined, //22
        undefined, //23
        undefined, //24
        undefined, //25
        undefined, //26
        undefined, //27
        undefined, //28
        undefined, //29
        undefined, //30
        undefined, //31
        undefined, // 32
        undefined, //33
        undefined, // 34
        this.details.lamellen, // 35
        this.details.lamellenRailTop, // 36
        this.details.lamellenRailBottom, // 37
        this.details.lamellenFrameColumn, // 38
        this.details.lamellenFrameBase, // 39
        undefined, // 39
        undefined, // 40
        undefined, // 41
        undefined // 42
      );
    }

    if (this.typeModel === 'louver3') {
      this.funcSelectModel(
        this.details.louver3Base, //1
        this.details.louver3Beam, //2
        this.details.louver3Column, //3
        this.details.louver3Roof, //4
        undefined, //5
        this.details.zipPlaneSklSide, //6
        undefined, //7
        undefined, //8
        undefined, //9
        undefined, //10
        this.details.zipColumn, //11
        this.details.zipTop, //12
        this.details.zipBottom, //13
        this.details.windowBorder, //14
        this.details.windowFrame, //15
        this.details.windowGlass, //16
        this.details.windowHandle, //17
        this.details.windowPartition, //18
        this.details.windowFrameT, //19
        this.details.windowFrameB1, //20
        this.details.windowFrameB2, //21
        this.details.windowGlassC, //22
        this.details.windowGlassB, //23
        undefined, //24
        undefined, //25
        undefined, //26
        undefined, //27
        undefined, //28
        undefined, //29
        undefined, //30
        undefined, //31
        undefined, // 32
        undefined, //33
        undefined, // 34
        this.details.lamellen, // 35
        this.details.lamellenRailTop, // 36
        this.details.lamellenRailBottom, // 37
        this.details.lamellenFrameColumn, // 38
        this.details.lamellenFrameBase, // 39
        undefined, // 39
        this.details.louver3Solar,
        this.details.louver3RoofClip1,
        this.details.louver3RoofClip2
      );
    }

    if (this.typeModel === 'glass') {
      this.funcSelectModel(
        this.details.glassBase, //1
        this.details.glassBeam, //2
        this.details.glassColumn, //3
        this.details.glassRoofL, //4
        this.details.glassGlassRoof, //5
        this.details.zipPlaneSglSide, //6
        this.details.zipTop2, //7
        this.details.zipColumn2, //8
        this.details.zipBottom2, //9
        this.details.zipHolder, //10
        this.details.zipColumn5, //11
        this.details.zipTop5, //12
        this.details.zipBottom5, //13
        this.details.windowSglBorder, //14
        this.details.windowSglFrame, //15
        this.details.windowSglGlass, //16
        this.details.windowSglHandle, //17
        this.details.windowSglPartition, //18
        this.details.windowSglFrameT, //19
        this.details.windowSglFrameB1, //20
        this.details.windowSglFrameB2, //21
        this.details.windowSglGlassC, //22
        this.details.windowSglGlassB, //23
        this.details.zipTop3, //24
        this.details.zipColumn3, //25
        this.details.zipBottom3, //26
        this.details.zipCenter2, //27
        this.details.zipCenter3, //28
        this.details.glassRoofT, //29
        this.details.zipTop4, // 30
        this.details.glassRoofP, // 31
        this.details.zipPlaneSglTop, //32
        this.details.glasscolumnMid, //33
        this.details.glasscompoundMid, //34
        undefined, // 35
        undefined, // 36
        undefined, // 37
        undefined, // 38
        undefined, // 39
        this.details.zipHolder2, // 39
        undefined, // 40
        undefined, // 41
        undefined // 42
      );
    }
  }
  changeExpertMode(bool) {
    this.expertMode = bool;
    if (this.currentModel) {
      this.currentModel.edges.visible = !bool;
    }

    this.extras = {
      servicesTG: {
        aufmassVorOrt: false,
        baugensuch: false,
        planung: false,
        montagePlanung: false,
      },
      externalServicesTG: {
        fundamente: false,
        plattenpassen: false,
        colFundamente: 0,
        colPlattenpassen: 0,
      },
    };

    this.funcUpdatePrice();
    setTimeout(() => {
      this.window1Menu.visible = !bool;
      this.window2Menu.visible = !bool;
      this.zipMenu.visible = !bool;
      this.zipRoofMenu.visible = !bool;
      this.lamellenMenu.visible = !bool;
      this.menuRoof.visible = !bool;

      this.viewer.onWindowResize();
    }, 50);
  }
  async infoProductGlassModel(w, d, h1, h2) {
    const result = await ProjectService.getProducts({
      products: [
        {
          id: '1001',
          qty: 1,
          Variables: [
            {
              VariableName: 'Breite',
              VariableValue: w / 10,
            },
            {
              VariableName: 'Tiefe',
              VariableValue: d / 10,
            },
            {
              VariableName: 'HoeheUKT',
              VariableValue: h1 / 10,
            },
            {
              VariableName: 'HoeheOKW',
              VariableValue: h2 / 10,
            },
          ],
        },
      ],
    });
    return result.prices[0];
  }
  async funcSelectModel(
    base,
    beam,
    column,
    roof,
    glassRoof = null,
    zipPlaneSide = null,
    zipTop2 = null,
    zipColumn2 = null,
    zipBottom2 = null,
    zipHolder = null,
    zipColumn = null,
    zipTop = null,
    zipBottom = null,
    windowBorder = null,
    windowFrame = null,
    windowGlass = null,
    windowHandle = null,
    windowPartition = null,
    windowFrameT = null,
    windowFrameB1 = null,
    windowFrameB2 = null,
    windowGlassC = null,
    windowGlassB = null,
    zipTop3 = null,
    zipColumn3 = null,
    zipBottom3 = null,
    zipCenter2 = null,
    zipCenter3 = null,
    roof2 = null,
    zipTop4 = null,
    roofp = null,
    zipPlaneSglTop = null,
    glasscolumnMid = null,
    glasscompoundMid = null,
    lamellen = null,
    lamellenRailTop = null,
    lamellenRailBottom = null,
    lamellenFrameColumn = null,
    lamellenFrameBase = null,
    zipHolder2 = null,
    solar = null,
    roofClip1,
    roofClip2
  ) {
    let model = null;
    let wall = null;

    this.viewer.onWindowResize();

    if (this.typeModel === 'louver') {
      model = new ConstructLouver(
        base,
        beam,
        column,
        roof,
        5000,
        2100,
        3000,
        zipPlaneSide,
        zipColumn,
        zipTop,
        zipBottom,
        windowBorder,
        windowFrame,
        windowGlass,
        windowHandle,
        windowPartition,
        windowFrameT,
        windowFrameB1,
        windowFrameB2,
        windowGlassC,
        windowGlassB,
        lamellen,
        lamellenRailTop,
        lamellenRailBottom,
        lamellenFrameColumn,
        lamellenFrameBase
      );
      wall = false;
    }

    if (this.typeModel === 'louver2') {
      model = new ConstructLouver2(
        base,
        beam,
        column,
        roof,
        5000,
        2100,
        3000,
        zipPlaneSide,
        zipColumn,
        zipTop,
        zipBottom,
        lamellen,
        lamellenRailTop,
        lamellenRailBottom,
        lamellenFrameColumn,
        lamellenFrameBase
      );
      wall = false;
    }

    if (this.typeModel === 'louver3') {
      model = new ConstructLouver3(
        solar,
        base,
        beam,
        column,
        roof,
        roofClip1,
        roofClip2,
        5000,
        2100,
        3000,
        zipPlaneSide,
        zipColumn,
        zipTop,
        zipBottom,
        windowBorder,
        windowFrame,
        windowGlass,
        windowHandle,
        windowPartition,
        windowFrameT,
        windowFrameB1,
        windowFrameB2,
        windowGlassC,
        windowGlassB,
        lamellen,
        lamellenRailTop,
        lamellenRailBottom,
        lamellenFrameColumn,
        lamellenFrameBase
      );
      wall = false;
    }

    if (this.typeModel === 'glass') {
      // const info = await this.infoProductGlassModel(5000, 3000, 2100, 2500);
      model = new ConstructGlass(
        this.resInfoProductGlassModel,
        base,
        beam,
        column,
        roof,
        roof2,
        roofp,
        glassRoof,
        zipPlaneSide,
        zipPlaneSglTop,
        zipColumn,
        zipTop,
        zipBottom,
        zipTop2,
        zipColumn2,
        zipBottom2,
        zipTop3,
        zipColumn3,
        zipBottom3,
        zipHolder,
        zipHolder2,
        zipCenter2,
        zipCenter3,
        windowBorder,
        windowFrame,
        windowGlass,
        windowHandle,
        windowPartition,
        windowFrameT,
        windowFrameB1,
        windowFrameB2,
        windowGlassC,
        windowGlassB,
        zipTop4,
        glasscolumnMid,
        glasscompoundMid,
        5000,
        2100,
        2500,
        3000
      );
      wall = true;
    }

    const newElement = new Element(
      model,
      () => {
        this.elements.push(newElement);
        this.newTargetControls();
        this.viewer.scene.add(newElement);
        this.funcUpdatePrice();
      },
      undefined,
      false,
      wall
    );
    this.currentModel = newElement;
    // this.currentModel.edges.visible = false;
  }
  updEl() {
    // if (this.currentModel && this.currentModel.bb) {
    //   this.currentModel.bb.visible = true;
    // }
    //
    // setTimeout(() => {
    //   this.currentModel.bb.visible = true;
    // }, 0)

    this.elements
      .map((el) => el.raycasterElMesh)
      .flat()
      .forEach((item) => {
        if (item.name === 'sideRoof') {
          this.current = item;
        }
      });
  }
  toggleFullScreen(state) {
    this.fullscreen = state;

    if (this.current) {
      this.current.material.color.set(0x61c38b);
      this.current.material.opacity = 0.2;
      this.current.parent.parent.children[0].visible = false;
      this.current.parent.parent.deleteMenu(this.menuRoof);
      this.current.parent.parent.deleteMenu(this.menuRoof);
      this.current.parent.parent.deleteMenu(this.zipMenu);
      this.current.parent.parent.deleteMenu(this.window1Menu);
      this.current.parent.parent.deleteMenu(this.window2Menu);
      this.current.parent.parent.deleteMenu(this.zipRoofMenu);
      this.current.parent.parent.deleteMenu(this.lamellenMenu);
      // this.current = null;
      // this.funcCheckSide(undefined);
    }
  }
  onMouseDown(edown) {
    const onMouseUp = (eup) => {
      const click =
        Math.abs(edown.x - eup.x) < 5 && Math.abs(edown.y - eup.y) < 5;
      this.container.removeEventListener('mouseup', onMouseUp);
      if (!click) return;
      this.onClick(eup);
    };
    this.container.addEventListener('mouseup', onMouseUp);
  }
  onClick(event) {
    if (this.expertMode) return;
    if (event.target.classList.value) {
      return;
    }
    if (this.fullscreen) return;

    this.mouse.x =
      ((event.clientX - this.container.offsetLeft) /
        this.container.clientWidth) *
        2 -
      1;
    this.mouse.y =
      -(
        (event.clientY - this.container.offsetTop) /
        this.container.clientHeight
      ) *
        2 +
      1;

    this.raycasterMenu.setFromCamera(this.mouse, this.viewer.camera);
    const intersectsMenu = this.raycasterMenu.intersectObjects(
      this.raycasterMenuMesh
    );
    if (intersectsMenu.length && this.current) {
      this.currentMenu = intersectsMenu.find(
        (el) => el.object.parent.parent
      )?.object;
      if (!this.currentMenu) return;

      if (
        this.currentMenu.name === 'window1MenuDelete' &&
        this.window1Menu.parent
      ) {
        this.current.parent.parent.arrWindow.forEach((item) => {
          if (item.side === this.current.name) {
            this.funcToggleWindow(undefined, undefined, item.type);
            if (item.type === 'windowType1') {
              this.current.parent.parent.deleteMenu(this.window1Menu);
            }
          }
        });
      }
      if (
        this.currentMenu.name === 'window2MenuDelete' &&
        this.window2Menu.parent
      ) {
        this.current.parent.parent.arrWindow.forEach((item) => {
          if (item.side === this.current.name) {
            this.funcToggleWindow(undefined, undefined, item.type);
            if (item.type === 'windowType2') {
              this.current.parent.parent.deleteMenu(this.window1Menu);
            }
          }
        });
      }

      if (this.currentMenu.name === 'zipMenuDelete' && this.zipMenu.parent) {
        this.current.parent.parent.arrZip.forEach((item) => {
          if (item.side === this.current.name) {
            this.funcToggleZip(undefined, false, undefined);
            this.current.parent.parent.deleteMenu(this.zipMenu);
          }
        });
      }

      if (
        this.currentMenu.name === 'lamellenMenuDelete' &&
        this.lamellenMenu.parent
      ) {
        this.current.parent.parent.arrLamellen.forEach((item) => {
          if (item.side === this.current.name) {
            this.funcToggleLamellen(undefined, false, undefined);
            this.current.parent.parent.deleteMenu(this.lamellenMenu);
          }
        });
      }

      if (
        this.currentMenu.name === 'zipRoofMenuDelete' &&
        this.zipRoofMenu.parent
      ) {
        this.current.parent.parent.arrZip.forEach((item) => {
          if (item.side === this.current.name) {
            this.funcToggleZip(undefined, false, undefined);
            this.current.parent.parent.deleteMenu(this.zipRoofMenu);
          }
        });
      }

      if (this.currentMenu.name === 'window1MenuEdit') {
        if (!this.window1Menu.parent) {
          return;
        }
        this.dispatchEvent({
          type: 'type-window',
          value: 'windowType1',
        });
      }

      if (this.currentMenu.name === 'window2MenuEdit') {
        if (!this.window2Menu.parent) {
          return;
        }
        this.dispatchEvent({
          type: 'type-window',
          value: 'windowType2',
        });
      }

      if (this.currentMenu.name === 'lamellenMenuEdit') {
        if (!this.lamellenMenu.parent) {
          return;
        }
        this.dispatchEvent({
          type: 'type-lamellen',
          value: 'lamellenType1',
          side: this.current.name,
        });
      }

      if (this.currentMenu.name === 'zipMenuEdit') {
        if (!this.zipMenu.parent) {
          return;
        }
        let filteredWindow = this.current.parent.parent.arrWindow.filter(
          (el) => el.side === this.current.name
        );
        this.dispatchEvent({
          type: 'type-zip',
          side: this.current.name,
          checkWindow: filteredWindow,
        });
      }

      if (this.currentMenu.name === 'zipRoofMenuEdit') {
        if (!this.zipRoofMenu.parent) {
          return;
        }
        let filteredWindow = this.current.parent.parent.arrWindow.filter(
          (el) => el.side === this.current.name
        );
        this.dispatchEvent({
          type: 'type-zip-roof',
          side: this.current.name,
          checkWindow: filteredWindow,
        });
      }

      if (
        this.currentMenu.name === 'delete' &&
        this.currentMenu.parent.name !== 'buffer'
      ) {
        History.execute(
          new RmElCommand(
            this,
            this.current.parent.parent,
            this.funcUpdatePrice.bind(this)
          )
        );
        this.funcRmEl();
      }
      if (this.currentMenu.name === 'edit') {
        const model = this.currentMenu.parent.parent.model;

        if (this.typeModel === 'louver') {
          this.funcOpenEdit(model.x, model.z, model.y);
        }
        if (this.typeModel === 'louver2') {
          this.funcOpenEdit(model.x, model.z, model.y);
        }
        if (this.typeModel === 'louver3') {
          this.funcOpenEdit(model.x, model.z, model.y);
        }
        if (this.typeModel === 'glass') {
          this.funcOpenEdit(model.x, model.z, model.y1, model.y2);
        }
      }
      return;
    }

    this.raycasterEl.setFromCamera(this.mouse, this.viewer.camera);
    const intersectsEl = this.raycasterEl.intersectObjects(
      this.elements.map((el) => el.raycasterElMesh).flat()
    );

    if (!intersectsEl.length) {
      this.funcCheckSide();
      if (this.current) {
        this.current.material.color.set(0x61c38b);
        this.current.material.opacity = 0.2;
        this.current.parent.parent.children[0].visible = false;
        this.current.parent.parent.deleteMenu(this.menuRoof);
        this.current.parent.parent.deleteMenu(this.zipMenu);
        this.current.parent.parent.deleteMenu(this.window1Menu);
        this.current.parent.parent.deleteMenu(this.window2Menu);
        this.current.parent.parent.deleteMenu(this.zipRoofMenu);
        this.current.parent.parent.deleteMenu(this.lamellenMenu);
        this.current = null;
      }
      return;
    }

    this.elements.forEach((item) => {
      item.bb.visible = false;
    });

    if (this.current) {
      this.current.material.color.set(0x61c38b);
      this.current.material.opacity = 0.2;
    }

    this.current = intersectsEl[0].object;
    this.current.parent.visible = true;

    this.current.parent.parent.attachMenu(this.menuRoof);

    this.current.parent.parent.deleteMenu(this.zipMenu, this.elements);
    this.current.parent.parent.deleteMenu(this.window1Menu, this.elements);
    this.current.parent.parent.deleteMenu(this.window2Menu, this.elements);
    this.current.parent.parent.deleteMenu(this.zipRoofMenu, this.elements);
    this.current.parent.parent.deleteMenu(this.lamellenMenu, this.elements);

    this.current.parent.parent.arrZip?.forEach((el) => {
      if (el.side === this.current.name && el.side === 'sideRoof') {
        this.current.parent.parent.attachMenu(
          this.zipRoofMenu,
          this.current.name,
          true
        );
      }
    });

    this.current.parent.parent.arrZip?.forEach((el) => {
      if (el.side === this.current.name && el.side !== 'sideRoof') {
        this.current.parent.parent.attachMenu(this.zipMenu, this.current.name);
      }
    });

    this.current.parent.parent.arrWindow?.forEach((el) => {
      if (el.side === this.current.name && el.type === 'windowType1') {
        this.current.parent.parent.attachMenu(
          this.window1Menu,
          this.current.name
        );
      }
    });

    this.current.parent.parent.arrWindow?.forEach((el) => {
      if (el.side === this.current.name && el.type === 'windowType2') {
        this.current.parent.parent.attachMenu(
          this.window2Menu,
          this.current.name
        );
      }
    });

    this.current.parent.parent.arrLamellen?.forEach((el) => {
      if (el.side === this.current.name) {
        this.current.parent.parent.attachMenu(
          this.lamellenMenu,
          this.current.name
        );
      }
    });

    this.current.parent.children[0].material.color.set(0x61c38b);
    this.current.parent.children[0].material.opacity = 0.2;
    this.current.parent.children[1].material.color.set(0x61c38b);
    this.current.parent.children[1].material.opacity = 0.2;
    this.current.parent.children[2].material.color.set(0x61c38b);
    this.current.parent.children[2].material.opacity = 0.2;
    this.current.parent.children[3].material.color.set(0x61c38b);
    this.current.parent.children[3].material.opacity = 0.2;
    this.current.parent.children[4].material.color.set(0x61c38b);
    this.current.parent.children[4].material.opacity = 0.2;

    this.funcCheckSide(this.current);
    this.current.material.color.set(0x00e060);
    this.current.material.opacity = 0.3;
    this.current.parent.parent.children[0].visible = true;
  }
  delEl(side) {
    this.current.parent.parent.arrWindow.forEach((item) => {
      if (side === item.side) {
        this.funcToggleWindow(undefined, undefined, item.type);
      }
    });
    this.current.parent.parent.arrZip.forEach((item) => {
      if (side === item.side) {
        this.funcToggleZip(undefined, false, undefined);
      }
    });
    this.current.parent.parent.arrLamellen.forEach((item) => {
      if (side === item.side) {
        this.funcToggleLamellen(undefined, false, undefined);
      }
    });

    if (
      this.currentMenu.name === 'delete' &&
      this.currentMenu.parent.name !== 'buffer'
    ) {
      History.execute(
        new RmElCommand(
          this,
          this.current.parent.parent,
          this.funcUpdatePrice.bind(this)
        )
      );
      this.funcRmEl();
    }
  }
  findSide(side) {
    this.window1Menu.visible = false;
    this.window2Menu.visible = false;
    this.zipMenu.visible = false;
    this.zipRoofMenu.visible = false;
    this.lamellenMenu.visible = false;
    if (side) {
      this.elements
        .map((el) => el.raycasterElMesh)
        .flat()
        .forEach((item) => {
          if (item.name === side) {
            this.current = item;
            // console.log(item, 'item');
            this.current.parent.children[0].material.color.set(0x61c38b);
            this.current.parent.children[0].material.opacity = 0.2;
            this.current.parent.children[1].material.color.set(0x61c38b);
            this.current.parent.children[1].material.opacity = 0.2;
            this.current.parent.children[2].material.color.set(0x61c38b);
            this.current.parent.children[2].material.opacity = 0.2;
            this.current.parent.children[3].material.color.set(0x61c38b);
            this.current.parent.children[3].material.opacity = 0.2;
            this.current.parent.children[4].material.color.set(0x61c38b);
            this.current.parent.children[4].material.opacity = 0.2;

            this.funcCheckSide(this.current);
            this.current.material.color.set(0x00e060);
            this.current.material.opacity = 0.3;
            this.current.parent.parent.children[0].visible = true;
          }
        });
    } else if (side === null) {
      this.currentModel.bb.visible = false;
      // this.current.parent.children[0].material.color.set(0x61c38b);
      // this.current.parent.children[0].material.opacity = 0.2;
      // this.current.parent.children[1].material.color.set(0x61c38b);
      // this.current.parent.children[1].material.opacity = 0.2;
      // this.current.parent.children[2].material.color.set(0x61c38b);
      // this.current.parent.children[2].material.opacity = 0.2;
      // this.current.parent.children[3].material.color.set(0x61c38b);
      // this.current.parent.children[3].material.opacity = 0.2;
      // this.current.parent.children[4].material.color.set(0x61c38b);
      // this.current.parent.children[4].material.opacity = 0.2;
      this.funcCheckSide();
      // if (this.current) {
      //   console.log(this.current, 'this.current')
      //   this.current.material.color.set(0x61c38b);
      //   this.current.material.opacity = 0.2;
      //   this.current.parent.parent.children[0].visible = false;
      //   this.current.parent.parent.deleteMenu(this.menuRoof);
      //   this.current.parent.parent.deleteMenu(this.zipMenu);
      //   this.current.parent.parent.deleteMenu(this.window1Menu);
      //   this.current.parent.parent.deleteMenu(this.window2Menu);
      //   this.current.parent.parent.deleteMenu(this.zipRoofMenu);
      //   this.current.parent.parent.deleteMenu(this.lamellenMenu);
      //   this.current = null;
      // }
    }
  }
  createBtnRoof() {
    this.buffer.name = 'buffer';
    this.planeEdit = new Mesh(
      new PlaneGeometry(0.512, 0.512),
      new MeshBasicMaterial({
        transparent: true,
        color: 0xffffff,
        map: new TextureLoader().load(pngEdit),
        side: THREE.DoubleSide,
      })
    );
    this.planeEdit.position.set(-0.3, 0.4, 0);
    this.planeEdit.renderOrder = 4;
    this.planeEdit.name = 'edit';
    this.raycasterMenuMesh.push(this.planeEdit);
    this.menuRoof.add(this.planeEdit);

    this.planeDelete = new Mesh(
      new PlaneGeometry(0.512, 0.512),
      new MeshBasicMaterial({
        transparent: true,
        color: 0xffffff,
        map: new TextureLoader().load(pngDelete),
        side: THREE.DoubleSide,
      })
    );
    this.planeDelete.position.set(0.3, 0.4, 0);
    this.planeDelete.renderOrder = 4;
    this.planeDelete.name = 'delete';
    this.raycasterMenuMesh.push(this.planeDelete);
    this.menuRoof.add(this.planeDelete);
    this.planeText = new Mesh(
      new PlaneGeometry(1.1, 0.18),
      new MeshBasicMaterial({
        color: 0xffffff,
        transparent: true,
        map: new TextureLoader().load(pngRoof),
        side: THREE.DoubleSide,
      })
    );
    this.planeText.renderOrder = 4;
    this.menuRoof.add(this.planeText);

    this.planeEdit.material.map.encoding = 3001;
    this.planeDelete.material.map.encoding = 3001;
    this.planeText.material.map.encoding = 3001;
  }
  updateBtnRoof() {
    const pos = this?.viewer?.camera.position;
    if (!pos) return;

    if (this.elements.length <= 1) {
      this.buffer.add(this.planeDelete);
      this.planeEdit.position.set(0, 0.4, 0);
    } else {
      this.menuRoof.add(this.planeDelete);
      this.planeEdit.position.set(-0.3, 0.4, 0);
    }

    if (this.menuRoof.parent && this.zipRoofMenu.parent) {
      this.menuRoof.lookAt(pos.x, pos.y, pos.z);
      this.menuRoof.position.z = 0.66;
    } else {
      this.menuRoof.lookAt(pos.x, pos.y, pos.z);
      this.menuRoof.position.z = 0;
    }
  }
  updatetBtnWindow1Menu() {
    const pos = this?.viewer?.camera.position;
    if (!pos) return;

    if (this.window1Menu.parent && this.zipMenu.parent) {
      this.window1Menu.lookAt(pos.x, pos.y, pos.z);

      if (this.current?.name === 'sideA' || this.current?.name === 'sideC') {
        this.window1Menu.position.x = 0.66;
      } else {
        this.window1Menu.position.z = 0.66;
      }
    } else {
      this.window1Menu.lookAt(pos.x, pos.y, pos.z);
      if (this.current?.name === 'sideA' || this.current?.name === 'sideC') {
        this.window1Menu.position.x = 0;
      } else {
        this.window1Menu.position.z = 0;
      }
    }
  }
  updatetBtnLamellenMenu() {
    const pos = this?.viewer?.camera.position;
    if (!pos) return;

    if (this.lamellenMenu.parent && this.zipMenu.parent) {
      this.lamellenMenu.lookAt(pos.x, pos.y, pos.z);

      if (this.current?.name === 'sideA' || this.current?.name === 'sideC') {
        this.lamellenMenu.position.x = 0.66;
      } else {
        this.lamellenMenu.position.z = 0.66;
      }
    } else {
      this.lamellenMenu.lookAt(pos.x, pos.y, pos.z);
      if (this.current?.name === 'sideA' || this.current?.name === 'sideC') {
        this.lamellenMenu.position.x = 0;
      } else {
        this.lamellenMenu.position.z = 0;
      }
    }
  }
  updatetBtnWindow2Menu() {
    const pos = this?.viewer?.camera.position;
    if (!pos) return;

    if (this.window2Menu.parent && this.zipMenu.parent) {
      this.window2Menu.lookAt(pos.x, pos.y, pos.z);

      if (this.current?.name === 'sideA' || this.current?.name === 'sideC') {
        this.window2Menu.position.x = 0.66;
      } else {
        this.window2Menu.position.z = 0.66;
      }
    } else {
      this.window2Menu.lookAt(pos.x, pos.y, pos.z);
      if (this.current?.name === 'sideA' || this.current?.name === 'sideC') {
        this.window2Menu.position.x = 0;
      } else {
        this.window2Menu.position.z = 0;
      }
    }
  }
  updatetBtnZipMenu() {
    const pos = this?.viewer?.camera.position;
    if (!pos) return;

    if (
      (this.window1Menu.parent ||
        this.window2Menu.parent ||
        this.lamellenMenu.parent) &&
      this.zipMenu.parent
    ) {
      this.zipMenu.lookAt(pos.x, pos.y, pos.z);

      if (this.current?.name === 'sideA' || this.current?.name === 'sideC') {
        this.zipMenu.position.x = -0.66;
      } else {
        this.zipMenu.position.z = -0.66;
      }
    } else {
      this.zipMenu.lookAt(pos.x, pos.y, pos.z);
      if (this.current?.name === 'sideA' || this.current?.name === 'sideC') {
        this.zipMenu.position.x = 0;
      } else {
        this.zipMenu.position.z = 0;
      }
    }
  }
  updatetBtnZipRoofMenu() {
    const pos = this?.viewer?.camera.position;
    if (!pos) return;

    if (this.menuRoof.parent && this.zipRoofMenu.parent) {
      this.zipRoofMenu.lookAt(pos.x, pos.y, pos.z);
      this.zipRoofMenu.position.z = -0.66;
    } else {
      this.zipRoofMenu.lookAt(pos.x, pos.y, pos.z);
      this.zipRoofMenu.position.z = 0;
    }
  }
  newTargetControls() {
    if (!this.elements.length) return;

    const boxTarget = new Box3();
    boxTarget.makeEmpty();

    const menuRoof = this.viewer.scene.getObjectByName('menuRoof');

    const window1Menu = this.window1Menu;
    const window2Menu = this.window2Menu;
    const zipMenu = this.zipMenu;
    const zipRoofMenu = this.zipRoofMenu;
    const lamellenMenu = this.lamellenMenu;

    let parentMenuRoof = null;
    let parentWindow1Menu = null;
    let parentWindow2Menu = null;
    let parentZipMenu = null;
    let parentZipRoofMenu = null;
    let parentLamellenMenu = null;

    if (menuRoof?.parent) {
      parentMenuRoof = menuRoof.parent;
      parentMenuRoof.remove(menuRoof);
    }
    if (window1Menu?.parent) {
      parentWindow1Menu = window1Menu.parent;
      parentWindow1Menu.remove(window1Menu);
    }
    if (window2Menu?.parent) {
      parentWindow2Menu = window2Menu.parent;
      parentWindow2Menu.remove(window2Menu);
    }
    if (zipMenu?.parent) {
      parentZipMenu = zipMenu.parent;
      parentZipMenu.remove(zipMenu);
    }
    if (zipRoofMenu?.parent) {
      parentZipRoofMenu = zipRoofMenu.parent;
      parentZipRoofMenu.remove(zipRoofMenu);
    }
    if (lamellenMenu?.parent) {
      parentLamellenMenu = lamellenMenu.parent;
      parentLamellenMenu.remove(lamellenMenu);
    }

    for (const object of this.elements) {
      boxTarget.expandByObject(object);
    }
    const centerTarget = new Vector3();
    boxTarget.getCenter(centerTarget);

    if (parentMenuRoof) {
      parentMenuRoof.add(menuRoof);
    }
    if (parentWindow1Menu) {
      parentWindow1Menu.add(window1Menu);
    }
    if (parentWindow2Menu) {
      parentWindow2Menu.add(window2Menu);
    }
    if (parentZipMenu) {
      parentZipMenu.add(zipMenu);
    }
    if (parentZipRoofMenu) {
      parentZipRoofMenu.add(zipRoofMenu);
    }
    if (parentLamellenMenu) {
      parentLamellenMenu.add(lamellenMenu);
    }

    this.viewer.controls.target.copy(centerTarget);
  }
  async funcAddEl(
    x = this.globalWidth,
    y1 = this.globalHeight1,
    y2 = this.globalHeight2,
    z = this.globalDepth
  ) {
    if (
      this.typeModel === 'glass'
      // && (this.typeModel === 'sideB' || this.typeModel === 'sideD')
    )
      return;
    if (!this.current) return;
    const { name } = this.current;
    if (!['sideA', 'sideB', 'sideC', 'sideD'].includes(name)) return;
    if (this.current.userData.wall.parent) return;
    const worldPosition = new Vector3();
    this.current.getWorldPosition(worldPosition);
    let direction = 1;
    let axis = 'x';

    switch (this.current.name) {
      case 'sideA': {
        direction = 1;
        axis = 'z';
        break;
      }
      case 'sideB': {
        direction = 1;
        axis = 'x';
        break;
      }
      case 'sideC': {
        direction = -1;
        axis = 'z';
        break;
      }
      case 'sideD': {
        direction = -1;
        axis = 'x';
        break;
      }
      default:
        break;
    }
    this.elements.forEach((item) => {
      item.bb.visible = false;
    });

    let model = null;
    let wall = null;

    if (this.typeModel === 'louver') {
      model = new ConstructLouver(
        this.details.louverBase,
        this.details.louverBeam,
        this.details.louverColumn,
        this.details.louverRoof,
        x,
        y1,
        z,
        this.details.zipPlaneSklSide,
        this.details.zipColumn,
        this.details.zipTop,
        this.details.zipBottom,
        this.details.windowBorder,
        this.details.windowFrame,
        this.details.windowGlass,
        this.details.windowHandle,
        this.details.windowPartition,
        this.details.windowFrameT,
        this.details.windowFrameB1,
        this.details.windowFrameB2,
        this.details.windowGlassC,
        this.details.windowGlassB,
        this.details.lamellen,
        this.details.lamellenRailTop,
        this.details.lamellenRailBottom,
        this.details.lamellenFrameColumn,
        this.details.lamellenFrameBase
      );
      wall = false;
    }

    if (this.typeModel === 'louver2') {
      model = new ConstructLouver2(
        this.details.louver2Base,
        this.details.louver2Beam,
        this.details.louver2Column,
        this.details.louver2Roof,
        x,
        y1,
        z,
        this.details.zipPlaneSklSide,
        this.details.zipColumn,
        this.details.zipTop,
        this.details.zipBottom,
        this.details.windowBorder,
        this.details.windowFrame,
        this.details.windowGlass,
        this.details.windowHandle,
        this.details.windowPartition,
        this.details.windowFrameT,
        this.details.windowFrameB1,
        this.details.windowFrameB2,
        this.details.windowGlassC,
        this.details.windowGlassB,
        this.details.lamellen,
        this.details.lamellenRailTop,
        this.details.lamellenRailBottom,
        this.details.lamellenFrameColumn,
        this.details.lamellenFrameBase
      );
      wall = false;
    }

    if (this.typeModel === 'louver3') {
      model = new ConstructLouver3(
        this.details.louver3Solar,
        this.details.louver3Base,
        this.details.louver3Beam,
        this.details.louver3Column,
        this.details.louver3Roof,
        this.details.louver3RoofClip1,
        this.details.louver3RoofClip2,
        x,
        y1,
        z,
        this.details.zipPlaneSklSide,
        this.details.zipColumn,
        this.details.zipTop,
        this.details.zipBottom,
        this.details.windowBorder,
        this.details.windowFrame,
        this.details.windowGlass,
        this.details.windowHandle,
        this.details.windowPartition,
        this.details.windowFrameT,
        this.details.windowFrameB1,
        this.details.windowFrameB2,
        this.details.windowGlassC,
        this.details.windowGlassB,
        this.details.lamellen,
        this.details.lamellenRailTop,
        this.details.lamellenRailBottom,
        this.details.lamellenFrameColumn,
        this.details.lamellenFrameBase
      );
      wall = false;
    }

    if (this.typeModel === 'glass') {
      // const info = await this.infoProductGlassModel(x, z, y1, y2);
      model = new ConstructGlass(
        this.resInfoProductGlassModel,
        this.details.glassBase,
        this.details.glassBeam,
        this.details.glassColumn,
        this.details.glassRoofL,
        this.details.glassRoofT,
        this.details.glassRoofP,
        this.details.glassGlassRoof,
        this.details.zipPlaneSglSide,
        this.details.zipPlaneSglTop,
        this.details.zipColumn5,
        this.details.zipTop5,
        this.details.zipBottom5,
        this.details.zipTop2,
        this.details.zipColumn2,
        this.details.zipBottom2,
        this.details.zipTop3,
        this.details.zipColumn3,
        this.details.zipBottom3,
        this.details.zipHolder,
        this.details.zipHolder2,
        this.details.zipCenter2,
        this.details.zipCenter3,
        this.details.windowSglBorder,
        this.details.windowSglFrame,
        this.details.windowSglGlass,
        this.details.windowSglHandle,
        this.details.windowSglPartition,
        this.details.windowSglFrameT,
        this.details.windowSglFrameB1,
        this.details.windowSglFrameB2,
        this.details.windowSglGlassC,
        this.details.windowSglGlassB,
        this.details.zipTop4,
        this.details.glasscolumnMid,
        this.details.glasscompoundMid,
        x,
        y1,
        y2,
        z
      );
      wall = true;
    }

    const temp = this.current;
    const newElement = new Element(
      model,
      () => {
        newElement.position.copy(worldPosition);
        newElement.position[axis] =
          worldPosition[axis] + (newElement.size[axis] / 2) * direction;
        newElement.position.y = 0;
        this.newTargetControls();
        newElement.attachMenu(this.menuRoof);
        newElement.position2d = temp.new2dPosition();
        this.current = newElement.children[0].children[0];

        this.current.material.color.set(0x00e060);
        this.current.material.opacity = 0.3;
        this.funcCheckSide(this.current);

        // this.current = null;

        newElement.update(
          model,
          () => {
            this.intersectionModels(newElement);
            this.funcUpdatePrice();
          },
          this.expertMode
        );
        History.execute(
          new AddElCommand(this, newElement, this.funcUpdatePrice.bind(this))
        );
      },
      undefined,
      true,
      wall
    );
    this.elements.push(newElement);
    this.viewer.scene.add(newElement);

    this.current.material.color.set(0x61c38b);
    this.current.material.opacity = 0.2;
    this.current = null;

    return { x: +x, z: +z, y1: y1, y2: y2 };
  }
  funcRmElExpertMode() {
    if (this.elements && this.elements.length > 1) {
      for (let i = 1; i < this.elements.length; i++) {
        const element = this.elements[i];

        element.traverse((item) => {
          if (item.geometry) {
            item.geometry.dispose();
          }
        });
        this.viewer.scene.remove(element);
        element.bb.visible = false;
        if (element.uuid === this.current?.parent?.parent?.uuid) {
          this.menuRoof.parent?.remove(this.menuRoof);
        }

        if (!element.parent?.parent?.parent) {
          element.parent?.parent.deleteMenu(this.zipMenu);
          element.parent?.parent.deleteMenu(this.window1Menu);
          element.parent?.parent.deleteMenu(this.window2Menu);
          element.parent?.parent.deleteMenu(this.zipRoofMenu);
          element.parent?.parent.deleteMenu(this.lamellenMenu);
          element.parent?.parent.deleteMenu(this.menuRoof);
        }
      }

      this.elements = [this.elements[0]];
      this.newTargetControls();
      this.funcUpdatePrice();

      if (this.current && !this.elements.includes(this.current)) {
        this.current = null;
        this.funcCheckSide(undefined);
      }
    }
  }
  funcRmEl(object) {
    const element = object || this.current.parent.parent;

    element.traverse((item) => {
      if (item.geometry) {
        item.geometry.dispose();
      }
    });
    this.viewer.scene.remove(element);
    element.bb.visible = false;
    if (element.uuid === this.current?.parent?.parent?.uuid) {
      this.menuRoof.parent?.remove(this.menuRoof);
    }

    this.elements = this.elements.filter((item) => item.uuid !== element.uuid);
    this.newTargetControls();

    if (!this.current?.parent?.parent?.parent) {
      this.current?.parent?.parent.deleteMenu(this.zipMenu);
      this.current?.parent?.parent.deleteMenu(this.window1Menu);
      this.current?.parent?.parent.deleteMenu(this.window2Menu);
      this.current?.parent?.parent.deleteMenu(this.zipRoofMenu);
      this.current?.parent?.parent.deleteMenu(this.lamellenMenu);
      this.current?.parent?.parent.deleteMenu(this.menuRoof);
      this.current = null;
      this.funcCheckSide(undefined);
    }

    this.funcUpdatePrice();
  }
  funcOpenEdit(w, d, h1, h2 = 2.5) {
    this.dispatchEvent({
      type: 'edit-open',
      value: true,
      size: {
        w: w,
        d: d,
        h1: h1,
        h2: h2,
      },
    });
  }
  funcModelSize() {
    let colSectionWindow = 0;
    let colSectionLamellenL = 0;
    let colSectionLamellenR = 0;
    let lamellenWidth = 0;

    if (this.current.name === 'sideA') {
      colSectionWindow = this.current.parent.parent.model.colA;

      colSectionLamellenL = this.current.parent.parent.model.newColLeftA;
      colSectionLamellenR = this.current.parent.parent.model.newColRightA;
      lamellenWidth = this.current.parent.parent.model.newWidthA;
    }
    if (this.current.name === 'sideB') {
      colSectionWindow = this.current.parent.parent.model.colB;

      colSectionLamellenL = this.current.parent.parent.model.newColLeftB;
      colSectionLamellenR = this.current.parent.parent.model.newColRightB;
      lamellenWidth = this.current.parent.parent.model.newWidthB;
    }
    if (this.current.name === 'sideC') {
      colSectionWindow = this.current.parent.parent.model.colC;

      colSectionLamellenL = this.current.parent.parent.model.newColLeftC;
      colSectionLamellenR = this.current.parent.parent.model.newColRightC;
      lamellenWidth = this.current.parent.parent.model.newWidthC;
    }
    if (this.current.name === 'sideD') {
      colSectionWindow = this.current.parent.parent.model.colD;

      colSectionLamellenL = this.current.parent.parent.model.newColLeftD;
      colSectionLamellenR = this.current.parent.parent.model.newColRightD;
      lamellenWidth = this.current.parent.parent.model.newWidthD;
    }

    this.dispatchEvent({
      type: 'model-size',
      side: this.current.name,
      typeModel: this.typeModel,
      size: {
        w: this.globalWidth,
        d: this.globalDepth,
        h1: this.globalHeight1,
        h2: this.globalHeight2,
      },
      sectionWindow: colSectionWindow,
      sectionLamellenL: colSectionLamellenL,
      sectionLamellenR: colSectionLamellenR,
      lamellenWidth: lamellenWidth,
    });
  }
  funcModelSize2() {
    let colSectionWindow = 0;
    let colSectionLamellenL = 0;
    let colSectionLamellenR = 0;
    let lamellenWidth = 0;

    if (this.current.name === 'sideA') {
      colSectionWindow = this.current.parent.parent.model.colA;

      colSectionLamellenL = this.current.parent.parent.model.newColLeftA;
      colSectionLamellenR = this.current.parent.parent.model.newColRightA;
      lamellenWidth = this.current.parent.parent.model.newWidthA;
    }
    if (this.current.name === 'sideB') {
      colSectionWindow = this.current.parent.parent.model.colB;

      colSectionLamellenL = this.current.parent.parent.model.newColLeftB;
      colSectionLamellenR = this.current.parent.parent.model.newColRightB;
      lamellenWidth = this.current.parent.parent.model.newWidthB;
    }
    if (this.current.name === 'sideC') {
      colSectionWindow = this.current.parent.parent.model.colC;

      colSectionLamellenL = this.current.parent.parent.model.newColLeftC;
      colSectionLamellenR = this.current.parent.parent.model.newColRightC;
      lamellenWidth = this.current.parent.parent.model.newWidthC;
    }
    if (this.current.name === 'sideD') {
      colSectionWindow = this.current.parent.parent.model.colD;

      colSectionLamellenL = this.current.parent.parent.model.newColLeftD;
      colSectionLamellenR = this.current.parent.parent.model.newColRightD;
      lamellenWidth = this.current.parent.parent.model.newWidthD;
    }

    this.dispatchEvent({
      type: 'model-size-lam',
      side: this.current.name,
      typeModel: this.typeModel,
      size: {
        w: this.globalWidth,
        d: this.globalDepth,
        h1: this.globalHeight1,
        h2: this.globalHeight2,
      },
      sectionWindow: colSectionWindow,
      sectionLamellenL: colSectionLamellenL,
      sectionLamellenR: colSectionLamellenR,
      lamellenWidth: lamellenWidth,
    });
  }
  async funcEditEl(x, z, y1, y2, changeFlag) {
    if (
      this.globalWidth === x &&
      this.globalDepth === z &&
      this.globalHeight1 === y1 &&
      this.globalHeight2 === y2 &&
      changeFlag === false
    )
      return;

    const windowsSections = {};
    this.elements.forEach((element) => {
      windowsSections[element.uuid] = JSON.parse(
        JSON.stringify(element.colSectionWindow)
      );
    });
    const lamellenSections = {};
    this.elements.forEach((element) => {
      lamellenSections[element.uuid] = JSON.parse(
        JSON.stringify(element.colSectionLamellen)
      );
    });
    const lamellenWidths = {};
    this.elements.forEach((element) => {
      lamellenWidths[element.uuid] = JSON.parse(
        JSON.stringify(element.lamellenWidth)
      );
    });

    const solars = {};
    this.elements.forEach((element) => {
      solars[element.uuid] = JSON.parse(JSON.stringify(element.colSolar));
    });

    if (
      !this.current ||
      !this.current.parent ||
      !this.current.parent.parent ||
      !this.current.parent.parent.model
    ) {
      return;
    }
    const data = {
      prev: [
        this.current.parent.parent.model.x * 1000,
        this.current.parent.parent.model.z * 1000,
        this.current.parent.parent.model.y1 * 1000,
        this.current.parent.parent.model.y2 * 1000 || 0,
      ],
      next: [+x, +z, +y1, +y2],
      windowsSections: windowsSections,
      lamellenSections: lamellenSections,
      lamellenWidths: lamellenWidths,
      solars: solars,
    };

    const infoSection = await this.funcEditElPerformance(
      x,
      z,
      y1,
      y2,
      changeFlag
    );

    History.execute(
      new SizeElCommand(
        this,
        this.current.parent.parent,
        data,
        this.funcUpdatePrice.bind(this)
      )
    );
    return infoSection;
  }
  clearArrForDell() {
    this.array = [];
    this.lastArrayDell = [];
  }
  checkElDel(model) {
    this.model = model;
    const elementsSet = new Set();

    // Zip
    if (this.model.y1 < 1) {
      this.current.parent.parent.arrZip.forEach((el) => {
        if (el.side !== 'sideRoof') {
          elementsSet.add(JSON.stringify({ type: el.type, side: el.side }));
        }
      });
    }
    if (this.model.x < 1 + 0.4 && this.model.type === 'louver') {
      this.current.parent.parent.arrZip.forEach((el) => {
        if (
          el.side !== 'sideA' &&
          el.side !== 'sideC' &&
          el.side !== 'sideRoof'
        ) {
          elementsSet.add(JSON.stringify({ type: el.type, side: el.side }));
        }
      });
    }
    if (this.model.x < 1 + 0.3 && this.model.type === 'louver2') {
      this.current.parent.parent.arrZip.forEach((el) => {
        if (
          el.side !== 'sideA' &&
          el.side !== 'sideC' &&
          el.side !== 'sideRoof'
        ) {
          elementsSet.add(JSON.stringify({ type: el.type, side: el.side }));
        }
      });
    }
    if (this.model.x < 1 + 0.2 && this.model.type === 'glass') {
      this.current.parent.parent.arrZip.forEach((el) => {
        if (
          el.side !== 'sideA' &&
          el.side !== 'sideC' &&
          el.side !== 'sideRoof'
        ) {
          elementsSet.add(JSON.stringify({ type: el.type, side: el.side }));
        }
      });
    }
    if (this.model.z < 1 + 0.2 && this.model.type === 'louver') {
      this.current.parent.parent.arrZip.forEach((el) => {
        if (
          el.side !== 'sideB' &&
          el.side !== 'sideD' &&
          el.side !== 'sideRoof'
        ) {
          elementsSet.add(JSON.stringify({ type: el.type, side: el.side }));
        }
      });
    }
    if (this.model.z < 1 + 0.1 && this.model.type === 'louver2') {
      this.current.parent.parent.arrZip.forEach((el) => {
        if (
          el.side !== 'sideB' &&
          el.side !== 'sideD' &&
          el.side !== 'sideRoof'
        ) {
          elementsSet.add(JSON.stringify({ type: el.type, side: el.side }));
        }
      });
    }
    if (this.model.z < 1 + 0.135 && this.model.type === 'glass') {
      this.current.parent.parent.arrZip.forEach((el) => {
        if (
          el.side !== 'sideB' &&
          el.side !== 'sideD' &&
          el.side !== 'sideRoof'
        ) {
          elementsSet.add(JSON.stringify({ type: el.type, side: el.side }));
        }
      });
    }
    if (this.model.x < 1 && this.model.type === 'glass') {
      this.current.parent.parent.arrZip.forEach((el) => {
        if (el.side === 'sideRoof') {
          elementsSet.add(JSON.stringify({ type: el.type, side: el.side }));
        }
      });
    }
    if (this.model.z < 1 && this.model.type === 'glass') {
      this.current.parent.parent.arrZip.forEach((el) => {
        if (el.side === 'sideRoof') {
          elementsSet.add(JSON.stringify({ type: el.type, side: el.side }));
        }
      });
    }
    if (this.model.y1 > 3.4 + 0.2 && this.model.type === 'louver') {
      this.current.parent.parent.arrZip.forEach((el) => {
        if (el.side !== 'sideRoof' || el.type !== 'zipType2') {
          elementsSet.add(JSON.stringify({ type: el.type, side: el.side }));
        }
      });
    }
    if (this.model.y1 > 3.4 + 0.2 - 0.14 && this.model.type === 'louver') {
      this.current.parent.parent.arrZip.forEach((el) => {
        if (el.side !== 'sideRoof' || el.type !== 'zipType1') {
          elementsSet.add(JSON.stringify({ type: el.type, side: el.side }));
        }
      });
    }
    if (this.model.y1 > 3.4 + 0.2 - 0.14 && this.model.type === 'louver2') {
      this.current.parent.parent.arrZip.forEach((el) => {
        if (el.side !== 'sideRoof' || el.type !== 'zipType1') {
          elementsSet.add(JSON.stringify({ type: el.type, side: el.side }));
        }
      });
    }
    if (this.model.y1 > 4 + 0.15 && this.model.type === 'glass') {
      this.current.parent.parent.arrZip.forEach((el) => {
        if (el.side !== 'sideRoof') {
          elementsSet.add(JSON.stringify({ type: el.type, side: el.side }));
        }
      });
    }
    if (this.model.x > 5.5 && this.model.type === 'glass') {
      this.current.parent.parent.arrZip.forEach((el) => {
        if (
          el.side === 'sideB' &&
          (el.type === 'zipType1' || el.type === 'zipType3')
        ) {
          elementsSet.add(JSON.stringify({ type: el.type, side: el.side }));
        }
      });
    }

    // Window
    if (this.model.y1 < 1) {
      this.current.parent.parent.arrWindow.forEach((el) => {
        if (
          el.side === 'sideB' ||
          el.side === 'sideD' ||
          el.side === 'sideA' ||
          el.side === 'sideC'
        ) {
          elementsSet.add(JSON.stringify({ type: el.type, side: el.side }));
        }
      });
    }
    if (this.model.x < 1 + 0.4 && this.model.type === 'louver') {
      this.current.parent.parent.arrWindow.forEach((el) => {
        if (el.side === 'sideB' || el.side === 'sideD') {
          elementsSet.add(JSON.stringify({ type: el.type, side: el.side }));
        }
      });
    }
    if (this.model.x < 1 + 0.2 && this.model.type === 'glass') {
      this.current.parent.parent.arrWindow.forEach((el) => {
        if (el.side === 'sideA' || el.side === 'sideC') {
          elementsSet.add(JSON.stringify({ type: el.type, side: el.side }));
        }
      });
    }
    if (this.model.z < 1 + 0.2 && this.model.type === 'louver') {
      this.current.parent.parent.arrWindow.forEach((el) => {
        if (el.side === 'sideA' || el.side === 'sideC') {
          elementsSet.add(JSON.stringify({ type: el.type, side: el.side }));
        }
      });
    }
    if (this.model.z < 1 + 0.135 && this.model.type === 'glass') {
      this.current.parent.parent.arrWindow.forEach((el) => {
        if (el.side === 'sideB' || el.side === 'sideD') {
          elementsSet.add(JSON.stringify({ type: el.type, side: el.side }));
        }
      });
    }
    if (this.model.y1 > 3 + 0.2 && this.model.type === 'louver') {
      this.current.parent.parent.arrWindow.forEach((el) => {
        if (el.type === 'windowType1') {
          elementsSet.add(JSON.stringify({ type: el.type, side: el.side }));
        }
      });
    }
    if (this.model.y1 > 3 + 0.2 && this.model.type === 'louver') {
      this.current.parent.parent.arrWindow.forEach((el) => {
        if (el.type === 'windowType2') {
          elementsSet.add(JSON.stringify({ type: el.type, side: el.side }));
        }
      });
    }
    if (this.model.y2 > 4 + 0.15 && this.model.type === 'glass') {
      this.current.parent.parent.arrWindow.forEach((el) => {
        if (el.type === 'windowType1') {
          elementsSet.add(JSON.stringify({ type: el.type, side: el.side }));
        }
      });
    }
    if (this.model.y1 > 2.4 + 0.15 && this.model.type === 'glass') {
      this.current.parent.parent.arrWindow.forEach((el) => {
        if (el.type === 'windowType2') {
          elementsSet.add(JSON.stringify({ type: el.type, side: el.side }));
        }
      });
    }

    // Lamellen
    if (this.model.y > 3 + 0.2 && this.model.type === 'louver') {
      this.current.parent.parent.arrLamellen.forEach((el) => {
        if (
          el.side === 'sideB' ||
          el.side === 'sideD' ||
          el.side === 'sideC' ||
          el.side === 'sideA'
        ) {
          elementsSet.add(JSON.stringify({ type: el.type, side: el.side }));
        }
      });
    }
    if (this.model.x > 6.98 + 0.4 && this.model.type === 'louver') {
      this.current.parent.parent.arrLamellen.forEach((el) => {
        if (el.side === 'sideB' && el.side === 'sideD') {
          elementsSet.add(JSON.stringify({ type: el.type, side: el.side }));
        }
      });
    }
    if (this.model.z > 6.98 + 0.2 && this.model.type === 'louver') {
      this.current.parent.parent.arrLamellen.forEach((el) => {
        if (el.side === 'sideC' && el.side === 'sideA') {
          elementsSet.add(JSON.stringify({ type: el.type, side: el.side }));
        }
      });
    }

    if (this.model.y > 3 + 0.2 && this.model.type === 'louver2') {
      this.current.parent.parent.arrLamellen.forEach((el) => {
        if (
          el.side === 'sideB' ||
          el.side === 'sideD' ||
          el.side === 'sideC' ||
          el.side === 'sideA'
        ) {
          elementsSet.add(JSON.stringify({ type: el.type, side: el.side }));
        }
      });
    }
    if (this.model.x > 6.98 + 0.3 && this.model.type === 'louver2') {
      this.current.parent.parent.arrLamellen.forEach((el) => {
        if (el.side === 'sideB' && el.side === 'sideD') {
          elementsSet.add(JSON.stringify({ type: el.type, side: el.side }));
        }
      });
    }
    if (this.model.z > 6.98 + 0.1 && this.model.type === 'louver2') {
      this.current.parent.parent.arrLamellen.forEach((el) => {
        if (el.side === 'sideC' && el.side === 'sideA') {
          elementsSet.add(JSON.stringify({ type: el.type, side: el.side }));
        }
      });
    }

    this.array = Array.from(elementsSet).map((elStr) => JSON.parse(elStr));

    if (this.array.length > 0) {
      return this.array;
    }
    return [];
  }
  async funcEditElPerformance(
    x,
    z,
    y1,
    y2,
    flag,
    objectEdit,
    windows,
    zips,
    lamellens,
    windowsSections,
    lamellenSections,
    lamellenWidths,
    solars
  ) {
    this.globalHeight1 = y1;
    this.globalHeight2 = y2;
    this.globalWidth = x;
    this.globalDepth = z;
    let model = null;

    if (this.current) {
      this.current.material.color.set(0x61c38b);
      this.current.material.opacity = 0.2;

      this.current = this.current.parent.parent.planeRoof;
      this.current.material.color.set(0x00e060);
      this.current.material.opacity = 0.3;
    } else {
      this.current = objectEdit.planeRoof;
    }

    let newColB = null;
    let newColD = null;
    let newColA = null;
    let newColC = null;

    if (windowsSections) {
      newColB = +windowsSections?.[this.current.parent.parent.uuid].sideB;
      newColD = +windowsSections?.[this.current.parent.parent.uuid].sideD;
      newColA = +windowsSections?.[this.current.parent.parent.uuid].sideA;
      newColC = +windowsSections?.[this.current.parent.parent.uuid].sideC;
    } else {
      this.current.parent.parent.arrWindow.forEach((item) => {
        if (item.type === 'windowType1') {
          if (item.side === 'sideA') {
            const data = this.defaultSectionswindowType1(+z, item.side);
            this.current.parent.parent.colSectionWindow.sideA = data;
            newColA = data;
          } else {
            newColA = +this.current.parent.parent.colSectionWindow.sideA;
          }
          if (item.side === 'sideB') {
            const data = this.defaultSectionswindowType1(+x, item.side);
            this.current.parent.parent.colSectionWindow.sideB = data;
            newColB = data;
          } else {
            newColB = +this.current.parent.parent.colSectionWindow.sideB;
          }
          if (item.side === 'sideC') {
            const data = this.defaultSectionswindowType1(+z, item.side);
            this.current.parent.parent.colSectionWindow.sideC = data;
            newColC = data;
          } else {
            newColC = +this.current.parent.parent.colSectionWindow.sideC;
          }
          if (item.side === 'sideD') {
            const data = this.defaultSectionswindowType1(+x, item.side);
            this.current.parent.parent.colSectionWindow.sideD = data;
            newColD = data;
          } else {
            newColD = +this.current.parent.parent.colSectionWindow.sideD;
          }
        }
        if (item.type === 'windowType2') {
          if (item.side === 'sideA') {
            const data = this.defaultSectionswindowType2(+z, item.side);
            this.current.parent.parent.colSectionWindow.sideA = data;
            newColA = data;
          } else {
            newColA = +this.current.parent.parent.colSectionWindow.sideA;
          }
          if (item.side === 'sideB') {
            const data = this.defaultSectionswindowType2(+x, item.side);
            this.current.parent.parent.colSectionWindow.sideB = data;
            newColB = data;
          } else {
            newColB = +this.current.parent.parent.colSectionWindow.sideB;
          }
          if (item.side === 'sideC') {
            const data = this.defaultSectionswindowType2(+z, item.side);
            this.current.parent.parent.colSectionWindow.sideC = data;
            newColC = data;
          } else {
            newColC = +this.current.parent.parent.colSectionWindow.sideC;
          }
          if (item.side === 'sideD') {
            const data = this.defaultSectionswindowType2(+x, item.side);
            this.current.parent.parent.colSectionWindow.sideD = data;
            newColD = data;
          } else {
            newColD = +this.current.parent.parent.colSectionWindow.sideD;
          }
        }
      });
    }
    if (lamellenSections && lamellenWidths) {
      this.current.parent.parent.colSectionLamellen.sideB[0] =
        +lamellenSections?.[this.current.parent.parent.uuid].sideB[0];
      this.current.parent.parent.colSectionLamellen.sideD[0] =
        +lamellenSections?.[this.current.parent.parent.uuid].sideD[0];
      this.current.parent.parent.colSectionLamellen.sideA[0] =
        +lamellenSections?.[this.current.parent.parent.uuid].sideA[0];
      this.current.parent.parent.colSectionLamellen.sideC[0] =
        +lamellenSections?.[this.current.parent.parent.uuid].sideC[0];
      this.current.parent.parent.colSectionLamellen.sideB[1] =
        +lamellenSections?.[this.current.parent.parent.uuid].sideB[1];
      this.current.parent.parent.colSectionLamellen.sideD[1] =
        +lamellenSections?.[this.current.parent.parent.uuid].sideD[1];
      this.current.parent.parent.colSectionLamellen.sideA[1] =
        +lamellenSections?.[this.current.parent.parent.uuid].sideA[1];
      this.current.parent.parent.colSectionLamellen.sideC[1] =
        +lamellenSections?.[this.current.parent.parent.uuid].sideC[1];

      this.current.parent.parent.lamellenWidth.sideB =
        +lamellenWidths?.[this.current.parent.parent.uuid].sideD;
      this.current.parent.parent.lamellenWidth.sideD =
        +lamellenWidths?.[this.current.parent.parent.uuid].sideD;
      this.current.parent.parent.lamellenWidth.sideA =
        +lamellenWidths?.[this.current.parent.parent.uuid].sideA;
      this.current.parent.parent.lamellenWidth.sideC =
        +lamellenWidths?.[this.current.parent.parent.uuid].sideC;
    } else {
      this.current.parent.parent.arrLamellen.forEach((item) => {
        if (item.side === 'sideA') {
          const data = Math.floor(
            (+z - 200) / (this.current.parent.parent.lamellenWidth.sideA * 1000)
          );
          if (this.current.parent.parent.colSectionLamellen.sideA[0] > data) {
            this.current.parent.parent.colSectionLamellen.sideA[0] = data;
          }
          if (this.current.parent.parent.colSectionLamellen.sideA[1] > data) {
            this.current.parent.parent.colSectionLamellen.sideA[1] = data;
          }
        }
        if (item.side === 'sideB') {
          const data = Math.floor(
            (+x - 400) / (this.current.parent.parent.lamellenWidth.sideB * 1000)
          );
          if (this.current.parent.parent.colSectionLamellen.sideB[0] > data) {
            this.current.parent.parent.colSectionLamellen.sideB[0] = data;
          }
          if (this.current.parent.parent.colSectionLamellen.sideB[1] > data) {
            this.current.parent.parent.colSectionLamellen.sideB[1] = data;
          }
        }
        if (item.side === 'sideC') {
          const data = Math.floor(
            (+z - 200) / (this.current.parent.parent.lamellenWidth.sideC * 1000)
          );
          if (this.current.parent.parent.colSectionLamellen.sideC[0] > data) {
            this.current.parent.parent.colSectionLamellen.sideC[0] = data;
          }
          if (this.current.parent.parent.colSectionLamellen.sideC[1] > data) {
            this.current.parent.parent.colSectionLamellen.sideC[1] = data;
          }
        }
        if (item.side === 'sideD') {
          const data = Math.floor(
            (+x - 400) / (this.current.parent.parent.lamellenWidth.sideD * 1000)
          );
          if (this.current.parent.parent.colSectionLamellen.sideD[0] > data) {
            this.current.parent.parent.colSectionLamellen.sideD[0] = data;
          }
          if (this.current.parent.parent.colSectionLamellen.sideD[1] > data) {
            this.current.parent.parent.colSectionLamellen.sideD[1] = data;
          }
        }
      });
    }

    if (solars) {
      this.current.parent.parent.colSolar =
        solars?.[this.current.parent.parent.uuid] || 0;
    }

    if (this.typeModel === 'louver') {
      model = new ConstructLouver(
        this.details.louverBase,
        this.details.louverBeam,
        this.details.louverColumn,
        this.details.louverRoof,
        x,
        y1,
        z,
        this.details.zipPlaneSklSide,
        this.details.zipColumn,
        this.details.zipTop,
        this.details.zipBottom,
        this.details.windowBorder,
        this.details.windowFrame,
        this.details.windowGlass,
        this.details.windowHandle,
        this.details.windowPartition,
        this.details.windowFrameT,
        this.details.windowFrameB1,
        this.details.windowFrameB2,
        this.details.windowGlassC,
        this.details.windowGlassB,
        this.details.lamellen,
        this.details.lamellenRailTop,
        this.details.lamellenRailBottom,
        this.details.lamellenFrameColumn,
        this.details.lamellenFrameBase,
        newColB,
        newColD,
        newColA,
        newColC,
        this.current.parent.parent.colSectionLamellen.sideB[0],
        this.current.parent.parent.colSectionLamellen.sideD[0],
        this.current.parent.parent.colSectionLamellen.sideA[0],
        this.current.parent.parent.colSectionLamellen.sideC[0],
        this.current.parent.parent.colSectionLamellen.sideB[1],
        this.current.parent.parent.colSectionLamellen.sideD[1],
        this.current.parent.parent.colSectionLamellen.sideA[1],
        this.current.parent.parent.colSectionLamellen.sideC[1],
        this.current.parent.parent.lamellenWidth.sideB,
        this.current.parent.parent.lamellenWidth.sideD,
        this.current.parent.parent.lamellenWidth.sideA,
        this.current.parent.parent.lamellenWidth.sideC
      );
    }

    if (this.typeModel === 'louver2') {
      model = new ConstructLouver2(
        this.details.louver2Base,
        this.details.louver2Beam,
        this.details.louver2Column,
        this.details.louver2Roof,
        x,
        y1,
        z,
        this.details.zipPlaneSklSide,
        this.details.zipColumn,
        this.details.zipTop,
        this.details.zipBottom,
        this.details.lamellen,
        this.details.lamellenRailTop,
        this.details.lamellenRailBottom,
        this.details.lamellenFrameColumn,
        this.details.lamellenFrameBase,
        this.current.parent.parent.colSectionLamellen.sideB[0],
        this.current.parent.parent.colSectionLamellen.sideD[0],
        this.current.parent.parent.colSectionLamellen.sideA[0],
        this.current.parent.parent.colSectionLamellen.sideC[0],
        this.current.parent.parent.colSectionLamellen.sideB[1],
        this.current.parent.parent.colSectionLamellen.sideD[1],
        this.current.parent.parent.colSectionLamellen.sideA[1],
        this.current.parent.parent.colSectionLamellen.sideC[1],
        this.current.parent.parent.lamellenWidth.sideB,
        this.current.parent.parent.lamellenWidth.sideD,
        this.current.parent.parent.lamellenWidth.sideA,
        this.current.parent.parent.lamellenWidth.sideC
      );
    }

    if (this.typeModel === 'louver3') {
      model = new ConstructLouver3(
        this.details.louver3Solar,
        this.details.louver3Base,
        this.details.louver3Beam,
        this.details.louver3Column,
        this.details.louver3Roof,
        this.details.louver3RoofClip1,
        this.details.louver3RoofClip2,
        x,
        y1,
        z,
        this.details.zipPlaneSklSide,
        this.details.zipColumn,
        this.details.zipTop,
        this.details.zipBottom,
        this.details.windowBorder,
        this.details.windowFrame,
        this.details.windowGlass,
        this.details.windowHandle,
        this.details.windowPartition,
        this.details.windowFrameT,
        this.details.windowFrameB1,
        this.details.windowFrameB2,
        this.details.windowGlassC,
        this.details.windowGlassB,
        this.details.lamellen,
        this.details.lamellenRailTop,
        this.details.lamellenRailBottom,
        this.details.lamellenFrameColumn,
        this.details.lamellenFrameBase,
        newColB,
        newColD,
        newColA,
        newColC,
        this.current.parent.parent.colSectionLamellen.sideB[0],
        this.current.parent.parent.colSectionLamellen.sideD[0],
        this.current.parent.parent.colSectionLamellen.sideA[0],
        this.current.parent.parent.colSectionLamellen.sideC[0],
        this.current.parent.parent.colSectionLamellen.sideB[1],
        this.current.parent.parent.colSectionLamellen.sideD[1],
        this.current.parent.parent.colSectionLamellen.sideA[1],
        this.current.parent.parent.colSectionLamellen.sideC[1],
        this.current.parent.parent.lamellenWidth.sideB,
        this.current.parent.parent.lamellenWidth.sideD,
        this.current.parent.parent.lamellenWidth.sideA,
        this.current.parent.parent.lamellenWidth.sideC
      );
    }

    if (this.typeModel === 'glass') {
      const info = await this.infoProductGlassModel(x, z, y1, y2);
      this.resInfoProductGlassModel = info;
      model = new ConstructGlass(
        info,
        this.details.glassBase,
        this.details.glassBeam,
        this.details.glassColumn,
        this.details.glassRoofL,
        this.details.glassRoofT,
        this.details.glassRoofP,
        this.details.glassGlassRoof,
        this.details.zipPlaneSglSide,
        this.details.zipPlaneSglTop,
        this.details.zipColumn5,
        this.details.zipTop5,
        this.details.zipBottom5,
        this.details.zipTop2,
        this.details.zipColumn2,
        this.details.zipBottom2,
        this.details.zipTop3,
        this.details.zipColumn3,
        this.details.zipBottom3,
        this.details.zipHolder,
        this.details.zipHolder2,
        this.details.zipCenter2,
        this.details.zipCenter3,
        this.details.windowSglBorder,
        this.details.windowSglFrame,
        this.details.windowSglGlass,
        this.details.windowSglHandle,
        this.details.windowSglPartition,
        this.details.windowSglFrameT,
        this.details.windowSglFrameB1,
        this.details.windowSglFrameB2,
        this.details.windowSglGlassC,
        this.details.windowSglGlassB,
        this.details.zipTop4,
        this.details.glasscolumnMid,
        this.details.glasscompoundMid,
        x,
        y1,
        y2,
        z,
        newColB,
        newColD,
        newColA,
        newColC
      );
    }

    const arrayDell = this.checkElDel(model);

    if (
      arrayDell.length > 0 &&
      flag &&
      JSON.stringify(arrayDell) !== JSON.stringify(this.lastArrayDell)
    ) {
      this.lastArrayDell = arrayDell;
      return arrayDell;
    }

    this.current.parent.parent.update(
      model,
      () => {
        this.intersectionModels(this.current.parent.parent);
        this.current = this.current.parent.parent.children[0].children[0];
        this.current.parent.parent.updateMenu(this.menuRoof);

        if (
          this.current.parent.parent.arrZip.find((el) => el.side === 'sideRoof')
        ) {
          this.current.parent.parent.attachMenu(
            this.zipRoofMenu,
            this.current.name,
            true
          );
        }

        this.current.parent.parent.deleteMenu(this.window1Menu);
        this.current.parent.parent.deleteMenu(this.window2Menu);
        this.current.parent.parent.deleteMenu(this.zipMenu);
        this.current.parent.parent.deleteMenu(this.lamellenMenu);

        this.current.parent.parent.showSolar(
          this.current.parent.parent.colSolar
        );

        if (windows?.[this.current.parent.parent.uuid]) {
          const window = windows?.[this.current.parent.parent.uuid];
          window.forEach((el) => {
            this.current.parent.parent.showWindow(el.side, el.type);
          });
        }
        if (zips?.[this.current.parent.parent.uuid]) {
          const zip = zips?.[this.current.parent.parent.uuid];
          zip.forEach((el) => {
            this.current.parent.parent.showZip(el.side, el.type);
          });
        }

        if (lamellens?.[this.current.parent.parent.uuid]) {
          const lamellen = lamellens?.[this.current.parent.parent.uuid];
          lamellen.forEach((el) => {
            this.current.parent.parent.showLamellen(el.side, el.type);
          });
        }

        this.funcCheckSide(this.current);

        if (objectEdit && objectEdit.bb.visible === false) {
          this.current = null;
          this.funcCheckSide(undefined);
        }

        if (this.typeModel === 'louver3') {
          this.current.parent.parent.update(
            this.current.parent.parent.model,
            () => {
              if (this.current.parent.parent.model.bias) {
                this.current.parent.parent.position.x +=
                  this.current.parent.parent.position2d.x *
                  this.current.parent.parent.model.bias.x;
                this.current.parent.parent.position.z -=
                  this.current.parent.parent.position2d.z *
                  this.current.parent.parent.model.bias.z;
              }
              this.newTargetControls();
              this.funcUpdatePrice();
            }
          );
        }
        this.newTargetControls();
        this.funcUpdatePrice();
      },
      this.expertMode
    );

    this.funcUpdateSize(
      x,
      z,
      y1,
      y2,
      windows,
      zips,
      lamellens,
      windowsSections,
      lamellenSections,
      lamellenWidths,
      solars
    );

    // this.lastArrayDell = arrayDell;
    // return arrayDell;
  }
  funcUpdateSize(
    x,
    z,
    y1,
    y2,
    windows,
    zips,
    lamellens,
    windowsSections,
    lamellenSections,
    lamellenWidths,
    solars
  ) {
    this.elements
      .filter((element) => element.uuid !== this.current?.parent?.parent.uuid)
      .forEach((element) => {
        let newColB = null;
        let newColD = null;
        let newColA = null;
        let newColC = null;

        if (windowsSections) {
          newColB = +windowsSections?.[element.uuid].sideB;
          newColD = +windowsSections?.[element.uuid].sideD;
          newColA = +windowsSections?.[element.uuid].sideA;
          newColC = +windowsSections?.[element.uuid].sideC;
        } else {
          element.arrWindow.forEach((item) => {
            if (item.type === 'windowType1') {
              if (item.side === 'sideA') {
                const data = this.defaultSectionswindowType1(+z, item.side);
                element.colSectionWindow.sideA = data;
                newColA = data;
              } else {
                newColA = +element.colSectionWindow.sideA;
              }
              if (item.side === 'sideB') {
                const data = this.defaultSectionswindowType1(+x, item.side);
                element.colSectionWindow.sideB = data;
                newColB = data;
              } else {
                newColB = +element.colSectionWindow.sideB;
              }
              if (item.side === 'sideC') {
                const data = this.defaultSectionswindowType1(+z, item.side);
                element.colSectionWindow.sideC = data;
                newColC = data;
              } else {
                newColC = +element.colSectionWindow.sideC;
              }
              if (item.side === 'sideD') {
                const data = this.defaultSectionswindowType1(+x, item.side);
                element.colSectionWindow.sideD = data;
                newColD = data;
              } else {
                newColD = +element.colSectionWindow.sideD;
              }
            }
            if (item.type === 'windowType2') {
              if (item.side === 'sideA') {
                const data = this.defaultSectionswindowType2(+z, item.side);
                element.colSectionWindow.sideA = data;
                newColA = data;
              } else {
                newColA = +element.colSectionWindow.sideA;
              }
              if (item.side === 'sideB') {
                const data = this.defaultSectionswindowType2(+x, item.side);
                element.colSectionWindow.sideB = data;
                newColB = data;
              } else {
                newColB = +element.colSectionWindow.sideB;
              }
              if (item.side === 'sideC') {
                const data = this.defaultSectionswindowType2(+z, item.side);
                element.colSectionWindow.sideC = data;
                newColC = data;
              } else {
                newColC = +element.colSectionWindow.sideC;
              }
              if (item.side === 'sideD') {
                const data = this.defaultSectionswindowType2(+x, item.side);
                element.colSectionWindow.sideD = data;
                newColD = data;
              } else {
                newColD = +element.colSectionWindow.sideD;
              }
            }
          });
        }

        let colSectionLamellenB0 = 0;
        let colSectionLamellenD0 = 0;
        let colSectionLamellenA0 = 0;
        let colSectionLamellenC0 = 0;
        let colSectionLamellenB1 = 0;
        let colSectionLamellenD1 = 0;
        let colSectionLamellenA1 = 0;
        let colSectionLamellenC1 = 0;
        let lamellenWidthB = 1;
        let lamellenWidthD = 1;
        let lamellenWidthA = 1;
        let lamellenWidthC = 1;

        // let colSolar = element.colSolar;

        if (lamellenSections && lamellenWidths) {
          colSectionLamellenB0 =
            +lamellenSections?.[this.current.parent.parent.uuid].sideB[0];
          colSectionLamellenD0 =
            +lamellenSections?.[this.current.parent.parent.uuid].sideD[0];
          colSectionLamellenA0 =
            +lamellenSections?.[this.current.parent.parent.uuid].sideA[0];
          colSectionLamellenC0 =
            +lamellenSections?.[this.current.parent.parent.uuid].sideC[0];
          colSectionLamellenB1 =
            +lamellenSections?.[this.current.parent.parent.uuid].sideB[1];
          colSectionLamellenD1 =
            +lamellenSections?.[this.current.parent.parent.uuid].sideD[1];
          colSectionLamellenA1 =
            +lamellenSections?.[this.current.parent.parent.uuid].sideA[1];
          colSectionLamellenC1 =
            +lamellenSections?.[this.current.parent.parent.uuid].sideC[1];

          lamellenWidthB =
            +lamellenWidths?.[this.current.parent.parent.uuid].sideD;
          lamellenWidthD =
            +lamellenWidths?.[this.current.parent.parent.uuid].sideD;
          lamellenWidthA =
            +lamellenWidths?.[this.current.parent.parent.uuid].sideA;
          lamellenWidthC =
            +lamellenWidths?.[this.current.parent.parent.uuid].sideC;
        } else {
          element.arrLamellen.forEach((item) => {
            if (item.side === 'sideA') {
              const data = Math.floor(
                (+z - 200) / (element.lamellenWidth.sideA * 1000)
              );

              lamellenWidthA = element.lamellenWidth.sideA;
              if (element.colSectionLamellen.sideA[0] > data) {
                element.colSectionLamellen.sideA[0] = data;
                colSectionLamellenA0 = data;
              } else {
                element.colSectionLamellen.sideA[0] =
                  element.colSectionLamellen.sideA[0];
                colSectionLamellenA0 = element.colSectionLamellen.sideA[0];
              }
              if (element.colSectionLamellen.sideA[1] > data) {
                element.colSectionLamellen.sideA[1] = data;
                colSectionLamellenA1 = data;
              } else {
                element.colSectionLamellen.sideA[1] =
                  element.colSectionLamellen.sideA[1];
                colSectionLamellenA1 = element.colSectionLamellen.sideA[1];
              }
            }
            if (item.side === 'sideB') {
              const data = Math.floor(
                (+x - 400) / (element.lamellenWidth.sideB * 1000)
              );
              lamellenWidthB = element.lamellenWidth.sideB;
              if (element.colSectionLamellen.sideB[0] > data) {
                element.colSectionLamellen.sideB[0] = data;
                colSectionLamellenB0 = data;
              } else {
                element.colSectionLamellen.sideB[0] =
                  element.colSectionLamellen.sideB[0];
                colSectionLamellenB0 = element.colSectionLamellen.sideB[0];
              }
              if (element.colSectionLamellen.sideB[1] > data) {
                element.colSectionLamellen.sideB[1] = data;
                colSectionLamellenB1 = data;
              } else {
                element.colSectionLamellen.sideB[1] =
                  element.colSectionLamellen.sideB[1];
                colSectionLamellenB1 = element.colSectionLamellen.sideB[1];
              }
            }
            if (item.side === 'sideC') {
              const data = Math.floor(
                (+z - 200) / (element.lamellenWidth.sideC * 1000)
              );
              lamellenWidthC = element.lamellenWidth.sideC;
              if (element.colSectionLamellen.sideC[0] > data) {
                element.colSectionLamellen.sideC[0] = data;
                colSectionLamellenC0 = data;
              } else {
                element.colSectionLamellen.sideC[0] =
                  element.colSectionLamellen.sideC[0];
                colSectionLamellenC0 = element.colSectionLamellen.sideC[0];
              }
              if (element.colSectionLamellen.sideC[1] > data) {
                element.colSectionLamellen.sideC[1] = data;
                colSectionLamellenC1 = data;
              } else {
                element.colSectionLamellen.sideC[1] =
                  element.colSectionLamellen.sideC[1];
                colSectionLamellenC1 = element.colSectionLamellen.sideC[1];
              }
            }
            if (item.side === 'sideD') {
              const data = Math.floor(
                (+x - 400) / (element.lamellenWidth.sideD * 1000)
              );
              lamellenWidthD = element.lamellenWidth.sideD;
              if (element.colSectionLamellen.sideD[0] > data) {
                element.colSectionLamellen.sideD[0] = data;
                colSectionLamellenD0 = data;
              } else {
                element.colSectionLamellen.sideD[0] =
                  element.colSectionLamellen.sideD[0];
                colSectionLamellenD0 = element.colSectionLamellen.sideD[0];
              }
              if (element.colSectionLamellen.sideD[1] > data) {
                element.colSectionLamellen.sideD[1] = data;
                colSectionLamellenD1 = data;
              } else {
                element.colSectionLamellen.sideD[1] =
                  element.colSectionLamellen.sideD[1];
                colSectionLamellenD1 = element.colSectionLamellen.sideD[1];
              }
            }
          });
        }

        // if (solars) {
        //   colSolar = solars?.[this.current.parent.parent.uuid]?.colSolar || 0;
        // } else {
        //   colSolar = element.colSolar;
        // }

        if (solars) {
          element.colSolar = solars?.[element.uuid] || 0;
        }

        let model = null;
        if (element.model.type === 'louver') {
          model = new ConstructLouver(
            this.details.louverBase,
            this.details.louverBeam,
            this.details.louverColumn,
            this.details.louverRoof,
            x,
            y1,
            z,
            this.details.zipPlaneSklSide,
            this.details.zipColumn,
            this.details.zipTop,
            this.details.zipBottom,
            this.details.windowBorder,
            this.details.windowFrame,
            this.details.windowGlass,
            this.details.windowHandle,
            this.details.windowPartition,
            this.details.windowFrameT,
            this.details.windowFrameB1,
            this.details.windowFrameB2,
            this.details.windowGlassC,
            this.details.windowGlassB,
            this.details.lamellen,
            this.details.lamellenRailTop,
            this.details.lamellenRailBottom,
            this.details.lamellenFrameColumn,
            this.details.lamellenFrameBase,
            newColB,
            newColD,
            newColA,
            newColC,
            colSectionLamellenB0,
            colSectionLamellenD0,
            colSectionLamellenA0,
            colSectionLamellenC0,
            colSectionLamellenB1,
            colSectionLamellenD1,
            colSectionLamellenA1,
            colSectionLamellenC1,
            lamellenWidthB,
            lamellenWidthD,
            lamellenWidthA,
            lamellenWidthC
          );
        }

        if (element.model.type === 'louver2') {
          model = new ConstructLouver2(
            this.details.louver2Base,
            this.details.louver2Beam,
            this.details.louver2Column,
            this.details.louver2Roof,
            x,
            y1,
            z,
            this.details.zipPlaneSklSide,
            this.details.zipColumn,
            this.details.zipTop,
            this.details.zipBottom,
            this.details.lamellen,
            this.details.lamellenRailTop,
            this.details.lamellenRailBottom,
            this.details.lamellenFrameColumn,
            this.details.lamellenFrameBase,
            colSectionLamellenB0,
            colSectionLamellenD0,
            colSectionLamellenA0,
            colSectionLamellenC0,
            colSectionLamellenB1,
            colSectionLamellenD1,
            colSectionLamellenA1,
            colSectionLamellenC1,
            lamellenWidthB,
            lamellenWidthD,
            lamellenWidthA,
            lamellenWidthC
          );
        }

        if (element.model.type === 'louver3') {
          model = new ConstructLouver3(
            this.details.louver3Solar,
            this.details.louver3Base,
            this.details.louver3Beam,
            this.details.louver3Column,
            this.details.louver3Roof,
            this.details.louver3RoofClip1,
            this.details.louver3RoofClip2,
            x,
            y1,
            z,
            this.details.zipPlaneSklSide,
            this.details.zipColumn,
            this.details.zipTop,
            this.details.zipBottom,
            this.details.windowBorder,
            this.details.windowFrame,
            this.details.windowGlass,
            this.details.windowHandle,
            this.details.windowPartition,
            this.details.windowFrameT,
            this.details.windowFrameB1,
            this.details.windowFrameB2,
            this.details.windowGlassC,
            this.details.windowGlassB,
            this.details.lamellen,
            this.details.lamellenRailTop,
            this.details.lamellenRailBottom,
            this.details.lamellenFrameColumn,
            this.details.lamellenFrameBase,
            newColB,
            newColD,
            newColA,
            newColC,
            colSectionLamellenB0,
            colSectionLamellenD0,
            colSectionLamellenA0,
            colSectionLamellenC0,
            colSectionLamellenB1,
            colSectionLamellenD1,
            colSectionLamellenA1,
            colSectionLamellenC1,
            lamellenWidthB,
            lamellenWidthD,
            lamellenWidthA,
            lamellenWidthC
          );
        }

        element.update(model, () => {
          this.intersectionModels(element);
          element.showSolar(element.colSolar);

          if (windows?.[element.uuid]) {
            const window = windows?.[element.uuid];
            window.forEach((el) => {
              element.showWindow(el.side, el.type);
            });
          }

          if (zips?.[element.uuid]) {
            const zip = zips?.[element.uuid];
            zip.forEach((el) => {
              element.showZip(el.side, el.type);
            });
          }

          if (lamellens?.[element.uuid]) {
            const lamellen = lamellens?.[element.uuid];
            lamellen.forEach((el) => {
              element.showZip(el.side, el.type);
            });
          }

          this.current?.parent?.parent.deleteMenu(this.window1Menu);
          this.current?.parent?.parent.deleteMenu(this.window2Menu);
          this.current?.parent?.parent.deleteMenu(this.zipMenu);
          this.current?.parent?.parent.deleteMenu(this.lamellenMenu);

          if (this.typeModel === 'louver3') {
            element.update(element.model, () => {
              if (element.model.bias) {
                element.position.x +=
                  element.position2d.x * element.model.bias.x;
                element.position.z -=
                  element.position2d.z * element.model.bias.z;
              }
              this.newTargetControls();
              this.funcUpdatePrice();
            });
          }
          this.newTargetControls();
          this.funcUpdatePrice();
        });
      });
  }
  intersectionModels(item) {
    if (item.model.bias) {
      item.position.x += item.position2d.x * item.model.bias.x;
      item.position.z -= item.position2d.z * item.model.bias.z;
    }
  }
  funcCheckSide(side) {
    if (side === undefined) {
      this.dispatchEvent({
        type: 'check-side',
        wall: null,
        colWall: null,
        current: null,
        typeModel: this.typeModel,
        window: null,
        zip: null,
        lamellen: null,
        led: null,
        colSolar: 0,
        steering: [],
        led2: [],
        electro: [],
        remoteControl: [],
      });
      return;
    }

    const el = side.parent.parent;
    const bufArrWall = [];
    let wall = false;

    el.bb.children
      .filter((item) => item.userData.wall?.parent)
      .map((item) => {
        bufArrWall.push(item.name);
        if (side.name === item.name) {
          wall = true;
        }
      });

    const { name } = side;

    let filteredWindow = side.parent.parent.arrWindow.filter(
      (el) => el.side === name
    );

    let filteredZip = side.parent.parent.arrZip.filter(
      (el) => el.side === name
    );

    let filteredLamellen = side.parent.parent.arrLamellen.filter(
      (el) => el.side === name
    );

    this.dispatchEvent({
      type: 'check-side',
      wall: wall,
      colWall: bufArrWall.length,
      current: side,
      typeModel: this.typeModel,
      window: filteredWindow,
      zip: filteredZip,
      lamellen: filteredLamellen,
      led: side.parent.parent.arrLed,
      colSolar: side.parent.parent.colSolar,
      led2: side.parent.parent.arrLed2,
      steering: side.parent.parent.arrSteering,
      electro: side.parent.parent.arrElectro,
      remoteControl: side.parent.parent.arrRemoteControl,
    });
  }
  funcToggleWall(side, show) {
    if (side === 'sideRoof') return;
    if (!side) {
      side = this.current;
    }
    if (
      this.typeModel === 'glass' &&
      (side.name === 'sideD' || side.name === 'sideB')
    )
      return;
    if (!side) return;
    if (typeof show === 'undefined') {
      if (side.name === 'sideRoof') return;
      show = !side.userData.wall.parent;
    }
    if (show) {
      History.execute(new AddWallCommand(side));
    } else {
      History.execute(new RmWallCommand(side));
    }
    side?.parent.parent.toggleWall(side, show);
    this.funcCheckSide(side);
    return show;
  }
  defaultSectionswindowType1(size, side) {
    if ((side === 'sideA' || side === 'sideC') && this.typeModel === 'louver') {
      size = size - 200;
    }
    if ((side === 'sideB' || side === 'sideD') && this.typeModel === 'louver') {
      size = size - 400;
    }

    if (
      (side === 'sideA' || side === 'sideC') &&
      this.typeModel === 'louver2'
    ) {
      size = size - 100;
    }
    if (
      (side === 'sideB' || side === 'sideD') &&
      this.typeModel === 'louver2'
    ) {
      size = size - 300;
    }

    if (
      (side === 'sideA' || side === 'sideC') &&
      this.typeModel === 'louver3'
    ) {
      size = size - 260;
    }
    if (
      (side === 'sideB' || side === 'sideD') &&
      this.typeModel === 'louver3'
    ) {
      size = size - 260;
    }

    if ((side === 'sideA' || side === 'sideC') && this.typeModel === 'glass') {
      size = size - 135;
    }
    if ((side === 'sideB' || side === 'sideD') && this.typeModel === 'glass') {
      size = size - 200;
    }

    if (this.typeModel === 'louver' && size > 7000) {
      size = size / 2;
    }

    if (this.typeModel === 'louver2' && size > 7000) {
      size = size / 2;
    }

    if (this.typeModel === 'louver3' && size > 7000) {
      size = size / 2;
    }

    if (this.typeModel === 'glass' && size > 7000) {
      size = size / 2;
    }

    let colSectionWindow = null;

    switch (true) {
      case size >= 0 && size <= 999:
        colSectionWindow = 1;
        break;
      case size >= 1000 && size <= 1999:
        colSectionWindow = 2;
        break;
      case size >= 2000 && size <= 2999:
        colSectionWindow = 3;
        break;
      case size >= 3000 && size <= 3999:
        colSectionWindow = 4;
        break;
      case size >= 4000 && size <= 4999:
        colSectionWindow = 5;
        break;
      case size >= 5000 && size <= 5999:
        colSectionWindow = 6;
        break;
      case size >= 6000 && size <= 6999:
        colSectionWindow = 7;
        break;
      case size >= 7000 && size <= 7999:
        colSectionWindow = 8;
        break;
      case size >= 8000 && size <= 9000:
        colSectionWindow = 9;
        break;
      default:
        colSectionWindow = null;
        break;
    }
    return colSectionWindow;
  }
  defaultSectionswindowType2(size, side) {
    if ((side === 'sideA' || side === 'sideC') && this.typeModel === 'louver') {
      size = size - 200;
    }
    if ((side === 'sideB' || side === 'sideD') && this.typeModel === 'louver') {
      size = size - 400;
    }

    if (
      (side === 'sideA' || side === 'sideC') &&
      this.typeModel === 'louver2'
    ) {
      size = size - 100;
    }
    if (
      (side === 'sideB' || side === 'sideD') &&
      this.typeModel === 'louver2'
    ) {
      size = size - 300;
    }

    if (
      (side === 'sideA' || side === 'sideC') &&
      this.typeModel === 'louver3'
    ) {
      size = size - 260;
    }
    if (
      (side === 'sideB' || side === 'sideD') &&
      this.typeModel === 'louver3'
    ) {
      size = size - 260;
    }

    if ((side === 'sideA' || side === 'sideC') && this.typeModel === 'glass') {
      size = size - 135;
    }
    if ((side === 'sideB' || side === 'sideD') && this.typeModel === 'glass') {
      size = size - 200;
    }

    if (this.typeModel === 'louver' && size > 6980) {
      size = size / 2;
    }

    if (this.typeModel === 'louver2' && size > 6980) {
      size = size / 2;
    }

    if (this.typeModel === 'louver3' && size > 6980) {
      size = size / 2;
    }

    if (this.typeModel === 'glass' && size > 7500) {
      size = size / 2;
    }

    if (this.typeModel === 'louver') {
      const arrSection = [];

      if (size >= 0 && size <= 999) {
        arrSection.push(2);
      }
      if (size >= 1000 && size <= 2800) {
        arrSection.push(2);
      }
      if (size >= 1800 && size <= 4200) {
        arrSection.push(3);
      }
      if (size >= 2600 && size <= 5600) {
        arrSection.push(4);
      }
      if (size >= 3200 && size <= 6980) {
        arrSection.push(5);
      }
      // if (size >= 6980 && size <= 10000) {
      //   arrSection.push(5);
      // }

      const min = Math.min(...arrSection);
      return min;
    }

    if (this.typeModel === 'louver2') {
      const arrSection = [];

      if (size >= 0 && size <= 999) {
        arrSection.push(2);
      }
      if (size >= 1000 && size <= 2800) {
        arrSection.push(2);
      }
      if (size >= 1800 && size <= 4200) {
        arrSection.push(3);
      }
      if (size >= 2600 && size <= 5600) {
        arrSection.push(4);
      }
      if (size >= 3200 && size <= 6980) {
        arrSection.push(5);
      }
      // if (size >= 6980 && size <= 10000) {
      //   arrSection.push(5);
      // }

      const min = Math.min(...arrSection);
      return min;
    }

    if (this.typeModel === 'louver3') {
      const arrSection = [];

      if (size >= 0 && size <= 999) {
        arrSection.push(2);
      }
      if (size >= 1000 && size <= 2800) {
        arrSection.push(2);
      }
      if (size >= 1800 && size <= 4200) {
        arrSection.push(3);
      }
      if (size >= 2600 && size <= 5600) {
        arrSection.push(4);
      }
      if (size >= 3200 && size <= 6980) {
        arrSection.push(5);
      }
      // if (size >= 6980 && size <= 10000) {
      //   arrSection.push(5);
      // }

      const min = Math.min(...arrSection);
      return min;
    }

    if (this.typeModel === 'glass') {
      const arrSection = [];
      if (size >= 0 && size <= 999) {
        arrSection.push(2);
      }
      if (size >= 1000 && size <= 4500) {
        arrSection.push(2);
      }
      if (size >= 1750 && size <= 5500) {
        arrSection.push(3);
      }
      if (size >= 3000 && size <= 6500) {
        arrSection.push(4);
      }
      if (size >= 4000 && size <= 7500) {
        arrSection.push(5);
      }
      // if (size >= 7500 && size <= 10000) {
      //   arrSection.push(5);
      // }

      const min = Math.min(...arrSection);
      return min;
    }
  }
  async funcToggleWindow(side, show, type, sections) {
    if (!side) {
      side = this.current;
    }
    if (!side) return;

    if (side.parent.parent.model.y1 < 1) return;

    if (side.parent.parent.model.type === 'louver') {
      if (
        side.parent.parent.model.x < 1 + 0.4 &&
        (side.name === 'sideB' || side.name === 'sideD')
      )
        return;
      if (
        side.parent.parent.model.z < 1 + 0.2 &&
        (side.name === 'sideA' || side.name === 'sideC')
      )
        return;

      if (side.parent.parent.model.y1 > 3 + 0.2 && type === 'windowType1')
        return;
      if (side.parent.parent.model.y1 > 3 + 0.2 && type === 'windowType2')
        return;

      // if (
      //   side.parent.parent.model.x > 6.98 + 0.4 &&
      //   type === 'windowType1' &&
      //   (side.name === 'sideB' || side.name === 'sideD')
      // )
      //   return;
      // if (
      //   side.parent.parent.model.z > 6.98 + 0.2 &&
      //   type === 'windowType1' &&
      //   (side.name === 'sideA' || side.name === 'sideC')
      // )
      //   return;

      // if (
      //   side.parent.parent.model.x > 7 + 0.4 &&
      //   type === 'windowType2' &&
      //   (side.name === 'sideB' || side.name === 'sideD')
      // )
      //   return;
      // if (
      //   side.parent.parent.model.z > 7 + 0.2 &&
      //   type === 'windowType2' &&
      //   (side.name === 'sideA' || side.name === 'sideC')
      // )
      //   return;
    }
    if (side.parent.parent.model.type === 'louver2') {
      if (
        side.parent.parent.model.x < 1 + 0.4 &&
        (side.name === 'sideB' || side.name === 'sideD')
      )
        return;
      if (
        side.parent.parent.model.z < 1 + 0.2 &&
        (side.name === 'sideA' || side.name === 'sideC')
      )
        return;

      if (side.parent.parent.model.y1 > 3 + 0.2 && type === 'windowType1')
        return;
      if (side.parent.parent.model.y1 > 3 + 0.2 && type === 'windowType2')
        return;

      // if (
      //   side.parent.parent.model.x > 6.98 + 0.4 &&
      //   type === 'windowType1' &&
      //   (side.name === 'sideB' || side.name === 'sideD')
      // )
      //   return;
      // if (
      //   side.parent.parent.model.z > 6.98 + 0.2 &&
      //   type === 'windowType1' &&
      //   (side.name === 'sideA' || side.name === 'sideC')
      // )
      //   return;

      // if (
      //   side.parent.parent.model.x > 7 + 0.4 &&
      //   type === 'windowType2' &&
      //   (side.name === 'sideB' || side.name === 'sideD')
      // )
      //   return;
      // if (
      //   side.parent.parent.model.z > 7 + 0.2 &&
      //   type === 'windowType2' &&
      //   (side.name === 'sideA' || side.name === 'sideC')
      // )
      //   return;
    }
    if (side.parent.parent.model.type === 'glass') {
      if (
        side.parent.parent.model.x < 1 + 0.2 &&
        (side.name === 'sideB' || side.name === 'sideD')
      )
        return;
      if (
        side.parent.parent.model.z < 1 + 0.135 &&
        (side.name === 'sideA' || side.name === 'sideC')
      )
        return;

      if (side.parent.parent.model.y2 > 4 + 0.15 && type === 'windowType1')
        return;
      if (side.parent.parent.model.y1 > 2.4 + 0.15 && type === 'windowType2')
        return;

      // if (
      //   side.parent.parent.model.x > 7 + 0.2 &&
      //   type === 'windowType1' &&
      //   (side.name === 'sideB' || side.name === 'sideD')
      // )
      //   return;
      // if (
      //   side.parent.parent.model.z > 7 + 0.135 &&
      //   type === 'windowType1' &&
      //   (side.name === 'sideA' || side.name === 'sideC')
      // )
      //   return;

      // if (
      //   side.parent.parent.model.x > 7.5 + 0.2 &&
      //   type === 'windowType2' &&
      //   (side.name === 'sideB' || side.name === 'sideD')
      // )
      //   return;
      // if (
      //   side.parent.parent.model.z > 7.5 + 0.135 &&
      //   type === 'windowType2' &&
      //   (side.name === 'sideA' || side.name === 'sideC')
      // )
      //   return;
    }

    let replace = null;
    if (this.typeModel === 'louver') {
      side.parent.parent.arrZip.forEach((zip) => {
        if (zip.side === side.name && zip.type === 'zipType2') {
          const data = side?.parent.parent.toggleZip(side, undefined);
          side.parent.parent.deleteMenu(this.zipMenu);

          replace = (() => {
            let toggle = true;
            const temp = JSON.parse(JSON.stringify(data));
            return () => {
              toggle && this.current
                ? side.parent.parent.attachMenu(this.zipMenu, side.name)
                : side.parent.parent.deleteMenu(this.zipMenu);
              side?.parent.parent.toggleZip(
                side,
                toggle ? temp.prev : undefined
              );
              if (this.current) {
                this.funcCheckSide(this.current);
              }

              toggle = !toggle;
            };
          })();
        }
      });
      side.parent.parent.arrLamellen.forEach((lamellen) => {
        if (lamellen.side === side.name) {
          const data = side?.parent.parent.toggleLamellen(side, undefined);
          side.parent.parent.deleteMenu(this.lamellenMenu);

          replace = (() => {
            let toggle = true;
            const temp = JSON.parse(JSON.stringify(data));
            return () => {
              toggle && this.current
                ? side.parent.parent.attachMenu(this.lamellenMenu, side.name)
                : side.parent.parent.deleteMenu(this.lamellenMenu);
              side?.parent.parent.toggleLamellen(
                side,
                toggle ? temp.prev : undefined
              );
              if (this.current) {
                this.funcCheckSide(this.current);
              }

              toggle = !toggle;
            };
          })();
        }
      });
    }
    if (this.typeModel === 'louver2') {
      side.parent.parent.arrZip.forEach((zip) => {
        if (zip.side === side.name && zip.type === 'zipType2') {
          const data = side?.parent.parent.toggleZip(side, undefined);
          side.parent.parent.deleteMenu(this.zipMenu);

          replace = (() => {
            let toggle = true;
            const temp = JSON.parse(JSON.stringify(data));
            return () => {
              toggle && this.current
                ? side.parent.parent.attachMenu(this.zipMenu, side.name)
                : side.parent.parent.deleteMenu(this.zipMenu);
              side?.parent.parent.toggleZip(
                side,
                toggle ? temp.prev : undefined
              );
              if (this.current) {
                this.funcCheckSide(this.current);
              }

              toggle = !toggle;
            };
          })();
        }
      });
      side.parent.parent.arrLamellen.forEach((lamellen) => {
        if (lamellen.side === side.name) {
          const data = side?.parent.parent.toggleLamellen(side, undefined);
          side.parent.parent.deleteMenu(this.lamellenMenu);

          replace = (() => {
            let toggle = true;
            const temp = JSON.parse(JSON.stringify(data));
            return () => {
              toggle && this.current
                ? side.parent.parent.attachMenu(this.lamellenMenu, side.name)
                : side.parent.parent.deleteMenu(this.lamellenMenu);
              side?.parent.parent.toggleLamellen(
                side,
                toggle ? temp.prev : undefined
              );
              if (this.current) {
                this.funcCheckSide(this.current);
              }

              toggle = !toggle;
            };
          })();
        }
      });
    }

    if (this.typeModel === 'louver3') {
      side.parent.parent.arrZip.forEach((zip) => {
        if (zip.side === side.name && zip.type === 'zipType2') {
          const data = side?.parent.parent.toggleZip(side, undefined);
          side.parent.parent.deleteMenu(this.zipMenu);

          replace = (() => {
            let toggle = true;
            const temp = JSON.parse(JSON.stringify(data));
            return () => {
              toggle && this.current
                ? side.parent.parent.attachMenu(this.zipMenu, side.name)
                : side.parent.parent.deleteMenu(this.zipMenu);
              side?.parent.parent.toggleZip(
                side,
                toggle ? temp.prev : undefined
              );
              if (this.current) {
                this.funcCheckSide(this.current);
              }

              toggle = !toggle;
            };
          })();
        }
      });
      side.parent.parent.arrLamellen.forEach((lamellen) => {
        if (lamellen.side === side.name) {
          const data = side?.parent.parent.toggleLamellen(side, undefined);
          side.parent.parent.deleteMenu(this.lamellenMenu);

          replace = (() => {
            let toggle = true;
            const temp = JSON.parse(JSON.stringify(data));
            return () => {
              toggle && this.current
                ? side.parent.parent.attachMenu(this.lamellenMenu, side.name)
                : side.parent.parent.deleteMenu(this.lamellenMenu);
              side?.parent.parent.toggleLamellen(
                side,
                toggle ? temp.prev : undefined
              );
              if (this.current) {
                this.funcCheckSide(this.current);
              }

              toggle = !toggle;
            };
          })();
        }
      });
    }

    if (typeof show === 'undefined') {
      show = !side.parent.parent.model[type].userData[side.name].parent;
    }

    const prevcolSectionWindow =
      this.current.parent.parent.colSectionWindow[side.name];
    const data = await this.funcToggleWindowPerformance(
      side,
      show,
      type,
      sections
    );
    const nextcolSectionWindow =
      this.current.parent.parent.colSectionWindow[side.name];
    data.prevcolSectionWindow = prevcolSectionWindow;
    data.nextcolSectionWindow = nextcolSectionWindow;

    if (replace) {
      History.execute(
        new ReplaceCommand(
          replace,
          (() => {
            let toggle = false;
            const temp = JSON.parse(JSON.stringify(data));
            return () => {
              side.parent.parent.deleteMenu(this.window1Menu);
              side.parent.parent.deleteMenu(this.window2Menu);

              this.funcToggleWindowPerformance(
                side,
                show,
                toggle ? temp.next : undefined,
                sections
              );

              toggle = !toggle;
            };
          })(),
          this.funcUpdatePrice.bind(this)
        )
      );
    } else {
      History.execute(
        new WindowElCommant(
          side,
          data,
          this.funcToggleWindowPerformance.bind(this),
          this.funcUpdatePrice.bind(this)
        )
      );
    }

    return { value: show, data };
  }
  async funcToggleWindowPerformance(side, show, type, sections) {
    const data = side?.parent.parent.toggleWindow(
      side,
      show ? type : undefined
    );

    side.parent.parent.deleteMenu(this.window1Menu);
    side.parent.parent.deleteMenu(this.window2Menu);

    if (show && type === 'windowType1' && side.parent.visible) {
      side.parent.parent.attachMenu(this.window1Menu, side.name);
    }
    if (show && type === 'windowType2' && side.parent.visible) {
      side.parent.parent.attachMenu(this.window2Menu, side.name);
    }

    let newColB = null;
    let newColD = null;
    let newColA = null;
    let newColC = null;

    if (sections === undefined) {
      if (type === 'windowType1') {
        if (side.name === 'sideA') {
          const data = this.defaultSectionswindowType1(
            this.globalDepth,
            side.name
          );
          side.parent.parent.colSectionWindow.sideA = data;
          newColA = data;
        } else {
          newColA = +side.parent.parent.colSectionWindow.sideA;
        }
        if (side.name === 'sideB') {
          const data = this.defaultSectionswindowType1(
            this.globalWidth,
            side.name
          );
          side.parent.parent.colSectionWindow.sideB = data;
          newColB = data;
        } else {
          newColB = +side.parent.parent.colSectionWindow.sideB;
        }
        if (side.name === 'sideC') {
          const data = this.defaultSectionswindowType1(
            this.globalDepth,
            side.name
          );
          side.parent.parent.colSectionWindow.sideC = data;
          newColC = data;
        } else {
          newColC = +side.parent.parent.colSectionWindow.sideC;
        }
        if (side.name === 'sideD') {
          const data = this.defaultSectionswindowType1(
            this.globalWidth,
            side.name
          );
          side.parent.parent.colSectionWindow.sideD = data;
          newColD = data;
        } else {
          newColD = +side.parent.parent.colSectionWindow.sideD;
        }
      }

      if (type === 'windowType2') {
        if (side.name === 'sideA') {
          const data = this.defaultSectionswindowType2(
            this.globalDepth,
            side.name
          );
          side.parent.parent.colSectionWindow.sideA = data;
          newColA = data;
        } else {
          newColA = +side.parent.parent.colSectionWindow.sideA;
        }
        if (side.name === 'sideB') {
          const data = this.defaultSectionswindowType2(
            this.globalWidth,
            side.name
          );
          side.parent.parent.colSectionWindow.sideB = data;
          newColB = data;
        } else {
          newColB = +side.parent.parent.colSectionWindow.sideB;
        }
        if (side.name === 'sideC') {
          const data = this.defaultSectionswindowType2(
            this.globalDepth,
            side.name
          );
          side.parent.parent.colSectionWindow.sideC = data;
          newColC = data;
        } else {
          newColC = +side.parent.parent.colSectionWindow.sideC;
        }
        if (side.name === 'sideD') {
          const data = this.defaultSectionswindowType2(
            this.globalWidth,
            side.name
          );
          side.parent.parent.colSectionWindow.sideD = data;
          newColD = data;
        } else {
          newColD = +side.parent.parent.colSectionWindow.sideD;
        }
      }
    } else {
      if (side.name === 'sideB') {
        newColB = sections;
        side.parent.parent.colSectionWindow.sideB = +sections;
      } else {
        newColB = side.parent.parent.colSectionWindow.sideB;
      }
      if (side.name === 'sideD') {
        newColD = sections;
        side.parent.parent.colSectionWindow.sideD = +sections;
      } else {
        newColD = +side.parent.parent.colSectionWindow.sideD;
      }
      if (side.name === 'sideA') {
        newColA = sections;
        side.parent.parent.colSectionWindow.sideA = +sections;
      } else {
        newColA = +side.parent.parent.colSectionWindow.sideA;
      }
      if (side.name === 'sideC') {
        newColC = sections;
        side.parent.parent.colSectionWindow.sideC = +sections;
      } else {
        newColC = +side.parent.parent.colSectionWindow.sideC;
      }
    }

    let model = null;
    let wall = null;
    if (this.typeModel === 'louver') {
      model = new ConstructLouver(
        this.details.louverBase,
        this.details.louverBeam,
        this.details.louverColumn,
        this.details.louverRoof,
        this.globalWidth,
        this.globalHeight1,
        this.globalDepth,
        this.details.zipPlaneSklSide,
        this.details.zipColumn,
        this.details.zipTop,
        this.details.zipBottom,
        this.details.windowBorder,
        this.details.windowFrame,
        this.details.windowGlass,
        this.details.windowHandle,
        this.details.windowPartition,
        this.details.windowFrameT,
        this.details.windowFrameB1,
        this.details.windowFrameB2,
        this.details.windowGlassC,
        this.details.windowGlassB,
        this.details.lamellen,
        this.details.lamellenRailTop,
        this.details.lamellenRailBottom,
        this.details.lamellenFrameColumn,
        this.details.lamellenFrameBase,
        newColB,
        newColD,
        newColA,
        newColC,
        side.parent.parent.colSectionLamellen.sideB[0],
        side.parent.parent.colSectionLamellen.sideD[0],
        side.parent.parent.colSectionLamellen.sideA[0],
        side.parent.parent.colSectionLamellen.sideC[0],
        side.parent.parent.colSectionLamellen.sideB[1],
        side.parent.parent.colSectionLamellen.sideD[1],
        side.parent.parent.colSectionLamellen.sideA[1],
        side.parent.parent.colSectionLamellen.sideC[1],
        side.parent.parent.lamellenWidth.sideB,
        side.parent.parent.lamellenWidth.sideD,
        side.parent.parent.lamellenWidth.sideA,
        side.parent.parent.lamellenWidth.sideC
      );
      wall = false;
    }
    if (this.typeModel === 'louver2') {
      model = new ConstructLouver2(
        this.details.louver2Base,
        this.details.louver2Beam,
        this.details.louver2Column,
        this.details.louver2Roof,
        this.globalWidth,
        this.globalHeight1,
        this.globalDepth,
        this.details.zipPlaneSklSide,
        this.details.zipColumn,
        this.details.zipTop,
        this.details.zipBottom,
        this.details.lamellen,
        this.details.lamellenRailTop,
        this.details.lamellenRailBottom,
        this.details.lamellenFrameColumn,
        this.details.lamellenFrameBase,
        side.parent.parent.colSectionLamellen.sideB[0],
        side.parent.parent.colSectionLamellen.sideD[0],
        side.parent.parent.colSectionLamellen.sideA[0],
        side.parent.parent.colSectionLamellen.sideC[0],
        side.parent.parent.colSectionLamellen.sideB[1],
        side.parent.parent.colSectionLamellen.sideD[1],
        side.parent.parent.colSectionLamellen.sideA[1],
        side.parent.parent.colSectionLamellen.sideC[1],
        side.parent.parent.lamellenWidth.sideB,
        side.parent.parent.lamellenWidth.sideD,
        side.parent.parent.lamellenWidth.sideA,
        side.parent.parent.lamellenWidth.sideC
      );
      wall = false;
    }
    if (this.typeModel === 'louver3') {
      model = new ConstructLouver3(
        this.details.louver3Solar,
        this.details.louver3Base,
        this.details.louver3Beam,
        this.details.louver3Column,
        this.details.louver3Roof,
        this.details.louver3RoofClip1,
        this.details.louver3RoofClip2,
        this.globalWidth,
        this.globalHeight1,
        this.globalDepth,
        this.details.zipPlaneSklSide,
        this.details.zipColumn,
        this.details.zipTop,
        this.details.zipBottom,
        this.details.windowBorder,
        this.details.windowFrame,
        this.details.windowGlass,
        this.details.windowHandle,
        this.details.windowPartition,
        this.details.windowFrameT,
        this.details.windowFrameB1,
        this.details.windowFrameB2,
        this.details.windowGlassC,
        this.details.windowGlassB,
        this.details.lamellen,
        this.details.lamellenRailTop,
        this.details.lamellenRailBottom,
        this.details.lamellenFrameColumn,
        this.details.lamellenFrameBase,
        newColB,
        newColD,
        newColA,
        newColC,
        side.parent.parent.colSectionLamellen.sideB[0],
        side.parent.parent.colSectionLamellen.sideD[0],
        side.parent.parent.colSectionLamellen.sideA[0],
        side.parent.parent.colSectionLamellen.sideC[0],
        side.parent.parent.colSectionLamellen.sideB[1],
        side.parent.parent.colSectionLamellen.sideD[1],
        side.parent.parent.colSectionLamellen.sideA[1],
        side.parent.parent.colSectionLamellen.sideC[1],
        side.parent.parent.lamellenWidth.sideB,
        side.parent.parent.lamellenWidth.sideD,
        side.parent.parent.lamellenWidth.sideA,
        side.parent.parent.lamellenWidth.sideC
      );
      wall = false;
    }
    if (this.typeModel === 'glass') {
      // const info = await this.infoProductGlassModel(
      //   this.globalWidth,
      //   this.globalDepth,
      //   this.globalHeight1,
      //   this.globalHeight2
      // );
      model = new ConstructGlass(
        this.resInfoProductGlassModel,
        this.details.glassBase,
        this.details.glassBeam,
        this.details.glassColumn,
        this.details.glassRoofL,
        this.details.glassRoofT,
        this.details.glassRoofP,
        this.details.glassGlassRoof,
        this.details.zipPlaneSglSide,
        this.details.zipPlaneSglTop,
        this.details.zipColumn5,
        this.details.zipTop5,
        this.details.zipBottom5,
        this.details.zipTop2,
        this.details.zipColumn2,
        this.details.zipBottom2,
        this.details.zipTop3,
        this.details.zipColumn3,
        this.details.zipBottom3,
        this.details.zipHolder,
        this.details.zipHolder2,
        this.details.zipCenter2,
        this.details.zipCenter3,
        this.details.windowSglBorder,
        this.details.windowSglFrame,
        this.details.windowSglGlass,
        this.details.windowSglHandle,
        this.details.windowSglPartition,
        this.details.windowSglFrameT,
        this.details.windowSglFrameB1,
        this.details.windowSglFrameB2,
        this.details.windowSglGlassC,
        this.details.windowSglGlassB,
        this.details.zipTop4,
        this.details.glasscolumnMid,
        this.details.glasscompoundMid,
        this.globalWidth,
        this.globalHeight1,
        this.globalHeight2,
        this.globalDepth,
        newColB,
        newColD,
        newColA,
        newColC
      );
      wall = true;
    }

    side.parent.parent.update(model, () => {
      this.intersectionModels(side.parent.parent);
      // side.material.color.set(0x61c38b);
      // side.material.opacity = 0.2;
      // this.current = side;
      side.parent.parent.updateMenu(this.menuRoof);
      this.newTargetControls();

      this.funcUpdatePrice();

      if (this.current) {
        this.funcCheckSide(this.current);
      } else {
        this.funcCheckSide(undefined);
      }
      // this.current = null;
    });
    return data;
  }
  funcToggleZip(side, show, type) {
    if (!side) {
      side = this.current;
    }
    if (!side) return;

    if (side.parent.parent.model.y1 < 1 && side.name !== 'sideRoof') return;
    if (side.parent.parent.model.type === 'louver') {
      if (
        side.parent.parent.model.x < 1 + 0.4 &&
        (side.name === 'sideB' || side.name === 'sideD')
      )
        return;
      if (
        side.parent.parent.model.z < 1 + 0.2 &&
        (side.name === 'sideA' || side.name === 'sideC')
      )
        return;
      if (
        side.parent.parent.model.y1 > 3.4 + 0.2 - 0.14 &&
        el.side !== 'sideRoof' &&
        type === 'zipType1'
      )
        return;

      if (
        side.parent.parent.model.y1 > 3.4 + 0.2 &&
        el.side !== 'sideRoof' &&
        type === 'zipType2'
      )
        return;
    }
    if (side.parent.parent.model.type === 'louver2') {
      if (
        side.parent.parent.model.x < 1 + 0.4 &&
        (side.name === 'sideB' || side.name === 'sideD')
      )
        return;
      if (
        side.parent.parent.model.z < 1 + 0.2 &&
        (side.name === 'sideA' || side.name === 'sideC')
      )
        return;
      if (
        side.parent.parent.model.y1 > 3.4 + 0.2 - 0.14 &&
        el.side !== 'sideRoof' &&
        type === 'zipType1'
      )
        return;

      if (
        side.parent.parent.model.y1 > 3.4 + 0.2 &&
        el.side !== 'sideRoof' &&
        type === 'zipType2'
      )
        return;
    }
    if (side.parent.parent.model.type === 'glass') {
      if (
        side.parent.parent.model.x < 1 + 0.2 &&
        (side.name === 'sideB' || side.name === 'sideD')
      )
        return;
      if (
        side.parent.parent.model.z < 1 + 0.135 &&
        (side.name === 'sideA' || side.name === 'sideC')
      )
        return;
      if (side.parent.parent.model.y1 > 4 + 0.15 && el.side !== 'sideRoof')
        return;
      if (side.parent.parent.model.x < 1 && side.name === 'sideRoof') return;
      if (side.parent.parent.model.z < 1 && side.name === 'sideRoof') return;
    }

    let replace = null;
    if (this.typeModel === 'louver') {
      side.parent.parent.arrWindow.forEach((window) => {
        if (window.side === side.name && type === 'zipType2') {
          const data = side?.parent.parent.toggleWindow(side, undefined);
          if (window.type === 'windowType1') {
            side.parent.parent.deleteMenu(this.window1Menu);
          }
          if (window.type === 'windowType2') {
            side.parent.parent.deleteMenu(this.window2Menu);
          }

          replace = (() => {
            let toggle = true;
            const temp = JSON.parse(JSON.stringify(data));
            return () => {
              side.parent.parent.deleteMenu(this.window1Menu);
              side.parent.parent.deleteMenu(this.window2Menu);

              if (toggle && this.current)
                temp.prev === 'windowType1'
                  ? side.parent.parent.attachMenu(this.window1Menu, side.name)
                  : side.parent.parent.attachMenu(this.window2Menu, side.name);

              side?.parent.parent.toggleWindow(
                side,
                toggle ? temp.prev : undefined
              );
              if (this.current) {
                this.funcCheckSide(this.current);
              }

              toggle = !toggle;
            };
          })();
        }
      });
      side.parent.parent.arrLamellen.forEach((lamellen) => {
        if (lamellen.side === side.name && type === 'zipType2') {
          const data = side?.parent.parent.toggleLamellen(side, undefined);
          side.parent.parent.deleteMenu(this.lamellenMenu);

          replace = (() => {
            let toggle = true;
            const temp = JSON.parse(JSON.stringify(data));
            return () => {
              toggle && this.current
                ? side.parent.parent.attachMenu(this.lamellenMenu, side.name)
                : side.parent.parent.deleteMenu(this.lamellenMenu);
              side?.parent.parent.toggleLamellen(
                side,
                toggle ? temp.prev : undefined
              );
              if (this.current) {
                this.funcCheckSide(this.current);
              }

              toggle = !toggle;
            };
          })();
        }
      });
    }
    if (this.typeModel === 'louver2') {
      side.parent.parent.arrWindow.forEach((window) => {
        if (window.side === side.name && type === 'zipType2') {
          const data = side?.parent.parent.toggleWindow(side, undefined);
          if (window.type === 'windowType1') {
            side.parent.parent.deleteMenu(this.window1Menu);
          }
          if (window.type === 'windowType2') {
            side.parent.parent.deleteMenu(this.window2Menu);
          }

          replace = (() => {
            let toggle = true;
            const temp = JSON.parse(JSON.stringify(data));
            return () => {
              side.parent.parent.deleteMenu(this.window1Menu);
              side.parent.parent.deleteMenu(this.window2Menu);

              if (toggle && this.current)
                temp.prev === 'windowType1'
                  ? side.parent.parent.attachMenu(this.window1Menu, side.name)
                  : side.parent.parent.attachMenu(this.window2Menu, side.name);

              side?.parent.parent.toggleWindow(
                side,
                toggle ? temp.prev : undefined
              );
              if (this.current) {
                this.funcCheckSide(this.current);
              }

              toggle = !toggle;
            };
          })();
        }
      });
      side.parent.parent.arrLamellen.forEach((lamellen) => {
        if (lamellen.side === side.name && type === 'zipType2') {
          const data = side?.parent.parent.toggleLamellen(side, undefined);
          side.parent.parent.deleteMenu(this.lamellenMenu);

          replace = (() => {
            let toggle = true;
            const temp = JSON.parse(JSON.stringify(data));
            return () => {
              toggle && this.current
                ? side.parent.parent.attachMenu(this.lamellenMenu, side.name)
                : side.parent.parent.deleteMenu(this.lamellenMenu);
              side?.parent.parent.toggleLamellen(
                side,
                toggle ? temp.prev : undefined
              );
              if (this.current) {
                this.funcCheckSide(this.current);
              }

              toggle = !toggle;
            };
          })();
        }
      });
    }

    if (this.typeModel === 'louver3') {
      side.parent.parent.arrWindow.forEach((window) => {
        if (window.side === side.name && type === 'zipType2') {
          const data = side?.parent.parent.toggleWindow(side, undefined);
          if (window.type === 'windowType1') {
            side.parent.parent.deleteMenu(this.window1Menu);
          }
          if (window.type === 'windowType2') {
            side.parent.parent.deleteMenu(this.window2Menu);
          }

          replace = (() => {
            let toggle = true;
            const temp = JSON.parse(JSON.stringify(data));
            return () => {
              side.parent.parent.deleteMenu(this.window1Menu);
              side.parent.parent.deleteMenu(this.window2Menu);

              if (toggle && this.current)
                temp.prev === 'windowType1'
                  ? side.parent.parent.attachMenu(this.window1Menu, side.name)
                  : side.parent.parent.attachMenu(this.window2Menu, side.name);

              side?.parent.parent.toggleWindow(
                side,
                toggle ? temp.prev : undefined
              );
              if (this.current) {
                this.funcCheckSide(this.current);
              }

              toggle = !toggle;
            };
          })();
        }
      });
      side.parent.parent.arrLamellen.forEach((lamellen) => {
        if (lamellen.side === side.name && type === 'zipType2') {
          const data = side?.parent.parent.toggleLamellen(side, undefined);
          side.parent.parent.deleteMenu(this.lamellenMenu);

          replace = (() => {
            let toggle = true;
            const temp = JSON.parse(JSON.stringify(data));
            return () => {
              toggle && this.current
                ? side.parent.parent.attachMenu(this.lamellenMenu, side.name)
                : side.parent.parent.deleteMenu(this.lamellenMenu);
              side?.parent.parent.toggleLamellen(
                side,
                toggle ? temp.prev : undefined
              );
              if (this.current) {
                this.funcCheckSide(this.current);
              }

              toggle = !toggle;
            };
          })();
        }
      });
    }

    if (!side) return;
    if (typeof show === 'undefined') {
      show = true;
    }

    side.parent.parent.deleteMenu(this.zipMenu);
    side.parent.parent.deleteMenu(this.zipRoofMenu);

    if (show && side.parent.visible) {
      if (side.name === 'sideRoof') {
        side.parent.parent.attachMenu(this.zipRoofMenu, side.name, true);
      }
      if (side.name !== 'sideRoof') {
        side.parent.parent.attachMenu(this.zipMenu, side.name);
      }
    }

    const data = side?.parent.parent.toggleZip(side, type);
    if (replace) {
      History.execute(
        new ReplaceCommand(
          replace,
          (() => {
            let toggle = false;
            const temp = JSON.parse(JSON.stringify(data));
            return () => {
              toggle && this.current
                ? side.parent.parent.attachMenu(this.zipMenu, side.name)
                : side.parent.parent.deleteMenu(this.zipMenu);
              side?.parent.parent.toggleZip(
                side,
                toggle ? temp.next : undefined
              );
              if (this.current) {
                this.funcCheckSide(this.current);
              }

              if (!toggle && data.prev) {
                if (this.current)
                  side.parent.parent.attachMenu(this.zipMenu, side.name);

                side?.parent.parent.toggleZip(side, data.prev);
              }

              toggle = !toggle;
            };
          })(),
          this.funcUpdatePrice.bind(this)
        )
      );
    } else {
      History.execute(
        new ZipElCommand(
          side,
          data,
          this.funcUpdatePrice.bind(this),
          this.zipMenu,
          this.zipRoofMenu,
          this.funcCheckSide.bind(this),
          this
        )
      );
    }
    side.parent.parent.update(side.parent.parent.model, () => {
      this.intersectionModels(side.parent.parent);
      this.newTargetControls();
      side.parent.parent.updateMenu(this.menuRoof);
      side.parent.parent.updateMenu(this.zipRoofMenu, side.name, true);
      if (this.current) {
        this.funcCheckSide(this.current);
      } else {
        this.funcCheckSide(undefined);
      }
      this.funcUpdatePrice();
    });

    return { value: show, data };
  }
  async funcToggleSolar(side, col) {
    if (!side) {
      side = this.current;
    }
    if (!side) return;

    const data = await this.funcToggleSolarPerformance(side, col);

    History.execute(
      new SolarELCommand(
        side,
        data,
        this.funcToggleSolarPerformance.bind(this),
        this.funcUpdatePrice.bind(this)
      )
    );
    return data;
  }

  async funcToggleSolarPerformance(side, col) {
    const data = side?.parent.parent.toggleSolar(side, col);

    let model = side?.parent.parent.model;
    // let wall = null;
    // if (this.typeModel === 'louver3') {
    //   model = new ConstructLouver3(
    //     this.details.louver3Solar,
    //     this.details.louver3Base,
    //     this.details.louver3Beam,
    //     this.details.louver3Column,
    //     this.details.louver3Roof,
    //     this.details.louver3RoofClip1,
    //     this.details.louver3RoofClip2,
    //     this.globalWidth,
    //     this.globalHeight1,
    //     this.globalDepth,
    //     this.details.zipPlaneSklSide,
    //     this.details.zipColumn,
    //     this.details.zipTop,
    //     this.details.zipBottom,
    //     this.details.windowBorder,
    //     this.details.windowFrame,
    //     this.details.windowGlass,
    //     this.details.windowHandle,
    //     this.details.windowPartition,
    //     this.details.windowFrameT,
    //     this.details.windowFrameB1,
    //     this.details.windowFrameB2,
    //     this.details.windowGlassC,
    //     this.details.windowGlassB,
    //     this.details.lamellen,
    //     this.details.lamellenRailTop,
    //     this.details.lamellenRailBottom,
    //     this.details.lamellenFrameColumn,
    //     this.details.lamellenFrameBase,
    //     side.parent.parent.colSectionWindow.sideB,
    //     side.parent.parent.colSectionWindow.sideD,
    //     side.parent.parent.colSectionWindow.sideA,
    //     side.parent.parent.colSectionWindow.sideC,
    //     side.parent.parent.newColLeftB,
    //     side.parent.parent.newColLeftD,
    //     side.parent.parent.newColLeftA,
    //     side.parent.parent.newColLeftC,
    //     side.parent.parent.newColRightB,
    //     side.parent.parent.newColRightD,
    //     side.parent.parent.newColRightA,
    //     side.parent.parent.newColRightC,
    //     side.parent.parent.newWidthB,
    //     side.parent.parent.newWidthD,
    //     side.parent.parent.newWidthA,
    //     side.parent.parent.newWidthC
    //   );
    //   wall = false;
    // }
    side.parent.parent.update(model, () => {
      this.intersectionModels(side.parent.parent);
      side.parent.parent.updateMenu(this.menuRoof);
      this.newTargetControls();
      if (this.current) {
        this.funcCheckSide(this.current);
      } else {
        this.funcCheckSide(undefined);
      }
      this.funcUpdatePrice();
    });
    return data;
  }
  async funcToggleLamellen(side, show, type, sectionsL, sectionsR, width) {
    if (!side) {
      side = this.current;
    }
    if (!side) return;

    if (side.parent.parent.model.y1 > 3 + 0.2) return;
    if (
      side.parent.parent.model.x > 6.98 + 0.4 &&
      (side.name === 'sideB' || side.name === 'sideD')
    )
      return;
    if (
      side.parent.parent.model.z > 6.98 + 0.2 &&
      (side.name === 'sideA' || side.name === 'sideC')
    )
      return;

    let replace = false;
    if (this.typeModel === 'louver') {
      side.parent.parent.arrZip.forEach((zip) => {
        if (zip.side === side.name && zip.type === 'zipType2') {
          const data = side?.parent.parent.toggleZip(side, undefined);
          side.parent.parent.deleteMenu(this.zipMenu);
          replace = (() => {
            let toggle = true;
            const temp = JSON.parse(JSON.stringify(data));
            return () => {
              toggle && this.current
                ? side.parent.parent.attachMenu(this.zipMenu, side.name)
                : side.parent.parent.deleteMenu(this.zipMenu);
              side?.parent.parent.toggleZip(
                side,
                toggle ? temp.prev : undefined
              );
              if (this.current) {
                this.funcCheckSide(this.current);
              }
              toggle = !toggle;
            };
          })();
        }
      });

      side.parent.parent.arrWindow.forEach((window) => {
        if (window.side === side.name) {
          const data = side?.parent.parent.toggleWindow(side, undefined);
          if (window.type === 'windowType1') {
            side.parent.parent.deleteMenu(this.window1Menu);
          }
          if (window.type === 'windowType2') {
            side.parent.parent.deleteMenu(this.window2Menu);
          }

          replace = (() => {
            let toggle = true;
            const temp = JSON.parse(JSON.stringify(data));
            return () => {
              side.parent.parent.deleteMenu(this.window1Menu);
              side.parent.parent.deleteMenu(this.window2Menu);
              if (toggle && this.current)
                temp.prev === 'windowType1'
                  ? side.parent.parent.attachMenu(this.window1Menu, side.name)
                  : side.parent.parent.attachMenu(this.window2Menu, side.name);
              side?.parent.parent.toggleWindow(
                side,
                toggle ? temp.prev : undefined
              );
              if (this.current) {
                this.funcCheckSide(this.current);
              }

              toggle = !toggle;
            };
          })();
        }
      });
    }
    if (this.typeModel === 'louver2') {
      side.parent.parent.arrZip.forEach((zip) => {
        if (zip.side === side.name && zip.type === 'zipType2') {
          const data = side?.parent.parent.toggleZip(side, undefined);
          side.parent.parent.deleteMenu(this.zipMenu);
          replace = (() => {
            let toggle = true;
            const temp = JSON.parse(JSON.stringify(data));
            return () => {
              toggle && this.current
                ? side.parent.parent.attachMenu(this.zipMenu, side.name)
                : side.parent.parent.deleteMenu(this.zipMenu);
              side?.parent.parent.toggleZip(
                side,
                toggle ? temp.prev : undefined
              );
              if (this.current) {
                this.funcCheckSide(this.current);
              }
              toggle = !toggle;
            };
          })();
        }
      });

      side.parent.parent.arrWindow.forEach((window) => {
        if (window.side === side.name) {
          const data = side?.parent.parent.toggleWindow(side, undefined);
          if (window.type === 'windowType1') {
            side.parent.parent.deleteMenu(this.window1Menu);
          }
          if (window.type === 'windowType2') {
            side.parent.parent.deleteMenu(this.window2Menu);
          }

          replace = (() => {
            let toggle = true;
            const temp = JSON.parse(JSON.stringify(data));
            return () => {
              side.parent.parent.deleteMenu(this.window1Menu);
              side.parent.parent.deleteMenu(this.window2Menu);
              if (toggle && this.current)
                temp.prev === 'windowType1'
                  ? side.parent.parent.attachMenu(this.window1Menu, side.name)
                  : side.parent.parent.attachMenu(this.window2Menu, side.name);
              side?.parent.parent.toggleWindow(
                side,
                toggle ? temp.prev : undefined
              );
              if (this.current) {
                this.funcCheckSide(this.current);
              }

              toggle = !toggle;
            };
          })();
        }
      });
    }
    if (this.typeModel === 'louver3') {
      side.parent.parent.arrZip.forEach((zip) => {
        if (zip.side === side.name && zip.type === 'zipType2') {
          const data = side?.parent.parent.toggleZip(side, undefined);
          side.parent.parent.deleteMenu(this.zipMenu);
          replace = (() => {
            let toggle = true;
            const temp = JSON.parse(JSON.stringify(data));
            return () => {
              toggle && this.current
                ? side.parent.parent.attachMenu(this.zipMenu, side.name)
                : side.parent.parent.deleteMenu(this.zipMenu);
              side?.parent.parent.toggleZip(
                side,
                toggle ? temp.prev : undefined
              );
              if (this.current) {
                this.funcCheckSide(this.current);
              }
              toggle = !toggle;
            };
          })();
        }
      });

      side.parent.parent.arrWindow.forEach((window) => {
        if (window.side === side.name) {
          const data = side?.parent.parent.toggleWindow(side, undefined);
          if (window.type === 'windowType1') {
            side.parent.parent.deleteMenu(this.window1Menu);
          }
          if (window.type === 'windowType2') {
            side.parent.parent.deleteMenu(this.window2Menu);
          }

          replace = (() => {
            let toggle = true;
            const temp = JSON.parse(JSON.stringify(data));
            return () => {
              side.parent.parent.deleteMenu(this.window1Menu);
              side.parent.parent.deleteMenu(this.window2Menu);
              if (toggle && this.current)
                temp.prev === 'windowType1'
                  ? side.parent.parent.attachMenu(this.window1Menu, side.name)
                  : side.parent.parent.attachMenu(this.window2Menu, side.name);
              side?.parent.parent.toggleWindow(
                side,
                toggle ? temp.prev : undefined
              );
              if (this.current) {
                this.funcCheckSide(this.current);
              }

              toggle = !toggle;
            };
          })();
        }
      });
    }

    if (typeof show === 'undefined') {
      show = true;
      // if (show) {
      //   side.parent.parent.attachMenu(this.lamellenMenu, side.name);
      // } else {
      //   side.parent.parent.deleteMenu(this.lamellenMenu);
      // }
    }

    const prevcolSectionLamellen = JSON.parse(
      JSON.stringify(this.current.parent.parent.colSectionLamellen[side.name])
    );

    const prevLamellenWidth =
      this.current.parent.parent.lamellenWidth[side.name];

    const data = await this.funcToggleeLamellenPerformance(
      side,
      show,
      type,
      sectionsL,
      sectionsR,
      width
    );

    const nextcolSectionLamellen = JSON.parse(
      JSON.stringify(this.current.parent.parent.colSectionLamellen[side.name])
    );
    const nextcolLamellenWidth =
      this.current.parent.parent.lamellenWidth[side.name];

    data.prevcolSectionLamellen = prevcolSectionLamellen;
    data.nextcolSectionLamellen = nextcolSectionLamellen;

    data.prevLamellenWidth = prevLamellenWidth;
    data.nextcolLamellenWidth = nextcolLamellenWidth;

    if (replace) {
      History.execute(
        new ReplaceCommand(
          replace,
          (() => {
            let toggle = false;
            const temp = JSON.parse(JSON.stringify(data));
            return () => {
              toggle && this.current
                ? side.parent.parent.attachMenu(this.lamellenMenu, side.name)
                : side.parent.parent.deleteMenu(this.lamellenMenu);
              side?.parent.parent.toggleLamellen(
                side,
                toggle ? temp.next : undefined
              );
              if (this.current) {
                this.funcCheckSide(this.current);
              }

              toggle = !toggle;
            };
          })(),
          this.funcUpdatePrice.bind(this)
        )
      );
    } else {
      History.execute(
        new LamellenElCommand(
          side,
          data,
          this.funcToggleeLamellenPerformance.bind(this),
          this.funcUpdatePrice.bind(this)
        )
      );
    }

    return { value: show, data };
  }
  async funcToggleeLamellenPerformance(
    side,
    show,
    type,
    sectionsL,
    sectionsR,
    width
  ) {
    const data = side?.parent.parent.toggleLamellen(
      side,
      show ? type : undefined
    );

    side.parent.parent.deleteMenu(this.lamellenMenu);

    if (show && side.parent.visible) {
      side.parent.parent.attachMenu(this.lamellenMenu, side.name);
    }

    let newColLeftB = null;
    let newColLeftD = null;
    let newColLeftA = null;
    let newColLeftC = null;

    let newColRightB = null;
    let newColRightD = null;
    let newColRightA = null;
    let newColRightC = null;

    let newWidthB = null;
    let newWidthD = null;
    let newWidthA = null;
    let newWidthC = null;

    if (sectionsR === undefined) {
      if (side.name === 'sideA') {
        newColRightA = 0;
      } else {
        newColRightA = +side.parent.parent.colSectionLamellen.sideA[1];
      }
      if (side.name === 'sideB') {
        newColRightB = 0;
      } else {
        newColRightB = +side.parent.parent.colSectionLamellen.sideB[1];
      }
      if (side.name === 'sideC') {
        newColRightC = 0;
      } else {
        newColRightC = +side.parent.parent.colSectionLamellen.sideC[1];
      }
      if (side.name === 'sideD') {
        newColRightD = 0;
      } else {
        newColRightD = +side.parent.parent.colSectionLamellen.sideD[1];
      }
    } else {
      if (side.name === 'sideB') {
        newColRightB = sectionsR;
        side.parent.parent.colSectionLamellen.sideB[1] = +sectionsR;
      } else {
        newColRightB = side.parent.parent.colSectionLamellen.sideB[1];
      }
      if (side.name === 'sideD') {
        newColRightD = sectionsR;
        side.parent.parent.colSectionLamellen.sideD[1] = +sectionsR;
      } else {
        newColRightD = +side.parent.parent.colSectionLamellen.sideD[1];
      }
      if (side.name === 'sideA') {
        newColRightA = sectionsR;
        side.parent.parent.colSectionLamellen.sideA[1] = +sectionsR;
      } else {
        newColRightA = +side.parent.parent.colSectionLamellen.sideA[1];
      }
      if (side.name === 'sideC') {
        newColRightC = sectionsR;
        side.parent.parent.colSectionLamellen.sideC[1] = +sectionsR;
      } else {
        newColRightC = +side.parent.parent.colSectionLamellen.sideC[1];
      }
    }

    if (sectionsL === undefined) {
      if (side.name === 'sideA') {
        newColLeftA = 0;
      } else {
        newColLeftA = +side.parent.parent.colSectionLamellen.sideA[0];
      }
      if (side.name === 'sideB') {
        newColLeftB = 0;
      } else {
        newColLeftB = +side.parent.parent.colSectionLamellen.sideB[0];
      }
      if (side.name === 'sideC') {
        newColLeftC = 0;
      } else {
        newColLeftC = +side.parent.parent.colSectionLamellen.sideC[0];
      }
      if (side.name === 'sideD') {
        newColLeftD = 0;
      } else {
        newColLeftD = +side.parent.parent.colSectionLamellen.sideD[0];
      }
    } else {
      if (side.name === 'sideB') {
        newColLeftB = sectionsL;
        side.parent.parent.colSectionLamellen.sideB[0] = +sectionsL;
      } else {
        newColLeftB = side.parent.parent.colSectionLamellen.sideB[0];
      }
      if (side.name === 'sideD') {
        newColLeftD = sectionsL;
        side.parent.parent.colSectionLamellen.sideD[0] = +sectionsL;
      } else {
        newColLeftD = +side.parent.parent.colSectionLamellen.sideD[0];
      }
      if (side.name === 'sideA') {
        newColLeftA = sectionsL;
        side.parent.parent.colSectionLamellen.sideA[0] = +sectionsL;
      } else {
        newColLeftA = +side.parent.parent.colSectionLamellen.sideA[0];
      }
      if (side.name === 'sideC') {
        newColLeftC = sectionsL;
        side.parent.parent.colSectionLamellen.sideC[0] = +sectionsL;
      } else {
        newColLeftC = +side.parent.parent.colSectionLamellen.sideC[0];
      }
    }

    if (width === undefined) {
      if (side.name === 'sideA') {
        newWidthA = 1;
      } else {
        newWidthA = +side.parent.parent.lamellenWidth.sideA;
      }
      if (side.name === 'sideB') {
        newWidthB = 1;
      } else {
        newWidthB = +side.parent.parent.lamellenWidth.sideB;
      }
      if (side.name === 'sideC') {
        newWidthC = 1;
      } else {
        newWidthC = +side.parent.parent.lamellenWidth.sideC;
      }
      if (side.name === 'sideD') {
        newWidthD = 1;
      } else {
        newWidthD = +side.parent.parent.lamellenWidth.sideD;
      }
    } else {
      if (side.name === 'sideB') {
        newWidthB = width;
        side.parent.parent.lamellenWidth.sideB = +width;
      } else {
        newWidthB = side.parent.parent.lamellenWidth.sideB;
      }
      if (side.name === 'sideD') {
        newWidthD = width;
        side.parent.parent.lamellenWidth.sideD = +width;
      } else {
        newWidthD = +side.parent.parent.lamellenWidth.sideD;
      }
      if (side.name === 'sideA') {
        newWidthA = width;
        side.parent.parent.lamellenWidth.sideA = +width;
      } else {
        newWidthA = +side.parent.parent.lamellenWidth.sideA;
      }
      if (side.name === 'sideC') {
        newWidthC = width;
        side.parent.parent.lamellenWidth.sideC = +width;
      } else {
        newWidthC = +side.parent.parent.lamellenWidth.sideC;
      }
    }

    let model = null;
    let wall = null;
    if (this.typeModel === 'louver') {
      model = new ConstructLouver(
        this.details.louverBase,
        this.details.louverBeam,
        this.details.louverColumn,
        this.details.louverRoof,
        this.globalWidth,
        this.globalHeight1,
        this.globalDepth,
        this.details.zipPlaneSklSide,
        this.details.zipColumn,
        this.details.zipTop,
        this.details.zipBottom,
        this.details.windowBorder,
        this.details.windowFrame,
        this.details.windowGlass,
        this.details.windowHandle,
        this.details.windowPartition,
        this.details.windowFrameT,
        this.details.windowFrameB1,
        this.details.windowFrameB2,
        this.details.windowGlassC,
        this.details.windowGlassB,
        this.details.lamellen,
        this.details.lamellenRailTop,
        this.details.lamellenRailBottom,
        this.details.lamellenFrameColumn,
        this.details.lamellenFrameBase,
        side.parent.parent.colSectionWindow.sideB,
        side.parent.parent.colSectionWindow.sideD,
        side.parent.parent.colSectionWindow.sideA,
        side.parent.parent.colSectionWindow.sideC,
        newColLeftB,
        newColLeftD,
        newColLeftA,
        newColLeftC,
        newColRightB,
        newColRightD,
        newColRightA,
        newColRightC,
        newWidthB,
        newWidthD,
        newWidthA,
        newWidthC
      );
      wall = false;
    }

    if (this.typeModel === 'louver2') {
      model = new ConstructLouver2(
        this.details.louver2Base,
        this.details.louver2Beam,
        this.details.louver2Column,
        this.details.louver2Roof,
        this.globalWidth,
        this.globalHeight1,
        this.globalDepth,
        this.details.zipPlaneSklSide,
        this.details.zipColumn,
        this.details.zipTop,
        this.details.zipBottom,
        this.details.lamellen,
        this.details.lamellenRailTop,
        this.details.lamellenRailBottom,
        this.details.lamellenFrameColumn,
        this.details.lamellenFrameBase,
        newColLeftB,
        newColLeftD,
        newColLeftA,
        newColLeftC,
        newColRightB,
        newColRightD,
        newColRightA,
        newColRightC,
        newWidthB,
        newWidthD,
        newWidthA,
        newWidthC
      );
      wall = false;
    }

    if (this.typeModel === 'louver3') {
      model = new ConstructLouver3(
        this.details.louver3Solar,
        this.details.louver3Base,
        this.details.louver3Beam,
        this.details.louver3Column,
        this.details.louver3Roof,
        this.details.louver3RoofClip1,
        this.details.louver3RoofClip2,
        this.globalWidth,
        this.globalHeight1,
        this.globalDepth,
        this.details.zipPlaneSklSide,
        this.details.zipColumn,
        this.details.zipTop,
        this.details.zipBottom,
        this.details.windowBorder,
        this.details.windowFrame,
        this.details.windowGlass,
        this.details.windowHandle,
        this.details.windowPartition,
        this.details.windowFrameT,
        this.details.windowFrameB1,
        this.details.windowFrameB2,
        this.details.windowGlassC,
        this.details.windowGlassB,
        this.details.lamellen,
        this.details.lamellenRailTop,
        this.details.lamellenRailBottom,
        this.details.lamellenFrameColumn,
        this.details.lamellenFrameBase,
        side.parent.parent.colSectionWindow.sideB,
        side.parent.parent.colSectionWindow.sideD,
        side.parent.parent.colSectionWindow.sideA,
        side.parent.parent.colSectionWindow.sideC,
        newColLeftB,
        newColLeftD,
        newColLeftA,
        newColLeftC,
        newColRightB,
        newColRightD,
        newColRightA,
        newColRightC,
        newWidthB,
        newWidthD,
        newWidthA,
        newWidthC
      );
      wall = false;
    }

    if (this.typeModel === 'glass') {
      // const info = await this.infoProductGlassModel(
      //   this.globalWidth,
      //   this.globalDepth,
      //   this.globalHeight1,
      //   this.globalHeight2
      // );
      model = new ConstructGlass(
        this.resInfoProductGlassModel,
        this.details.glassBase,
        this.details.glassBeam,
        this.details.glassColumn,
        this.details.glassRoofL,
        this.details.glassRoofT,
        this.details.glassRoofP,
        this.details.glassGlassRoof,
        this.details.zipPlaneSglSide,
        this.details.zipPlaneSglTop,
        this.details.zipColumn5,
        this.details.zipTop5,
        this.details.zipBottom5,
        this.details.zipTop2,
        this.details.zipColumn2,
        this.details.zipBottom2,
        this.details.zipTop3,
        this.details.zipColumn3,
        this.details.zipBottom3,
        this.details.zipHolder,
        this.details.zipHolder2,
        this.details.zipCenter2,
        this.details.zipCenter3,
        this.details.windowSglBorder,
        this.details.windowSglFrame,
        this.details.windowSglGlass,
        this.details.windowSglHandle,
        this.details.windowSglPartition,
        this.details.windowSglFrameT,
        this.details.windowSglFrameB1,
        this.details.windowSglFrameB2,
        this.details.windowSglGlassC,
        this.details.windowSglGlassB,
        this.details.zipTop4,
        this.details.glasscolumnMid,
        this.details.glasscompoundMid,
        this.globalWidth,
        this.globalHeight1,
        this.globalHeight2,
        this.globalDepth,
        side.parent.parent.colSectionWindow.sideB,
        side.parent.parent.colSectionWindow.sideD,
        side.parent.parent.colSectionWindow.sideA,
        side.parent.parent.colSectionWindow.sideC
      );
      wall = true;
    }

    side.parent.parent.update(model, () => {
      this.intersectionModels(side.parent.parent);
      side.parent.parent.updateMenu(this.menuRoof);
      this.newTargetControls();
      if (this.current) {
        this.funcCheckSide(this.current);
      } else {
        this.funcCheckSide(undefined);
      }
      this.funcUpdatePrice();
    });
    return data;
  }
  funcToggleLed(el, options) {
    if (!el) {
      el = this.current.parent.parent;
    }
    if (!el) return;
    if (el.arrLed[0] === undefined) {
      el.arrLed = [];
    }
    const value = el.arrLed.length;
    const data = el.toggleLed(el, options);
    History.execute(
      new LedElCommand(
        el,
        data,
        this.funcUpdatePrice.bind(this),
        this.funcCheckSide.bind(this),
        this
      )
    );
    this.funcUpdatePrice();
    this.funcCheckSide(this.current);
    return value;
  }
  funcToggleSteering(el, type) {
    if (!el) {
      el = this.current.parent.parent;
    }
    if (!el) return;

    // el?.arrSteering.forEach((item) => {
    //   if (item === type) {
    //     type = undefined;
    //   }
    // });

    // if (el?.arrSteering[0] === type) {
    //   type = undefined;
    // }

    // if (this.dataSteering.next === undefined) {
    //
    // }
    this.dataSteering = el.toggleSteering(el, type);
    History.execute(
      new SteeringElCommand(
        el,
        this.dataSteering,
        this.funcUpdatePrice.bind(this),
        this.funcCheckSide.bind(this),
        this
      )
    );
    this.funcUpdatePrice();
    this.funcCheckSide(this.current);
  }

  funcToggleLed2(el, type) {
    if (!el) {
      el = this.current.parent.parent;
    }
    if (!el) return;
    this.dataLed2 = el.toggleLed2(el, type);
    History.execute(
      new Led2ElCommand(
        el,
        this.dataLed2,
        this.funcUpdatePrice.bind(this),
        this.funcCheckSide.bind(this),
        this
      )
    );

    this.funcUpdatePrice();
    this.funcCheckSide(this.current);
  }

  funcToggleElectro(el, type) {
    if (!el) {
      el = this.current.parent.parent;
    }
    if (!el) return;
    // if (el?.arrElectro[0] === type) {
    //   type = undefined;
    // }
    const data = el.toggleElectro(el, type);
    History.execute(
      new ElectroElCommand(
        el,
        data,
        this.funcUpdatePrice.bind(this),
        this.funcCheckSide.bind(this),
        this
      )
    );
    this.funcUpdatePrice();
    this.funcCheckSide(this.current);
  }
  funcToggleRemoteControl(el, type) {
    if (!el) {
      el = this.current.parent.parent;
    }
    if (!el) return;
    if (el?.arrRemoteControl[0] === type) {
      type = undefined;
    }
    const data = el.toggleRemoteControl(el, type);
    History.execute(
      new RemoteControlElCommand(
        el,
        data,
        this.funcUpdatePrice.bind(this),
        this.funcCheckSide.bind(this),
        this
      )
    );
    this.funcUpdatePrice();
    this.funcCheckSide(this.current);
  }
  funcLedVariable() {
    this.dispatchEvent({
      type: 'led-variable',
      led: this.current.parent.parent.arrLed,
      sparren: this.current.parent.parent.model.colRoof
        ? this.current.parent.parent.model.colRoof
        : '0',
    });
  }
  funcSolarVariable() {
    this.dispatchEvent({
      type: 'solar-variable',
      colSolar: this.current.parent.parent.colSolar,
      sizeZ: this.current.parent.parent.model.z,
      sizeX: this.current.parent.parent.model.x,
    });
  }
  funcChangeAllMapSkl(map, type) {
    const prevMap = this.details.zipTop4.children[0].material.map;
    let texture = new THREE.TextureLoader().load(map);
    texture.userData = map;
    texture.wrapS = texture.wrapT = THREE.MirroredRepeatWrapping;
    texture.repeat.set(1, 3);
    const nextMap = texture;

    const arr = [];

    if (type === 'lammelenMapSkl') {
      arr.push(this.details.louverRoof);
      arr.push(this.details.louver2Roof);
      arr.push(this.details.lamellen);
    }

    if (type === 'allMapSkl' || type === 'allMapSklSolar') {
      arr.push(this.details.louverBase);
      arr.push(this.details.louverBeam);
      arr.push(this.details.louverColumn);
      arr.push(this.details.louver2Base);
      arr.push(this.details.louver2Beam);
      arr.push(this.details.louver2Column);
      arr.push(this.details.louver3Base);
      arr.push(this.details.louver3Beam);
      arr.push(this.details.louver3Column);
      arr.push(this.details.louver3Roof);
      arr.push(this.details.louver3RoofClip1);
      arr.push(this.details.louver3RoofClip2);

      arr.push(this.details.zipColumn);
      arr.push(this.details.zipTop);
      arr.push(this.details.zipBottom);

      arr.push(this.details.windowBorder);
      arr.push(this.details.windowFrame);
      arr.push(this.details.windowHandle);
      arr.push(this.details.windowPartition);
      arr.push(this.details.windowGlassB);
      arr.push(this.details.windowGlassC);
      arr.push(this.details.windowFrameB2);
      arr.push(this.details.windowFrameB1);
      arr.push(this.details.windowFrameT);

      arr.push(this.details.lamellenRailTop);
      arr.push(this.details.lamellenRailBottom);
      arr.push(this.details.lamellenFrameColumn);
      arr.push(this.details.lamellenFrameBase);
    }

    arr.forEach((item) => {
      item.children[0].material.map = texture;
      item.children[0].material.map.rotation = Math.PI / 2;
      item.children[0].material.color.set(0xffffff);
      item.children[0].material.needsUpdate = true;
    });

    History.execute(new ChangeMapElCommand(arr, prevMap, nextMap));
  }
  funcChangeZipMapSklSide(map) {
    if (!this.viewer.scene.getObjectByName('zipPlaneSklSide')) return;
    const prevMap = this.details.zipPlaneSklSide.children[0].material.map;
    let texture = new THREE.TextureLoader().load(map);
    texture.userData = map;
    texture.wrapS = texture.wrapT = THREE.MirroredRepeatWrapping;
    texture.repeat.set(1, 3);
    this.details.zipPlaneSklSide.children[0].material.map = texture;
    this.details.zipPlaneSklSide.children[0].material.map.rotation =
      Math.PI / 2;
    this.details.zipPlaneSklSide.children[0].material.color.set(0xffffff);
    this.details.zipPlaneSklSide.children[0].material.needsUpdate = true;
    const nextMap = texture;

    const arr = [];
    arr.push(this.details.zipPlaneSklSide);
    History.execute(new ChangeMapElCommand(arr, prevMap, nextMap));
  }
  funcChangeAllMapSgl(map) {
    const prevMap = this.details.zipTop4.children[0].material.map;
    let texture = new THREE.TextureLoader().load(map);
    texture.userData = map;
    texture.wrapS = texture.wrapT = THREE.MirroredRepeatWrapping;
    texture.repeat.set(1, 3);
    const nextMap = texture;

    const arr = [];
    arr.push(this.details.zipTop3);
    arr.push(this.details.zipColumn3);
    arr.push(this.details.zipCenter3);
    arr.push(this.details.zipBottom3);
    arr.push(this.details.zipTop2);
    arr.push(this.details.zipColumn2);
    arr.push(this.details.zipCenter2);
    arr.push(this.details.zipBottom2);
    arr.push(this.details.zipHolder);
    arr.push(this.details.zipHolder2);
    arr.push(this.details.zipTop4);
    arr.push(this.details.zipBottom5);
    arr.push(this.details.zipTop5);
    arr.push(this.details.zipColumn5);

    arr.push(this.details.glassBase);
    arr.push(this.details.glassBeam);
    arr.push(this.details.glassColumn);
    arr.push(this.details.glassRoofL);
    arr.push(this.details.glassRoofP);
    arr.push(this.details.glassRoofT);
    arr.push(this.details.glasscolumnMid);
    arr.push(this.details.glasscompoundMid);

    arr.push(this.details.windowSglBorder);
    arr.push(this.details.windowSglFrame);
    arr.push(this.details.windowSglGlass);
    arr.push(this.details.windowSglHandle);
    arr.push(this.details.windowSglPartition);
    arr.push(this.details.windowSglGlassB);
    arr.push(this.details.windowSglGlassC);
    arr.push(this.details.windowSglFrameB2);
    arr.push(this.details.windowSglFrameB1);
    arr.push(this.details.windowSglFrameT);

    arr.forEach((item) => {
      item.children[0].material.map = texture;
      item.children[0].material.map.rotation = Math.PI / 2;
      item.children[0].material.color.set(0xffffff);
      item.children[0].material.needsUpdate = true;
    });

    History.execute(new ChangeMapElCommand(arr, prevMap, nextMap));
  }
  funcChangeZipMapSglTop(map) {
    if (!this.viewer.scene.getObjectByName('zipPlaneSglTop')) return;
    const prevMap = this.details.zipPlaneSglTop.children[0].material.map;
    let texture = new THREE.TextureLoader().load(map);
    texture.userData = map;
    texture.wrapS = texture.wrapT = THREE.MirroredRepeatWrapping;
    texture.repeat.set(1, 3);
    this.details.zipPlaneSglTop.children[0].material.map = texture;
    this.details.zipPlaneSglTop.children[0].material.map.rotation = Math.PI / 2;
    this.details.zipPlaneSglTop.children[0].material.color.set(0xffffff);
    this.details.zipPlaneSglTop.children[0].material.needsUpdate = true;
    const nextMap = texture;

    const arr = [];
    arr.push(this.details.zipPlaneSglTop);
    History.execute(new ChangeMapElCommand(arr, prevMap, nextMap));
  }
  funcChangeZipMapSglSide(map) {
    if (!this.viewer.scene.getObjectByName('zipPlaneSglSide')) return;
    const prevMap = this.details.zipPlaneSglSide.children[0].material.map;
    let texture = new THREE.TextureLoader().load(map);
    texture.userData = map;
    texture.wrapS = texture.wrapT = THREE.MirroredRepeatWrapping;
    texture.repeat.set(1, 3);
    this.details.zipPlaneSglSide.children[0].material.map = texture;
    this.details.zipPlaneSglSide.children[0].material.map.rotation =
      Math.PI / 2;
    this.details.zipPlaneSglSide.children[0].material.color.set(0xffffff);
    this.details.zipPlaneSglSide.children[0].material.needsUpdate = true;
    const nextMap = texture;

    const arr = [];
    arr.push(this.details.zipPlaneSglSide);
    History.execute(new ChangeMapElCommand(arr, prevMap, nextMap));
  }
  funcChangeExtras(
    aufmassVorOrt,
    baugensuch,
    planung,
    montagePlanung,
    fundamente,
    plattenpassen,
    colFundamente,
    colPlattenpassen
  ) {
    this.extras.servicesTG.aufmassVorOrt = aufmassVorOrt;
    this.extras.servicesTG.baugensuch = baugensuch;
    this.extras.servicesTG.planung = planung;
    this.extras.servicesTG.montagePlanung = montagePlanung;

    this.extras.externalServicesTG.fundamente = fundamente;
    this.extras.externalServicesTG.plattenpassen = plattenpassen;
    this.extras.externalServicesTG.colFundamente = colFundamente;
    this.extras.externalServicesTG.colPlattenpassen = colPlattenpassen;

    this.funcUpdatePrice();
  }
  overwriteExtras(extras) {
    this.dispatchEvent({
      type: 'extras',
      extras: extras,
    });

    this.funcUpdatePrice();
  }
  funcRestart() {
    this.funcClearScene();

    this.globalWidth = 5000;
    this.globalDepth = 3000;
    this.globalHeight1 = 2100;
    this.globalHeight2 = 2500;

    let textureSkl = new THREE.TextureLoader().load(
      'models/zipMapSklSide/001010_grey_charcoal.jpg'
    );
    textureSkl.userData = 'models/zipMapSklSide/001010_grey_charcoal.jpg';
    textureSkl.wrapS = textureSkl.wrapT = THREE.MirroredRepeatWrapping;
    textureSkl.repeat.set(1, 5);

    let textureSglTop = new THREE.TextureLoader().load(
      'models/zipMapSglTop/314_660_Sage.jpg'
    );
    textureSglTop.userData = 'models/zipMapSglTop/314_660_Sage.jpg';
    textureSglTop.wrapS = textureSglTop.wrapT = THREE.MirroredRepeatWrapping;
    textureSglTop.repeat.set(1, 5);

    let textureSglSide = new THREE.TextureLoader().load(
      'models/zipMapSglSide/0102.jpg'
    );
    textureSglSide.userData = 'models/zipMapSglSide/0102.jpg';
    textureSglSide.wrapS = textureSglSide.wrapT = THREE.MirroredRepeatWrapping;
    textureSglSide.repeat.set(1, 5);

    let textureAllMapSgl = new THREE.TextureLoader().load(
      'models/mapSglAll/DB_703_seidenglanz.jpg'
    );
    textureAllMapSgl.userData = 'models/mapSglAll/DB_703_seidenglanz.jpg';
    textureAllMapSgl.wrapS = textureAllMapSgl.wrapT =
      THREE.MirroredRepeatWrapping;
    textureAllMapSgl.repeat.set(1, 5);

    let textureAllMapSkl = new THREE.TextureLoader().load(
      'models/mapSklAll/RAL_7016_Antrazithgrau_feinstruktur.jpg'
    );
    textureAllMapSkl.userData =
      'models/mapSklAll/RAL_7016_Antrazithgrau_feinstruktur.jpg';
    textureAllMapSkl.wrapS = textureAllMapSkl.wrapT =
      THREE.MirroredRepeatWrapping;
    textureAllMapSkl.repeat.set(1, 5);

    let textureLamellenMapSkl = new THREE.TextureLoader().load(
      'models/mapSklAll/Starlight_G.jpg'
    );
    textureLamellenMapSkl.userData = 'models/mapSklAll/Starlight_G.jpg';
    textureLamellenMapSkl.wrapS = textureLamellenMapSkl.wrapT =
      THREE.MirroredRepeatWrapping;
    textureLamellenMapSkl.repeat.set(1, 5);

    this.details.zipPlaneSklSide.children[0].material.map = textureSkl;
    this.details.zipPlaneSklSide.children[0].material.map.rotation =
      Math.PI / 2;
    this.details.zipPlaneSklSide.children[0].material.color.set(0xffffff);
    this.details.zipPlaneSklSide.children[0].material.needsUpdate = true;
    this.details.zipPlaneSglTop.children[0].material.map = textureSglTop;
    this.details.zipPlaneSglTop.children[0].material.map.rotation = Math.PI / 2;
    this.details.zipPlaneSglTop.children[0].material.color.set(0xffffff);
    this.details.zipPlaneSglTop.children[0].material.needsUpdate = true;
    this.details.zipPlaneSglSide.children[0].material.map = textureSglSide;
    this.details.zipPlaneSglSide.children[0].material.map.rotation =
      Math.PI / 2;
    this.details.zipPlaneSglSide.children[0].material.color.set(0xffffff);
    this.details.zipPlaneSglSide.children[0].material.needsUpdate = true;

    const allMapSgl = [];
    allMapSgl.push(this.details.zipTop3);
    allMapSgl.push(this.details.zipColumn3);
    allMapSgl.push(this.details.zipCenter3);
    allMapSgl.push(this.details.zipBottom3);
    allMapSgl.push(this.details.zipTop2);
    allMapSgl.push(this.details.zipColumn2);
    allMapSgl.push(this.details.zipCenter2);
    allMapSgl.push(this.details.zipBottom2);
    allMapSgl.push(this.details.zipHolder);
    allMapSgl.push(this.details.zipHolder2);
    allMapSgl.push(this.details.zipTop4);
    allMapSgl.push(this.details.zipBottom5);
    allMapSgl.push(this.details.zipTop5);
    allMapSgl.push(this.details.zipColumn5);

    allMapSgl.push(this.details.glassBase);
    allMapSgl.push(this.details.glassBeam);
    allMapSgl.push(this.details.glassColumn);
    allMapSgl.push(this.details.glassRoofL);
    allMapSgl.push(this.details.glassRoofP);
    allMapSgl.push(this.details.glassRoofT);
    allMapSgl.push(this.details.glasscolumnMid);
    allMapSgl.push(this.details.glasscompoundMid);

    allMapSgl.push(this.details.windowSglBorder);
    allMapSgl.push(this.details.windowSglFrame);
    allMapSgl.push(this.details.windowSglGlass);
    allMapSgl.push(this.details.windowSglHandle);
    allMapSgl.push(this.details.windowSglPartition);
    allMapSgl.push(this.details.windowSglGlassB);
    allMapSgl.push(this.details.windowSglGlassC);
    allMapSgl.push(this.details.windowSglFrameB2);
    allMapSgl.push(this.details.windowSglFrameB1);
    allMapSgl.push(this.details.windowSglFrameT);

    const lammelenMapSkl = [];
    lammelenMapSkl.push(this.details.louverRoof);
    lammelenMapSkl.push(this.details.louver2Roof);
    lammelenMapSkl.push(this.details.lamellen);

    const allMapSkl = [];
    allMapSkl.push(this.details.louverBase);
    allMapSkl.push(this.details.louver2Base);
    allMapSkl.push(this.details.louver3Base);
    allMapSkl.push(this.details.louverBeam);
    allMapSkl.push(this.details.louver2Beam);
    allMapSkl.push(this.details.louver3Beam);
    allMapSkl.push(this.details.louverColumn);
    allMapSkl.push(this.details.louver2Column);
    allMapSkl.push(this.details.louver3Column);
    allMapSkl.push(this.details.louver3Roof);
    allMapSkl.push(this.details.louver3RoofClip1);
    allMapSkl.push(this.details.louver3RoofClip2);
    allMapSkl.push(this.details.zipColumn);
    allMapSkl.push(this.details.zipTop);
    allMapSkl.push(this.details.zipBottom);

    allMapSkl.push(this.details.windowBorder);
    allMapSkl.push(this.details.windowFrame);
    allMapSkl.push(this.details.windowHandle);
    allMapSkl.push(this.details.windowPartition);
    allMapSkl.push(this.details.windowGlassB);
    allMapSkl.push(this.details.windowGlassC);
    allMapSkl.push(this.details.windowFrameB2);
    allMapSkl.push(this.details.windowFrameB1);
    allMapSkl.push(this.details.windowFrameT);

    allMapSkl.push(this.details.lamellenRailTop);
    allMapSkl.push(this.details.lamellenRailBottom);
    allMapSkl.push(this.details.lamellenFrameColumn);
    allMapSkl.push(this.details.lamellenFrameBase);

    allMapSgl.forEach((item) => {
      item.children[0].material.map = textureAllMapSgl;
      item.children[0].material.map.rotation = Math.PI / 2;
      item.children[0].material.color.set(0xffffff);
      item.children[0].material.needsUpdate = true;
    });

    allMapSkl.forEach((item) => {
      item.children[0].material.map = textureAllMapSkl;
      item.children[0].material.map.rotation = Math.PI / 2;
      item.children[0].material.color.set(0xffffff);
      item.children[0].material.needsUpdate = true;
    });

    lammelenMapSkl.forEach((item) => {
      item.children[0].material.map = textureLamellenMapSkl;
      item.children[0].material.map.rotation = Math.PI / 2;
      item.children[0].material.color.set(0xffffff);
      item.children[0].material.needsUpdate = true;
    });

    History.reset();
    this.extras = {
      servicesTG: {
        aufmassVorOrt: false,
        baugensuch: false,
        planung: false,
        montagePlanung: false,
      },
      externalServicesTG: {
        fundamente: false,
        plattenpassen: false,
        colFundamente: 0,
        colPlattenpassen: 0,
      },
    };

    this.overwriteExtras(this.extras);
  }
  funcSaveProject() {
    let config = {
      elements: [],
      accessories: [],
      paint: [],
      extras: [],
    };

    let materialsklall = undefined;
    let materiallamellensklall = undefined;
    let materialsglall = undefined;

    let zipmaterialsklside = undefined;
    let zipmaterialsglside = undefined;
    let zipmaterialsgltop = undefined;

    materialsklall =
      this.viewer.scene.getObjectByName('louverColumn')?.material.map.userData;

    materiallamellensklall =
      this.viewer.scene.getObjectByName('louverRoof')?.material.map.userData;

    materialsglall =
      this.viewer.scene.getObjectByName('glassBase')?.material.map.userData;

    zipmaterialsklside =
      this.viewer.scene.getObjectByName('zipPlaneSklSide')?.material.map
        .userData;

    zipmaterialsglside =
      this.viewer.scene.getObjectByName('zipPlaneSglSide')?.material.map
        .userData;

    zipmaterialsgltop =
      this.viewer.scene.getObjectByName('zipPlaneSglTop')?.material.map
        .userData;

    this.elements.forEach((element) => {
      config.elements.push({
        position: element.position.toArray(),
        position2d: element.position2d,
        model: element.model.type,
        size: {
          x: element.model.x,
          y1: element.model.y || element.model.y1,
          y2: element.model.y2 || null,
          z: element.model.z,
        },
        walls: element.bb.children
          .filter((item) => item.userData.wall?.parent)
          .map((item) => item.name),
        leds: element.arrLed,

        windows: element.arrWindow,
        zips: element.arrZip,
        lamellens: element.arrLamellen,
        steering: element.arrSteering,
        leds2: element.arrLed2,
        electro: element.arrElectro,
        remoteControl: element.arrRemoteControl,

        colSolar: element.colSolar,
        colSectionsWindow: element.colSectionWindow,
        colSectionsLamellen: element.colSectionLamellen,
        lamellenWidth: element.lamellenWidth,
        colSectionsZip: element.colSectionZip,
      });
    });
    config.paint = {
      materialsklall: materialsklall,
      materiallamellensklall: materiallamellensklall,
      materialsglall: materialsglall,

      zipmaterialsklside: zipmaterialsklside,
      zipmaterialsglside: zipmaterialsglside,
      zipmaterialsgltop: zipmaterialsgltop,
    };

    config.extras = this.extras;
    return config;
  }
  funcOpenProject(config) {
    this.funcClearScene();

    config.elements.forEach(async (item) => {
      this.typeModel = item.model;

      this.dispatchEvent({
        type: 'new-type-model',
        model: item.model,
      });

      let model = null;
      let wall = null;
      this.globalWidth = item.size.x * 1000;
      this.globalHeight1 = item.size.y1 * 1000;
      this.globalDepth = item.size.z * 1000;

      if (this.typeModel === 'louver') {
        model = new ConstructLouver(
          this.details.louverBase,
          this.details.louverBeam,
          this.details.louverColumn,
          this.details.louverRoof,
          item.size.x * 1000,
          item.size.y1 * 1000,
          item.size.z * 1000,
          this.details.zipPlaneSklSide,
          this.details.zipColumn,
          this.details.zipTop,
          this.details.zipBottom,
          this.details.windowBorder,
          this.details.windowFrame,
          this.details.windowGlass,
          this.details.windowHandle,
          this.details.windowPartition,
          this.details.windowFrameT,
          this.details.windowFrameB1,
          this.details.windowFrameB2,
          this.details.windowGlassC,
          this.details.windowGlassB,
          this.details.lamellen,
          this.details.lamellenRailTop,
          this.details.lamellenRailBottom,
          this.details.lamellenFrameColumn,
          this.details.lamellenFrameBase,
          item.colSectionsWindow.sideB,
          item.colSectionsWindow.sideD,
          item.colSectionsWindow.sideA,
          item.colSectionsWindow.sideC,
          item.colSectionsLamellen.sideB[0],
          item.colSectionsLamellen.sideD[0],
          item.colSectionsLamellen.sideA[0],
          item.colSectionsLamellen.sideC[0],
          item.colSectionsLamellen.sideB[1],
          item.colSectionsLamellen.sideD[1],
          item.colSectionsLamellen.sideA[1],
          item.colSectionsLamellen.sideC[1],
          item.lamellenWidth.sideB,
          item.lamellenWidth.sideD,
          item.lamellenWidth.sideA,
          item.lamellenWidth.sideC
        );
        wall = false;
      }

      if (this.typeModel === 'louver2') {
        model = new ConstructLouver2(
          this.details.louver2Base,
          this.details.louver2Beam,
          this.details.louver2Column,
          this.details.louver2Roof,
          item.size.x * 1000,
          item.size.y1 * 1000,
          item.size.z * 1000,
          this.details.zipPlaneSklSide,
          this.details.zipColumn,
          this.details.zipTop,
          this.details.zipBottom,
          this.details.lamellen,
          this.details.lamellenRailTop,
          this.details.lamellenRailBottom,
          this.details.lamellenFrameColumn,
          this.details.lamellenFrameBase,
          item.colSectionsLamellen.sideB[0],
          item.colSectionsLamellen.sideD[0],
          item.colSectionsLamellen.sideA[0],
          item.colSectionsLamellen.sideC[0],
          item.colSectionsLamellen.sideB[1],
          item.colSectionsLamellen.sideD[1],
          item.colSectionsLamellen.sideA[1],
          item.colSectionsLamellen.sideC[1],
          item.lamellenWidth.sideB,
          item.lamellenWidth.sideD,
          item.lamellenWidth.sideA,
          item.lamellenWidth.sideC
        );
        wall = false;
      }

      if (this.typeModel === 'louver3') {
        model = new ConstructLouver3(
          this.details.louver3Solar,
          this.details.louver3Base,
          this.details.louver3Beam,
          this.details.louver3Column,
          this.details.louver3Roof,
          this.details.louver3RoofClip1,
          this.details.louver3RoofClip2,
          item.size.x * 1000,
          item.size.y1 * 1000,
          item.size.z * 1000,
          this.details.zipPlaneSklSide,
          this.details.zipColumn,
          this.details.zipTop,
          this.details.zipBottom,
          this.details.windowBorder,
          this.details.windowFrame,
          this.details.windowGlass,
          this.details.windowHandle,
          this.details.windowPartition,
          this.details.windowFrameT,
          this.details.windowFrameB1,
          this.details.windowFrameB2,
          this.details.windowGlassC,
          this.details.windowGlassB,
          this.details.lamellen,
          this.details.lamellenRailTop,
          this.details.lamellenRailBottom,
          this.details.lamellenFrameColumn,
          this.details.lamellenFrameBase,
          item.colSectionsWindow.sideB,
          item.colSectionsWindow.sideD,
          item.colSectionsWindow.sideA,
          item.colSectionsWindow.sideC,
          item.colSectionsLamellen.sideB[0],
          item.colSectionsLamellen.sideD[0],
          item.colSectionsLamellen.sideA[0],
          item.colSectionsLamellen.sideC[0],
          item.colSectionsLamellen.sideB[1],
          item.colSectionsLamellen.sideD[1],
          item.colSectionsLamellen.sideA[1],
          item.colSectionsLamellen.sideC[1],
          item.lamellenWidth.sideB,
          item.lamellenWidth.sideD,
          item.lamellenWidth.sideA,
          item.lamellenWidth.sideC
        );
        wall = false;
      }

      if (this.typeModel === 'glass') {
        const info = await this.infoProductGlassModel(
          item.size.x * 1000,
          item.size.z * 1000,
          item.size.y1 * 1000,
          item.size.y2 * 1000
        );
        this.resInfoProductGlassModel = info;
        model = new ConstructGlass(
          info,
          this.details.glassBase,
          this.details.glassBeam,
          this.details.glassColumn,
          this.details.glassRoofL,
          this.details.glassRoofT,
          this.details.glassRoofP,
          this.details.glassGlassRoof,
          this.details.zipPlaneSglSide,
          this.details.zipPlaneSglTop,
          this.details.zipColumn5,
          this.details.zipTop5,
          this.details.zipBottom5,
          this.details.zipTop2,
          this.details.zipColumn2,
          this.details.zipBottom2,
          this.details.zipTop3,
          this.details.zipColumn3,
          this.details.zipBottom3,
          this.details.zipHolder,
          this.details.zipHolder2,
          this.details.zipCenter2,
          this.details.zipCenter3,
          this.details.windowSglBorder,
          this.details.windowSglFrame,
          this.details.windowSglGlass,
          this.details.windowSglHandle,
          this.details.windowSglPartition,
          this.details.windowSglFrameT,
          this.details.windowSglFrameB1,
          this.details.windowSglFrameB2,
          this.details.windowSglGlassC,
          this.details.windowSglGlassB,
          this.details.zipTop4,
          this.details.glasscolumnMid,
          this.details.glasscompoundMid,
          item.size.x * 1000,
          item.size.y1 * 1000,
          item.size.y2 * 1000,
          item.size.z * 1000,
          item.colSectionsWindow.sideB,
          item.colSectionsWindow.sideD,
          item.colSectionsWindow.sideA,
          item.colSectionsWindow.sideC
        );
        wall = true;
      }

      // add
      const newElement = new Element(
        model,
        () => {
          newElement.colSectionLamellen = JSON.parse(
            JSON.stringify(item.colSectionsLamellen)
          );
          newElement.colSectionWindow = JSON.parse(
            JSON.stringify(item.colSectionsWindow)
          );
          newElement.colSectionZip = JSON.parse(
            JSON.stringify(item.colSectionsZip)
          );
          newElement.lamellenWidth = JSON.parse(
            JSON.stringify(item.lamellenWidth)
          );

          item.walls.forEach((wall) => {
            newElement.toggleWall(newElement.bb.getObjectByName(wall), true);
          });
          item.windows?.forEach((window) => {
            newElement.toggleWindow(
              newElement.bb.getObjectByName(window.side),
              window.type
            );
          });
          item.zips?.forEach((zip) => {
            newElement.toggleZip(
              newElement.bb.getObjectByName(zip.side),
              zip.type
            );
          });
          item.leds?.forEach((led) => {
            newElement.toggleLed(newElement, led);
          });

          item.steering?.forEach((steering) => {
            newElement.toggleSteering(newElement, steering);
          });

          item.leds2?.forEach((led2) => {
            newElement.toggleLed2(newElement, led2);
          });

          newElement.showSolar(item.colSolar);

          item.electro?.forEach((electro) => {
            newElement.toggleElectro(newElement, electro);
          });
          item.remoteControl?.forEach((remoteControl) => {
            newElement.toggleRemoteControl(newElement, remoteControl);
          });

          item.lamellens?.forEach((lamellen) => {
            newElement.toggleLamellen(
              newElement.bb.getObjectByName(lamellen.side),
              lamellen.type
            );
          });

          this.newTargetControls();
        },
        undefined,
        false
        // wall
      );
      // set position
      newElement.position.fromArray(item.position);
      this.elements.push(newElement);
      this.viewer.scene.add(newElement);
      newElement.position2d = item.position2d;
    });

    if (config?.paint?.materialsklall) {
      let texturesklall = new THREE.TextureLoader().load(
        config.paint.materialsklall
      );
      texturesklall.wrapS = texturesklall.wrapT = THREE.MirroredRepeatWrapping;
      texturesklall.repeat.set(1, 5);

      const allMapSkl = [];
      allMapSkl.push(this.details.louverBase);
      allMapSkl.push(this.details.louverBeam);
      allMapSkl.push(this.details.louverColumn);
      allMapSkl.push(this.details.louver2Base);
      allMapSkl.push(this.details.louver2Beam);
      allMapSkl.push(this.details.louver2Column);
      allMapSkl.push(this.details.zipColumn);
      allMapSkl.push(this.details.zipTop);
      allMapSkl.push(this.details.zipBottom);

      allMapSkl.push(this.details.windowBorder);
      allMapSkl.push(this.details.windowFrame);
      allMapSkl.push(this.details.windowHandle);
      allMapSkl.push(this.details.windowPartition);
      allMapSkl.push(this.details.windowGlassB);
      allMapSkl.push(this.details.windowGlassC);
      allMapSkl.push(this.details.windowFrameB2);
      allMapSkl.push(this.details.windowFrameB1);
      allMapSkl.push(this.details.windowFrameT);

      allMapSkl.push(this.details.lamellenRailTop);
      allMapSkl.push(this.details.lamellenRailBottom);
      allMapSkl.push(this.details.lamellenFrameColumn);
      allMapSkl.push(this.details.lamellenFrameBase);

      allMapSkl.forEach((item) => {
        item.children[0].material.map = texturesklall;
        item.children[0].material.map.rotation = Math.PI / 2;
        item.children[0].material.color.set(0xffffff);
        item.children[0].material.needsUpdate = true;
      });
    }

    if (config?.paint?.materiallamellensklall) {
      let texturelamellensklall = new THREE.TextureLoader().load(
        config.paint.materiallamellensklall
      );
      texturelamellensklall.wrapS = texturelamellensklall.wrapT =
        THREE.MirroredRepeatWrapping;
      texturelamellensklall.repeat.set(1, 5);

      const lammelenMapSkl = [];
      lammelenMapSkl.push(this.details.louverRoof);
      lammelenMapSkl.push(this.details.louver2Roof);
      lammelenMapSkl.push(this.details.lamellen);

      lammelenMapSkl.forEach((item) => {
        item.children[0].material.map = texturelamellensklall;
        item.children[0].material.map.rotation = Math.PI / 2;
        item.children[0].material.color.set(0xffffff);
        item.children[0].material.needsUpdate = true;
      });
    }

    if (config?.paint?.materialsglall) {
      let texturesglall = new THREE.TextureLoader().load(
        config.paint.materialsglall
      );
      texturesglall.wrapS = texturesglall.wrapT = THREE.MirroredRepeatWrapping;
      texturesglall.repeat.set(1, 5);

      const arrSglAluminium = [];
      arrSglAluminium.push(this.details.zipTop3);
      arrSglAluminium.push(this.details.zipColumn3);
      arrSglAluminium.push(this.details.zipCenter3);
      arrSglAluminium.push(this.details.zipBottom3);
      arrSglAluminium.push(this.details.zipTop2);
      arrSglAluminium.push(this.details.zipColumn2);
      arrSglAluminium.push(this.details.zipCenter2);
      arrSglAluminium.push(this.details.zipBottom2);
      arrSglAluminium.push(this.details.zipHolder);
      arrSglAluminium.push(this.details.zipHolder2);
      arrSglAluminium.push(this.details.zipTop4);
      arrSglAluminium.push(this.details.zipBottom5);
      arrSglAluminium.push(this.details.zipTop5);
      arrSglAluminium.push(this.details.zipColumn5);

      arrSglAluminium.push(this.details.glassBase);
      arrSglAluminium.push(this.details.glassBeam);
      arrSglAluminium.push(this.details.glassColumn);
      arrSglAluminium.push(this.details.glassRoofL);
      arrSglAluminium.push(this.details.glassRoofP);
      arrSglAluminium.push(this.details.glassRoofT);
      arrSglAluminium.push(this.details.glasscolumnMid);
      arrSglAluminium.push(this.details.glasscompoundMid);

      arrSglAluminium.push(this.details.windowSglBorder);
      arrSglAluminium.push(this.details.windowSglFrame);
      arrSglAluminium.push(this.details.windowSglGlass);
      arrSglAluminium.push(this.details.windowSglHandle);
      arrSglAluminium.push(this.details.windowSglPartition);
      arrSglAluminium.push(this.details.windowSglGlassB);
      arrSglAluminium.push(this.details.windowSglGlassC);
      arrSglAluminium.push(this.details.windowSglFrameB2);
      arrSglAluminium.push(this.details.windowSglFrameB1);
      arrSglAluminium.push(this.details.windowSglFrameT);

      arrSglAluminium.forEach((item) => {
        item.children[0].material.map = texturesglall;
        item.children[0].material.map.rotation = Math.PI / 2;
        item.children[0].material.color.set(0xffffff);
        item.children[0].material.needsUpdate = true;
      });
    }

    if (config?.paint?.zipmaterialsglside) {
      let texturesglside = new THREE.TextureLoader().load(
        config.paint.zipmaterialsglside
      );
      texturesglside.wrapS = texturesglside.wrapT =
        THREE.MirroredRepeatWrapping;
      texturesglside.repeat.set(1, 5);
      this.details.zipPlaneSglSide.children[0].material.map = texturesglside;
      this.details.zipPlaneSglSide.children[0].material.map.rotation =
        Math.PI / 2;
      this.details.zipPlaneSglSide.children[0].material.color.set(0xffffff);
      this.details.zipPlaneSglSide.children[0].material.needsUpdate = true;
    }

    if (config?.paint?.zipmaterialsklside) {
      let texturesklside = new THREE.TextureLoader().load(
        config.paint.zipmaterialsklside
      );
      texturesklside.wrapS = texturesklside.wrapT =
        THREE.MirroredRepeatWrapping;
      texturesklside.repeat.set(1, 5);
      this.details.zipPlaneSklSide.children[0].material.map = texturesklside;
      this.details.zipPlaneSklSide.children[0].material.map.rotation =
        Math.PI / 2;
      this.details.zipPlaneSklSide.children[0].material.color.set(0xffffff);
      this.details.zipPlaneSklSide.children[0].material.needsUpdate = true;
    }

    if (config?.paint?.zipmaterialsgltop) {
      let texturesgltop = new THREE.TextureLoader().load(
        config.paint.zipmaterialsgltop
      );
      texturesgltop.wrapS = texturesgltop.wrapT = THREE.MirroredRepeatWrapping;
      texturesgltop.repeat.set(1, 5);
      this.details.zipPlaneSglTop.children[0].material.map = texturesgltop;
      this.details.zipPlaneSglTop.children[0].material.map.rotation =
        Math.PI / 2;
      this.details.zipPlaneSglTop.children[0].material.color.set(0xffffff);
      this.details.zipPlaneSglTop.children[0].material.needsUpdate = true;
    }

    this.extras = {
      servicesTG: {
        aufmassVorOrt: false,
        baugensuch: false,
        planung: false,
        montagePlanung: false,
      },
      externalServicesTG: {
        fundamente: false,
        plattenpassen: false,
        colFundamente: 0,
        colPlattenpassen: 0,
      },
    };
    this.overwriteExtras(config.extras);
    this.funcUpdatePrice();
    this.fitCameraToCenteredObjects(this.elements);
  }
  funcClearScene() {
    for (let index = this.elements.length - 1; index >= 0; index--) {
      const element = this.elements[index];
      element.traverse((item) => {
        if (item.geometry) {
          item.geometry.dispose();
        }
      });
      this.viewer.scene.remove(element);
    }
    this.elements = [];
    this.viewer.controls.target.set(0, 0, 0);
    this.funcCheckSide(undefined);
  }
  fitCameraToCenteredObjects(elements, fitOffset = 1.01) {
    const size = new Vector3();
    const center = new Vector3();
    const box = new Box3();

    box.makeEmpty();
    for (const object of elements) {
      box.expandByObject(object);
    }

    box.getSize(size);
    box.getCenter(center);

    const maxSize = Math.max(size.x, size.y, size.z);

    const fitHeightDistance =
      maxSize / (2 * Math.atan((Math.PI * this.viewer.camera.fov) / 300));
    const fitWidthDistance = fitHeightDistance / this.viewer.camera.aspect;
    const distance = fitOffset * Math.max(fitHeightDistance, fitWidthDistance);

    const direction = this.viewer.controls.target
      .clone()
      .sub(this.viewer.camera.position)
      .normalize()
      .multiplyScalar(distance);

    this.viewer.controls.target.copy(center);
    this.viewer.camera.updateProjectionMatrix();
    this.viewer.camera.position
      .copy(this.viewer.controls.target)
      .sub(direction);
    this.viewer.controls.update();
  }
  fitCameraToCenteredObjects2(elements, fitOffset = 0.9) {
    const boundingBox = new THREE.Box3();
    for (const object of elements) {
      boundingBox.expandByObject(object);
    }

    const size = new THREE.Vector3();
    const center = new THREE.Vector3();
    boundingBox.getSize(size);
    boundingBox.getCenter(center);

    const maxDimension = Math.max(size.x, size.y, size.z);
    const distance = maxDimension / 2;
    const aspect = this.container.offsetWidth / this.container.offsetHeight;
    const cameraWidth = aspect >= 1 ? maxDimension * aspect : maxDimension;
    const cameraHeight = aspect >= 1 ? maxDimension : maxDimension / aspect;

    this.viewer.cameraOrt.left = -cameraWidth / (fitOffset * 2);
    this.viewer.cameraOrt.right = cameraWidth / (fitOffset * 2);
    this.viewer.cameraOrt.top = cameraHeight / (fitOffset * 2);
    this.viewer.cameraOrt.bottom = -cameraHeight / (fitOffset * 2);

    this.viewer.cameraOrt.rotation.z = -Math.PI / 2;

    this.viewer.cameraOrt.position.set(center.x, center.y + distance, center.z);
    this.viewer.cameraOrt.lookAt(center);

    this.viewer.cameraOrt.rotation.z = Math.PI / 2;

    this.viewer.cameraOrt.updateProjectionMatrix();
    this.viewer.renderer.setSize(
      this.container.offsetWidth,
      this.container.offsetHeight
    );
  }
  funcScreenScene() {
    this.viewer.renderer.domElement.style.display = 'none';
    if (this.current) {
      this.current.material.color.set(0x61c38b);
      this.current.material.opacity = 0.2;
      this.current.parent.parent.children[0].visible = false;
      this.current.parent.parent.deleteMenu(this.menuRoof);
      this.current = null;
    }
    this.funcCheckSide(undefined);

    return new Promise((res) => {
      const pos = new Vector3();
      pos.copy(this.viewer.camera.position);
      this.viewer.camera.position.set(60, 15, -40);
      this.fitCameraToCenteredObjects(this.elements);
      this.viewer.renderer.render(this.viewer.scene, this.viewer.camera);
      this.viewer.controls.minPolarAngle = -Infinity;
      setTimeout(() => {
        let screenScene = this.viewer.renderer.domElement.toDataURL();

        /////////////

        const watermarkImage = new Image();
        watermarkImage.src = '/watermark.png';

        watermarkImage.onload = function () {
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');

          const screenImage = new Image();
          screenImage.src = screenScene;

          screenImage.onload = function () {
            canvas.width = screenImage.width;
            canvas.height = screenImage.height;

            context.drawImage(screenImage, 0, 0);
            const watermarkX = (canvas.width - screenImage.width * 0.7) / 2;
            const watermarkY = (canvas.height - screenImage.width * 0.7) / 2;
            context.drawImage(
              watermarkImage,
              watermarkX,
              watermarkY,
              screenImage.width * 0.7,
              screenImage.width * 0.7
            );

            screenScene = canvas.toDataURL();
          };
        };

        ///////////////

        this.newTargetControls();
        // const x = this.viewer.controls.target.x;
        // const z = this.viewer.controls.target.z;
        setTimeout(() => {
          // this.viewer.cameraOrt.position.set(x, 1, z);
          // this.fitCameraToCenteredObjects2(this.elements);
          this.fitCameraToCenteredObjects2(this.elements);

          this.viewer.typeCamera = 'cameraOrt';

          setTimeout(() => {
            let screenSceneTop = this.viewer.renderer.domElement.toDataURL();

            const watermarkImage = new Image();
            watermarkImage.src = '/watermark.png';

            watermarkImage.onload = function () {
              const canvas = document.createElement('canvas');
              const context = canvas.getContext('2d');

              const screenImage = new Image();
              screenImage.src = screenSceneTop;

              screenImage.onload = function () {
                canvas.width = screenImage.width;
                canvas.height = screenImage.height;

                context.drawImage(screenImage, 0, 0);
                const watermarkX = (canvas.width - screenImage.width * 0.7) / 2;
                const watermarkY =
                  (canvas.height - screenImage.width * 0.7) / 2;
                context.drawImage(
                  watermarkImage,
                  watermarkX,
                  watermarkY,
                  screenImage.width * 0.7,
                  screenImage.width * 0.7
                );

                screenSceneTop = canvas.toDataURL();
              };
            };

            this.viewer.controls.minPolarAngle = 1;
            this.viewer.controls.maxPolarAngle = 1.4;
            this.viewer.camera.fov = 20;
            this.viewer.scene.rotation.y = 0;
            this.viewer.camera.position.copy(pos);
            setTimeout(() => {
              this.viewer.typeCamera = 'camera';
              this.newTargetControls();
              this.viewer.renderer.domElement.style.display = 'block';

              res({ screenScene, screenSceneTop });
            }, 200);
          }, 200);
        }, 200);
      }, 200);
    });
  }
  funcUpdatePrice() {
    this.dispatchEvent({
      type: 'update-price',
      size: {
        w: this.globalWidth,
        d: this.globalDepth,
        h1: this.globalHeight1,
        h2: this.globalHeight2,
      },
      elements: this.elements,
      extras: this.extras,
    });
  }
  exportSceneGltf() {
    if (this.current) {
      this.current.material.color.set(0x61c38b);
      this.current.material.opacity = 0.2;
      this.current.parent.parent.children[0].visible = false;
      this.current.parent.parent.deleteMenu(this.menuRoof);
      this.current.parent.parent.deleteMenu(this.menuRoof);
      this.current.parent.parent.deleteMenu(this.zipMenu);
      this.current.parent.parent.deleteMenu(this.window1Menu);
      this.current.parent.parent.deleteMenu(this.window2Menu);
      this.current.parent.parent.deleteMenu(this.zipRoofMenu);
      this.current.parent.parent.deleteMenu(this.lamellenMenu);
      this.current = null;
      this.funcCheckSide(undefined);
    }

    const options = {
      trs: true, // Применять масштабирование, поворот и перемещение объектов
      onlyVisible: true, // Экспортировать только видимые объекты
      truncateDrawRange: true, // Обрезать диапазон отрисовки
      binary: false, // Экспортировать в двоичном формате
    };

    this.exporter.parse(
      this.viewer.scene,
      function (gltf) {
        const link = document.createElement('a');
        link.style.display = 'none';
        document.body.appendChild(link);
        link.href = URL.createObjectURL(
          new Blob([JSON.stringify(gltf)], { type: 'application/octet-stream' })
        );
        link.download = 'scene.gltf';
        link.click();
        document.body.removeChild(link);
      },
      options
    );
  }
  exportSceneObj() {
    if (this.current) {
      this.current.material.color.set(0x61c38b);
      this.current.material.opacity = 0.2;
      this.current.parent.parent.children[0].visible = false;
      this.current.parent.parent.deleteMenu(this.menuRoof);
      this.current.parent.parent.deleteMenu(this.menuRoof);
      this.current.parent.parent.deleteMenu(this.zipMenu);
      this.current.parent.parent.deleteMenu(this.window1Menu);
      this.current.parent.parent.deleteMenu(this.window2Menu);
      this.current.parent.parent.deleteMenu(this.zipRoofMenu);
      this.current.parent.parent.deleteMenu(this.lamellenMenu);
      this.current = null;
      this.funcCheckSide(undefined);
    }
    const exporter = new OBJExporter();
    const visibleScene = new THREE.Scene();
    this.viewer.scene.traverse(function (object) {
      if (
        object.isMesh &&
        object.isObject3D &&
        !['sideRoof', 'sideA', 'sideB', 'sideC', 'sideD'].includes(object.name)
      ) {
        visibleScene.add(object.clone());
      }
    });

    const objData = exporter.parse(visibleScene);
    const link = document.createElement('a');
    link.style.display = 'none';
    document.body.appendChild(link);
    link.href = URL.createObjectURL(
      new Blob([objData], { type: 'text/plain' })
    );
    link.download = 'scene.obj';
    link.click();
    document.body.removeChild(link);
  }
  exportSceneCollada() {
    if (this.current) {
      this.current.material.color.set(0x61c38b);
      this.current.material.opacity = 0.2;
      this.current.parent.parent.children[0].visible = false;
      this.current.parent.parent.deleteMenu(this.menuRoof);
      this.current.parent.parent.deleteMenu(this.menuRoof);
      this.current.parent.parent.deleteMenu(this.zipMenu);
      this.current.parent.parent.deleteMenu(this.window1Menu);
      this.current.parent.parent.deleteMenu(this.window2Menu);
      this.current.parent.parent.deleteMenu(this.zipRoofMenu);
      this.current.parent.parent.deleteMenu(this.lamellenMenu);
      this.current = null;
      this.funcCheckSide(undefined);
    }
    const exporter = new ColladaExporter();

    const visibleScene = new THREE.Scene();
    visibleScene.name = 'newScene';

    this.elements.forEach((el) => {
      visibleScene.add(deepClone(el));
    });

    const temp = [];
    visibleScene.traverse((item) => {
      if (['bb'].includes(item.name)) {
        temp.push(item);
      }
    });

    for (let index = temp.length - 1; index >= 0; index--) {
      const element = temp[index];
      element?.parent.remove(element);
    }

    let materialsklall = undefined;
    let materiallamellensklall = undefined;
    let zipmaterialsklside = undefined;
    let materialsglall = undefined;
    let zipmaterialsglside = undefined;
    let zipmaterialsgltop = undefined;

    materialsklall =
      this.viewer.scene.getObjectByName('louverColumn')?.material.map.userData;
    materiallamellensklall =
      this.viewer.scene.getObjectByName('louverRoof')?.material.map.userData;
    zipmaterialsklside =
      this.viewer.scene.getObjectByName('zipPlaneSklSide')?.material.map
        .userData;
    materialsglall =
      this.viewer.scene.getObjectByName('glassBase')?.material.map.userData;
    zipmaterialsglside =
      this.viewer.scene.getObjectByName('zipPlaneSglSide')?.material.map
        .userData;
    zipmaterialsgltop =
      this.viewer.scene.getObjectByName('zipPlaneSglTop')?.material.map
        .userData;

    const zip = new JSZip();
    exporter.parse(visibleScene, (result) => {
      zip.file('scene.dae', result.data);

      const relativePath1 = materialsklall?.replace(
        'models/mapSklAll/',
        'Profilfarbe/'
      );
      const relativePath2 = materiallamellensklall?.replace(
        'models/mapSklAll/',
        'Lamellenfarbe/'
      );
      const relativePath3 = materialsglall?.replace(
        'models/mapSglAll/',
        'Profilfarbe/'
      );
      const relativePath4 = zipmaterialsklside?.replace(
        'models/zipMapSklSide/',
        'Markise/'
      );
      const relativePath5 = zipmaterialsglside?.replace(
        'models/zipMapSglSide/',
        'Markise/'
      );
      const relativePath6 = zipmaterialsgltop?.replace(
        'models/zipMapSglTop/',
        'Dachmarkise/'
      );

      const filePromise1 = fetch(materialsklall)
        .then((response) => response.blob())
        .then((blob) => {
          zip.file(relativePath1, blob);
        })
        .catch((error) => {
          console.error(error);
        });

      const filePromise2 = fetch(materiallamellensklall)
        .then((response) => response.blob())
        .then((blob) => {
          zip.file(relativePath2, blob);
        })
        .catch((error) => {
          console.error(error);
        });

      const filePromise3 = fetch(materialsglall)
        .then((response) => response.blob())
        .then((blob) => {
          zip.file(relativePath3, blob);
        })
        .catch((error) => {
          console.error(error);
        });

      const filePromise4 = fetch(zipmaterialsklside)
        .then((response) => response.blob())
        .then((blob) => {
          zip.file(relativePath4, blob);
        })
        .catch((error) => {
          console.error(error);
        });

      const filePromise5 = fetch(zipmaterialsglside)
        .then((response) => response.blob())
        .then((blob) => {
          zip.file(relativePath5, blob);
        })
        .catch((error) => {
          console.error(error);
        });

      const filePromise6 = fetch(zipmaterialsgltop)
        .then((response) => response.blob())
        .then((blob) => {
          zip.file(relativePath6, blob);
        })
        .catch((error) => {
          console.error(error);
        });

      Promise.all([
        materialsklall ? filePromise1 : undefined,
        materiallamellensklall ? filePromise2 : undefined,
        materialsglall ? filePromise3 : undefined,
        zipmaterialsklside ? filePromise4 : undefined,
        zipmaterialsglside ? filePromise5 : undefined,
        zipmaterialsgltop ? filePromise6 : undefined,
      ])
        .then(function () {
          return zip.generateAsync({ type: 'blob' });
        })
        .then((content) => {
          saveAs(
            content,
            this.typeModel === 'louver'
              ? 'skl100.zip'
              : this.typeModel === 'louver2'
              ? 'skl50.zip'
              : this.typeModel === 'louver3'
              ? 'ecl130.zip'
              : 'glasdach.zip'
          );
        })
        .catch(function (error) {
          console.error(error);
        });
    });
  }
  funcRotationModel(side) {
    let targetTheta;
    const currentSpherical = new THREE.Spherical();
    currentSpherical.setFromVector3(
      this.viewer.camera.position.sub(this.viewer.controls.target)
    );
    switch (side) {
      case 'sideA':
        targetTheta = 0;
        break;
      case 'sideC':
        targetTheta = Math.PI;
        break;
      case 'sideB':
        targetTheta = Math.PI / 2;
        break;
      case 'sideD':
        targetTheta = -Math.PI / 2;

        break;
      default:
        targetTheta = currentSpherical.theta;
    }
    let deltaTheta = targetTheta - currentSpherical.theta;
    if (deltaTheta > Math.PI) {
      deltaTheta -= 2 * Math.PI;
    } else if (deltaTheta < -Math.PI) {
      deltaTheta += 2 * Math.PI;
    }

    new TWEEN.Tween({ theta: currentSpherical.theta })
      .to({ theta: currentSpherical.theta + deltaTheta }, 1000)
      .easing(TWEEN.Easing.Quadratic.Out)
      .onUpdate((state) => {
        currentSpherical.theta = state.theta;
        this.viewer.camera.position
          .setFromSpherical(currentSpherical)
          .add(this.viewer.controls.target);
        this.viewer.camera.lookAt(this.viewer.controls.target);
      })
      .onComplete(() => {
        this.viewer.controls.update();
      })
      .start();
  }

  funcControlsAllow(value) {
    if (value) {
      this.viewer.controls.target.set(0, 0, 0);
      this.viewer.controls.enablePan = true;
      this.viewer.controls.minDistance = 0;
      this.viewer.controls.maxDistance = 100;
      this.viewer.controls.minPolarAngle = 0;
      this.viewer.controls.maxPolarAngle = Math.PI;
    } else {
      this.viewer.controls.target.set(0, 0, 0);
      this.viewer.controls.enablePan = false;
      this.viewer.controls.minDistance = 15;
      this.viewer.controls.maxDistance = 50;
      this.viewer.controls.minPolarAngle = 1;
      this.viewer.controls.maxPolarAngle = 1.4;
    }
    this.newTargetControls();
  }
}
