import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'components/ui/Button';

import closeImg from '../../../assets/close.svg';

import ModalWrapper, { ModalHeader } from '../ModalWrapper';
import fileImg from '../../../assets/file.svg';
import { setShowUploadBg } from '../../../store/reducers/User/UserSlice';
import './style.scss';

const UploadBGModal = () => {
  const dispatch = useDispatch();
  const { showUploadBg } = useSelector((state) => state.user);
  const { source } = useSelector((state) => state.project);

  const [selectedFile, setSelectedFile] = useState(null);

  const [uploadProgress, setUploadProgress] = useState(0);

  const handleFileChange = (event) => {
    const fileList = event.target.files;

    if (fileList && fileList.length > 0) {
      const file = fileList[0];
      setSelectedFile(file);

      const reader = new FileReader();
      reader.onprogress = (e) => {
        if (e.lengthComputable) {
          const progress = Math.round((e.loaded / e.total) * 100);
          setUploadProgress(progress);
        }
      };

      reader.onload = () => {
        file.readerResult = reader.result;
        setSelectedFile(file);
        event.target.files = null;
      };

      reader.readAsDataURL(file);
    }
  };

  const handleClose = () => {
    dispatch(setShowUploadBg(false));
  };

  const handleAnwenden = () => {
    if (showUploadBg) {
      if (selectedFile) {
        showUploadBg.style.backgroundImage = `url(${URL.createObjectURL(
          selectedFile
        )})`;
        source?.funcControlsAllow(true);
      } else {
        showUploadBg.style.backgroundImage = 'none';
        source?.funcControlsAllow(false);
      }
    }
    dispatch(setShowUploadBg(false));
  };

  return (
    <ModalWrapper
      show={showUploadBg}
      close={handleClose}
      customClass="wrapper-upload-bg-modal"
    >
      <ModalHeader title="Hintergrund hochladen" close={handleClose} />
      <div className="container-upload-bg-modal">
        <div className="file-upload-container">
          <label
            htmlFor="file-input"
            className={
              selectedFile ? 'file-upload-label active' : 'file-upload-label'
            }
          >
            {!selectedFile && (
              <span className="file-upload-text">
                <div className="file-upload-container">
                  {!selectedFile && (
                    <>
                      <img src={fileImg} className="file-upload-icon" alt="" />
                      <div className="file-upload-text">
                        Ziehen und ablegen oder <span>durchsuchen</span> Ihre
                        Dateien.
                      </div>
                      <div className="file-upload-text">
                        Verwenden Sie Dateiformate .jpg oder .png.
                      </div>

                      <input
                        type="file"
                        accept=".jpg, .jpeg, .png"
                        onChange={handleFileChange}
                        id="file-input"
                        className="file-upload-input"
                      />
                    </>
                  )}
                </div>
              </span>
            )}

            {selectedFile && (
              <div className="upload-content">
                <img
                  src={URL.createObjectURL(selectedFile)}
                  className="file-upload-icon"
                  alt=""
                />

                <div className="wrapper-file-upload-text-remove">
                  <div className="file-upload-text-remove">
                    <span className="file-upload-text">
                      {selectedFile.name.length > 28
                        ? `${selectedFile.name.slice(0, 28)}... `
                        : selectedFile.name}{' '}
                      (
                      {Math.ceil((selectedFile.size / (1024 * 1024)) * 10) / 10}{' '}
                      MB)
                    </span>

                    <button
                      className="delete-upload-bg"
                      type="button"
                      onClick={(e) => {
                        setSelectedFile(null);
                        setUploadProgress(0);
                        e.stopPropagation();
                        e.preventDefault();
                        return false;
                      }}
                    >
                      <img src={closeImg} alt="" />
                    </button>
                  </div>

                  <div className="progress-bar">
                    <div
                      className="progress"
                      style={{ width: `${uploadProgress}%` }}
                    />
                  </div>
                </div>
              </div>
            )}
          </label>
        </div>

        <div className="container-btn-upload-bg-modal">
          <Button className="btn-upload-bg-modal" onClick={handleAnwenden}>
            Anwenden
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default UploadBGModal;
